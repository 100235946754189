import * as React from "react";
import { B1, B2, styled, H5 } from "@h1eng/ui-components";
import {
  CombinedDocumentsType,
  GenericSearchResultInterface,
  PublicationInterface
} from "@h1eng/interfaces";
import { switchDocumentDateSort } from "./MultiDocRender";
import { connect } from "react-redux";
import PillFactory from "../../Pill";
const ShowingResults = styled.div`
  padding-top: 10px;
  padding-left: 20px;
  display: flex;
  font-family: Montserrat;
  font-size: 14px;
  color: #333333;
`;
export const ShowingResultsKeyWords = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
`;
const CLEARALL = styled.div`
  text-decoration: underline;
  display: inline-block;
  color: #000000;
  margin-left: 40px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
} from "../../../store/actions";
import { SetDocumentSearchBarQueryInterface } from "./ProfileDocumentSearch";
import {
  getDocumentSearch,
  getDocumentSearchBarState
} from "../../../store/selectors";

export const SearchBarQureyDisplayClass: React.SFC<{
  searchBarState: { query: string[] };
}> = (props: { searchBarState: { query: string[] } }) => {
  const searchBarHelperText =
    props.searchBarState.query.length > 1 ? "keywords" : "keyword";
  const searchBarSearchHeler =
    props.searchBarState.query.length > 0 ? (
      <ShowingResults>
        Showing&nbsp;results&nbsp;containing&nbsp;the&nbsp;{searchBarHelperText}
        &nbsp;“
        <ShowingResultsKeyWords>
          {props.searchBarState.query.join(", ")}
        </ShowingResultsKeyWords>
        ”
      </ShowingResults>
    ) : (
      <></>
    );
  return searchBarSearchHeler;
};
const mapStateToProps = (state: any) => ({
  searchBarState: getDocumentSearchBarState(state)
});

const mapDispatchToProps = {};

export const SearchBarQureyDisplay = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(SearchBarQureyDisplayClass as any);

export const filterDocsByDate = (
  filterDate: number,
  filterDateText: string,
  docs: any[]
) => {
  if (!filterDate || filterDateText === "All time") {
    return docs;
  }
  const filterDate = new Date(filterDate);
  return docs.filter(e => {
    const date = switchDocumentDateSort(e);
    if (!date) {
      return false;
    }
    const docDate = new Date(date);
    return docDate.getTime() - filterDate.getTime() > 0;
  });
};

export const filterFoundDocs = (
  foundDocuments: GenericSearchResultInterface[],
  docs: CombinedDocumentsType[],
  transform?: function
) => {
  const filtered = docs.filter(e =>
    foundDocuments.map(a => a.id).includes(e.id)
  );
  if (transform) {
    return transform(filtered);
  }
  return filtered;
};

export const generatePublication = (pubs: PublicationInterface[]) => {
  return {
    publicationCount: pubs.length,
    citationCount: pubs.reduce((pv, cv) => {
      if (cv.citationsCount && cv.citationsCount.toString()) {
        pv += Number.parseFloat(cv.citationsCount!.toString() || "0") || 0;
      }
      return pv;
    }, 0),
    hIndex: this.props.summary!.hIndex
  } as PublicationSummaryInterface;
};

export const dateFilters = (caller: any) =>
  [5, 10, 100].map(e => {
    const date = new Date().setFullYear(new Date().getFullYear() - e);
    const optionText = e === 100 ? "All time" : `Last ${e} years`;
    return {
      callback: () => {
        caller.props.setDocumentSearchBarFilterDate({
          filterDate: date,
          displayFilterDateString: optionText
        });
      },
      optionText
    };
  });

interface SearchBarQureyHelperDispatcher {
  setDocumentSearchBarQuery: (t: SetDocumentSearchBarQueryInterface) => void;
  documentSearch: GenericSearchResultInterface[];
  searchBarState: { query: string[]; personId: string; types: any[] };
  onClear?: () => void;
  customQuery?: (personId: string, terms: string[]) => void;
}

export const SearchBarQureyHelperClass: React.SFC<
  SearchBarQureyHelperDispatcher
> = (props: SearchBarQureyHelperDispatcher) => {
  const removeTerm = (term: string) => {
    const query = props.searchBarState.query.filter((t: any) => t !== term);
    if (props.customQuery) {
      props.customQuery(props.searchBarState.personId, query);
      props.setDocumentSearchBarQuery({
        query,
        personId: props.searchBarState.personId,
        types: props.searchBarState.types
      });
    } else {
      props.setDocumentSearchBarQuery({
        query,
        personId: props.searchBarState.personId,
        types: props.searchBarState.types
      });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "20px",
        paddingLeft: "20px"
      }}
    >
      <div style={{}}>
        {props.searchBarState.query.map((term: any, i: any) => {
          return (
            <PillFactory
              backgroundColor={"#d7d6e7"}
              onClick={() => removeTerm(term)}
              title={term}
              key={i}
            />
          );
        })}
        {props.searchBarState.query.length > 0 && (
          <CLEARALL
            onClick={() => {
              if (props.onClear) {
                props.onClear();
              }
              props.setDocumentSearchBarQuery({
                query: [],
                personId: props.personId,
                types: []
              });
            }}
          >
            Clear&nbsp;All
          </CLEARALL>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  searchBarState: getDocumentSearchBarState(state)
});

const mapDispatchToProps = {
  setDocumentSearchBarQuery
};

export const SearchBarQureyHelper = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(SearchBarQureyHelperClass as any);
