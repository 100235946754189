import * as React from "react";
import { ListMeta } from "../../ListManagementInterfaces";
import { B2, B1 } from "@h1eng/ui-components";
import { ListManipulationInterface } from "../../../../../store/actions";
import { images } from "./images";
import { flatMap, includes } from "lodash";
import { Divider, ListActionButton } from "./components";

interface Props {
  listsAvailable: ListMeta[];
  personId: string;
  selectedListId: string | null;
  removeList: (event?: React.SyntheticEvent<HTMLElement>) => void;
  addToList: (opts: ListManipulationInterface) => void;
  showNewListForm: (event?: React.SyntheticEvent<HTMLDivElement>) => void;
}

export class AvailableListsMenu extends React.Component<Props> {
  get lists() {
    const { personId, listsAvailable, selectedListId } = this.props;

    return listsAvailable
      .filter(list => list.id !== selectedListId)
      .map((list: any) => {
        try {
          const personIds = flatMap(list.listPersonJoins, e => e.personId);
          if (includes(personIds, personId)) {
            return null;
          }

          return list;
        } catch (e) {
          return null;
        }
      })
      .filter(list => list !== null);
  }

  render() {
    const {
      personId,
      selectedListId,
      removeList,
      addToList,
      showNewListForm
    } = this.props;

    const { lists } = this;

    return (
      <>
        <B2 style={{ width: "100%", textAlign: "center" }}>
          {lists.length > 0 ? "Select list to save to:" : "Create a new list"}
        </B2>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: 200,
            overflowY: "scroll"
          }}
        >
          {lists.map((list: any) => {
            return (
              <React.Fragment key={`list-${list.id}`}>
                <div
                  onClick={() => {
                    addToList({
                      personIds: [personId],
                      listId: list.id
                    });
                  }}
                  style={{ lineHeight: "18px" }}
                >
                  <B2 small hover>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{ marginRight: "5px" }}
                        src={images.availableList}
                      />
                      <span>{list.title}</span>
                    </div>
                  </B2>
                </div>
              </React.Fragment>
            );
          })}
        </div>

        <Divider />
        <ListActionButton onClick={showNewListForm}>
          <img style={{ marginRight: "5px" }} src={images.newList} />
          <B1 hover>New List</B1>
        </ListActionButton>
        {!!selectedListId && (
          <>
            <Divider />
            <ListActionButton onClick={removeList}>
              <B1 hover>Remove from List</B1>
            </ListActionButton>
          </>
        )}
      </>
    );
  }
}
