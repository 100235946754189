/* tslint:disable:cyclomatic-complexity */
import { getType } from "typesafe-actions";
import {
  kolCardsActionGet,
  kolSavedListsCardsActionGet,
  setKolCardsLoading,
  KOLCardAction,
  deleteSavedListKolCards
} from "../actions";
import { ResultCardData } from "@h1eng/interfaces";

export interface KolCardsState {
  cards: ResultCardData[];
  savedListsKolCards: ResultCardData[];
  loading: boolean;
  allCards: ResultCardData[];
}

const initialState: KolCardsState = {
  cards: [],
  allCards: [],
  savedListsKolCards: [],
  loading: false
};

export const kolCardsReducer = (
  state: KolCardsState = initialState,
  action: KOLCardAction
): KolCardsState => {
  switch (action.type) {
    case getType(kolCardsActionGet.request): {
      return {
        ...state,
        loading: true
      };
    }

    /**
     * @TODO: Why are some results returning with empty data?
     */
    case getType(kolCardsActionGet.success): {
      const ids = state.allCards.map(e => e.personId);

      return {
        ...state,
        cards: action.payload.filter(i => !!i.personId),
        allCards: state.allCards.concat(
          action.payload.filter((e: ResultCardData) => {
            return !ids.includes(e.personId);
          })
        ),
        loading: false
      };
    }

    case getType(kolSavedListsCardsActionGet.request): {
      return {
        ...state,
        loading: true
      };
    }

    case getType(kolSavedListsCardsActionGet.success): {
      return {
        ...state,
        savedListsKolCards: action.payload,
        loading: false
      };
    }

    case getType(setKolCardsLoading): {
      return {
        ...state,
        loading: action.payload
      };
    }

    case getType(deleteSavedListKolCards): {
      return {
        ...state,
        savedListsKolCards: []
      };
    }
  }

  return state;
};
