import {
  createAsyncAction,
  createStandardAction,
  ActionType
} from "typesafe-actions";

import {
  GetCollaboratorsInterface,
  GetCollaboratorsDetialsInterface,
  GetLiteProfileInterface,
  GetCollaboratorsDetialsResultInterface,
  LiteProfile
} from "@h1eng/interfaces";
import { CollaboratorState } from "../reducers/collaborators";

export const searchCollaborators = createAsyncAction(
  "searchCollaborators/request",
  "searchCollaborators/success",
  "searchCollaborators/failure"
)<GetCollaboratorsInterface, CollaboratorState, Error>();

export const getCollaboratorsDetials = createAsyncAction(
  "getCollaboratorsDetials/request",
  "getCollaboratorsDetials/success",
  "getCollaboratorsDetials/failure"
)<
  GetCollaboratorsDetialsInterface,
  GetCollaboratorsDetialsResultInterface,
  Error
>();

export const getLiteProfileAction = createAsyncAction(
  "getLiteProfile/request",
  "getLiteProfile/success",
  "getLiteProfile/failure"
)<GetLiteProfileInterface, LiteProfile[], Error>();

/**
 * Collaborators page number
 */
export const setPage = createStandardAction("searchCollaborators/setPage")<
  number
>();
export const setPersonId = createStandardAction(
  "searchCollaborators/setPersonId"
)<string>();

export type CollaboratorsAction =
  | ActionType<typeof searchCollaborators>
  | ActionType<setPage>
  | ActionType<getCollaboratorsDetials>
  | ActionType<getLiteProfileAction>
  | ActionType<setPersonId>;
