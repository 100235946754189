import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import {
  getPageNum,
  getPageSize,
  getTotalHits
} from "../../../store/selectors";
import { setPageNum } from "../../../store/actions";
import { Pagination as P } from "../common/Pagination";

const mapStateToProps = (state: RootState) => ({
  pageNum: getPageNum(state),
  pageSize: getPageSize(state),
  total: getTotalHits(state)
});

const mapDispatchToProps = {
  loadPage: setPageNum
};

export const Pagination = connect(
  mapStateToProps,
  mapDispatchToProps
)(P);
