/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import { ProfileCard } from "../ProfileShared";
import { RootState } from "../../../../store/reducers";
import { withRouter } from "react-router-dom";
import { styled, B1, B2, B4, H5 } from "@h1eng/ui-components";
import {
  getSelectedProject,
  currentUser,
  getSelectedListId,
  getAvailableLists
} from "../../../../store/selectors";
import { UserVO } from "../../../../models/UserVO";
import { ListMeta } from "../../ListManagement/ListManagementInterfaces";
import {
  ListManipulationInterface,
  addToList
} from "../../../../store/actions";
import { GenericSearchEnum, LiteProfile } from "@h1eng/interfaces";

import { ListManager } from "../../ListManagement/ListManager";
import { ListsForPersonIndicator } from "../../KOLResult/KOLResultCard";
import { IconButton } from "../../../IconButton";

import { formatStats } from "@h1eng/format-util";
import { createAndSortAffiliation } from "../ProfileAffiliationsHelper";
import { openExcel } from "../../KOLResult/helpers/ExcelLinkGenerator";

const Spacer = styled.div`
  margin-bottom: 5px;
`;
const DetailText = styled.div`
  color: #737373;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
`;
const DetailTextPadding = styled.div`
  color: #737373;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  padding-right: 20px;
`;
const DetailTextWrapper = styled.div`
  color: #737373;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    color: #0aaacd;
  }
`;
const DetailTextWrapperNoHover = styled.div`
  color: #737373;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;
const NameWrapper = styled.div`
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  color: #333333;
  margin: 0;
  &:hover {
    cursor: pointer;
    color: #0aaacd;
  }
`;

const StatWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  &:hover * {
    cursor: pointer;
    color: #0aaacd;
  }
`;

const DetailStat = styled.div`
  color: #333333;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    color: #0aaacd;
  }
`;
const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const images: any = {
  saved: "https://d10ba5vkyum61s.cloudfront.net/img/list-management/saved.svg",
  download: "https://d10ba5vkyum61s.cloudfront.net/img/download.svg",
  trendGraph: "https://d10ba5vkyum61s.cloudfront.net/img/trend-graph.png"
};
export interface CollaboratorsCardProps extends LiteProfile {
  collaboratorsDocs: string[];
  docs: { [number]: string[] };
}

interface RootStateProps {
  user: UserVO;
  projectId: string;
  selectedListId: string | null;
  listsAvailable: ListMeta[];
}

interface DispatchFromProps {
  addToList: (opts: ListManipulationInterface) => void;
  setDocumentSearchBarQuery: (t: SetDocumentSearchBarQueryInterface) => void;
}
type Props = RootStateProps & DispatchFromProps & CollaboratorsCardProps;

export class CollaboratorCardClass extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  getTypeAsTitle(kind) {
    switch (Number.parseInt(kind, 10)) {
      case GenericSearchEnum.AFFILIATION:
        return "Awards";
      case GenericSearchEnum.PAYMENTS:
        return "Payments";
      case GenericSearchEnum.GRANTS:
        return "Grants";
      case GenericSearchEnum.PUBLICATION:
        return "Publications";
      case GenericSearchEnum.CLINICALTRIAL:
        return "Clinical Trials";
      case GenericSearchEnum.CONGRESS:
        return "Congresses";
      default:
        return "";
    }
  }
  getTypeLinkPrefix(kind) {
    switch (Number.parseInt(kind, 10)) {
      case GenericSearchEnum.PAYMENTS:
        return "payments";
      case GenericSearchEnum.GRANTS:
        return "grants";
      case GenericSearchEnum.PUBLICATION:
        return "publications";
      case GenericSearchEnum.CLINICALTRIAL:
        return "clinical-trials";
      case GenericSearchEnum.CONGRESS:
        return "congresses";
      default:
        return "";
    }
  }

  renderDoc(kind: any, docs: any[]): JSX.Element {
    const count = docs.filter(
      value => -1 !== this.props.collaboratorsDocs.indexOf(value)
    ).length;
    const title = this.getTypeAsTitle(kind);
    const stat =
      title !== "Payments"
        ? `${docs.length}`
        : `$${formatStats(this.props.payments)}`;
    const linkType = this.getTypeLinkPrefix(kind);
    if (title === "Awards") {
      return;
    }
    if (title === "Payments") {
      return;
    }
    if (count === 0) {
      return (
        <StatWrapper
          onClick={() => this.goToProfile(this.props.personId, linkType)}
        >
          <DetailStat>{stat}&nbsp;</DetailStat>
          <DetailText>{title}</DetailText>
        </StatWrapper>
      );
    }

    return (
      <StatWrapper
        onClick={() => this.goToProfile(this.props.personId, linkType)}
      >
        <DetailStat>{stat}&nbsp;</DetailStat>
        <DetailText>{title}</DetailText>&nbsp;
        {count !== 0 && (
          <DetailTextWrapper>
            (<DetailStat>{count}&nbsp;</DetailStat>
            Collaborations)
          </DetailTextWrapper>
        )}
      </StatWrapper>
    );
  }

  render() {
    const titles =
      createAndSortAffiliation(this.props.titleAndAssociations).sections
        .experience[0] || null;
    const payments = `$${formatStats(this.props.payments)}` || "";
    const paymentsRender = this.props.payments ? (
      <StatWrapper
        onClick={() => this.goToProfile(this.props.personId, "payments")}
      >
        <DetailStat>{payments}&nbsp;</DetailStat>
        <DetailText>{"Payments"}</DetailText>
      </StatWrapper>
    ) : (
      <></>
    );

    return (
      <ProfileCard boxShadowColor={"#060464"}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <CardContainer
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column"
            }}
          >
            <H5 deEmphasized>PROFILE</H5>
            <Spacer />
            <NameWrapper onClick={() => this.goToProfile(this.props.personId)}>
              {this.props.firstName} {this.props.lastName}
              {this.props.designations &&
                this.props.designations.length > 0 &&
                ","}
              &nbsp;
              {this.props.designations && this.props.designations.join(",")}
            </NameWrapper>
            <Spacer />
            <DetailTextWrapperNoHover>
              <DetailText>
                {titles && titles.department && titles.department}
              </DetailText>
              <DetailText>
                {titles && titles.organizationName && titles.organizationName}
              </DetailText>
            </DetailTextWrapperNoHover>
            <Spacer />
            <DetailTextWrapperNoHover>
              {titles && titles.location && titles.location.city && (
                <DetailTextPadding>
                  {titles &&
                    titles.location &&
                    titles.location.city &&
                    titles.location.city}
                  {titles && titles.location && titles.location.city && ","}
                  &nbsp;
                  {titles &&
                    titles.location &&
                    titles.location.state &&
                    titles.location.state}
                </DetailTextPadding>
              )}

              {this.props.email && this.props.email[0] && (
                <DetailTextPadding>{this.props.email[0]}</DetailTextPadding>
              )}

              {this.props.phone && (
                <DetailTextPadding>
                  {this.props.phone && this.props.phone && this.props.phone}
                </DetailTextPadding>
              )}
            </DetailTextWrapperNoHover>
          </CardContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "-5px"
            }}
          >
            <div style={{ display: "flex", justifyItems: "center" }}>
              <IconButton
                onClick={() => openExcel([this.props.personId])}
                img={images.download ? images.download : ""}
                active
              />
              <ListManager personId={this.props.personId} active />
            </div>
            <ListsForPersonIndicator
              personId={this.props.personId}
              listsForPerson={this.props.listsAvailable}
              selectedListId={this.props.selectedListId}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "2px"
          }}
        >
          {Object.keys(this.props.docs).map((key, i) => {
            return this.renderDoc(key, this.props.docs[key]);
          })}
          {paymentsRender}
        </div>
      </ProfileCard>
    );
  }
  goToProfile(id: string, context?: string) {
    window.location.assign(
      context === undefined
        ? `/curie/person/${id}`
        : `/curie/person/${id}/${context}`
    );
  }
}

export const mapStateToProps = (state: RootState): RootStateProps => ({
  projectId: getSelectedProject(state)!,
  user: currentUser(state)!,
  selectedListId: getSelectedListId(state),
  listsAvailable: getAvailableLists(state)
});

export const mapDispatchToProps = {
  addToList: addToList.request
};

export const CollaboratorCard = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CollaboratorCardClass)
);
