export const getItemRangeInPage = ({
  pageNum,
  perPage,
  count
}: {
  pageNum: number;
  perPage: number;
  count: number;
}): [number, number] => {
  const start = pageNum * perPage + 1;

  let end = start + perPage - 1;

  if (end >= count) end = count;

  return [start, end];
};
