import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Anchor = styled.a`
  font-family: Montserrat;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.color || "#333"}
  &:hover {
    text-decoration: underline;
  }
`;

export interface EllipsisProps {
  onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => any;
  style?: React.CSSProperties;
  color?: string;
}

export const Ellipsis: React.SFC<EllipsisProps> = props => (
  <Anchor {...props} />
);
