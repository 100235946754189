import { styled } from "./themes/styled-components";
import { themeColors } from "./Colors";

const BASE_INPUT = styled.input`
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-family: Montserrat;
  font-size: 14px;

  color: ${themeColors.themeBodyDefualt};
  background-color: white;

  &::placeholder {
    font-style: italic;
  }

  &:-ms-input-placeholder {
    font-style: italic;
  }
`;

export const PRIMARY_INPUT = styled(BASE_INPUT)<{ block: boolean }>`
  padding: 10px;
  width: ${props => (props.block ? "100%" : "auto")};
`;

export const SECONDARY_INPUT = styled(BASE_INPUT)`
  padding: 5px 0px;
  border-bottom: 2px solid #060464;
`;
