import { createSelector } from "reselect";
import { RootState } from "../reducers";

export * from "./authSelectors";
export * from "./kolCardSelectors";
export * from "./projectSelectors";
export * from "./searchResultSelectors";
export * from "./listSelectors";
export * from "./searchBar";
export * from "./documentSearchBar";
export * from "./searchFilterSelectors";

const getState = (state: RootState) => state;

export const getIsSearchLoading = createSelector(
  getState,
  state => state.kolCards.loading || state.searchResults.loading
);
