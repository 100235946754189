import * as React from "react";
import { PRIMARY_BUTTON, SECONDARY_INPUT } from "@h1eng/ui-components";

interface Props {
  onListNameChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  createList: (e?: React.SyntheticEvent<HTMLElement>) => void;
  listName?: string;
}

export class NewListForm extends React.Component<Props> {
  private input = React.createRef<HTMLInputElement>();

  componentDidMount() {
    const node = this.input.current;
    if (node) node.focus();
  }

  handleSubmit = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    if (!this.props.listName) return;

    this.props.createList(e);
  };

  render() {
    const { onListNameChange } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <SECONDARY_INPUT
          type="text"
          placeholder="List Name"
          onChange={onListNameChange}
          innerRef={this.input}
          style={{ backgroundColor: "transparent" }}
        />
        <br />
        <br />
        <PRIMARY_BUTTON block small onClick={this.handleSubmit}>
          Create New List
        </PRIMARY_BUTTON>
      </form>
    );
  }
}
