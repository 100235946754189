import { Middleware } from "redux";
import { getType } from "typesafe-actions";
import { createGrowler, findAndClose } from "../actions/growler";

export const GrowlerAutoClose: Middleware = store => next => action => {
  // Do stuff
  switch (action.type) {
    case getType(createGrowler):
      setTimeout(() => {
        store.dispatch(findAndClose(action.payload));
      }, 5000);
  }

  return next(action);
};
