/* tslint:disable:cyclomatic-complexity */

export function ordinalNumber(num: number) {
  let ordinal: string = "th";

  const mod10 = num % 10;
  const mod100 = num % 100;

  if (isNaN(mod10) || isNaN(mod100)) return "0th";

  if (mod10 === 1 && mod100 !== 11) {
    ordinal = "st";
  } else if (mod10 === 2 && mod100 !== 12) {
    ordinal = "nd";
  } else if (mod10 === 3 && mod100 !== 13) {
    ordinal = "rd";
  }

  return `${num}${ordinal}`;
}
