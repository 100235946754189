/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { B1, B2, B4, styled, H5 } from "@h1eng/ui-components";
import { margins } from "../../Typeography";
import * as _ from "lodash";
import {
  CongressesInterface,
  CongressInterface,
  CombinedDocumentsType,
  GenericSearchResultInterface,
  GenericSearchEnum
} from "@h1eng/interfaces";
import { Card } from "./ProfileElements";
import { SortMode } from "./ProfileDropDown";
import { ProfileCard, HorizontalProfileRow } from "./ProfileShared";
import { connect } from "react-redux";
import {
  getDocumentSearch,
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText
} from "../../../store/selectors";
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
} from "../../../store/actions";
import { MultiRender } from "./MultiDocRender";
import {
  SetDocumentSearchBarQueryInterface,
  ProfileDocumentSearch
} from "./ProfileDocumentSearch";
import { Bold14 } from "./ProfileOverStats";
import {
  SearchBarQureyHelper,
  SearchBarQureyDisplay,
  filterDocsByDate,
  filterFoundDocs
} from "./SearchHelpFunctions";
import { DateRangeDropdown } from "../common/DateRangeDropdown";
import { formatDate } from "../DocumentViews/ClinicalTrial/ClinicalTrial";

const BottomDetails = styled.div`
  padding-top: ${margins.publicationsCard};
  > * {
    padding-right: 10px;
  }
  display: flex;
`;

const ClinicalTrialShadedGrid = styled.div`
  display: flex;
  > * {
    padding-right: 10px;
  }
`;
const StatFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
`;

const StatsContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-right: 300px;
`;

const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ecf0f1;
`;
const StatsInLine = styled.div`
  display: inline-flex;
`;
const Spacer = styled.div`
  margin-bottom: 5px;
`;

const CongressTitle = styled.div`
  font-size: 14px;
  font-family: Montserrat;
  color: #333;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-weight: 600;
`;
const TopCongress = styled.div`
  color: #333333;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
`;
const TopCongressInfo = styled.div`
  color: #333333;
  font-family: Montserrat;
  font-size: 14px;
`;

// @ts-ignore
export const CONGRESSCARD: React.SFC<CongressInterface> = props => {
  return (
    <ProfileCard boxShadowColor={"#4e50a7"}>
      <H5 deEmphasized>{`CONGRESS - ${props.type.toUpperCase()}`}</H5>
      <Spacer />
      <CongressTitle>{props.title}</CongressTitle>
      <Spacer />
      <HorizontalProfileRow>
        {props.authors.length && <B4>{props.authors.join(", ")}</B4>}
      </HorizontalProfileRow>
      <BottomDetails>
        {!!props.presentedDate && <B1 small>Presented:</B1>}
        {!!props.presentedDate && (
          <B2 small deEmphasized>
            {formatDate(new Date(props.presentedDate))}
            &nbsp;
          </B2>
        )}

        {!!props.conference && <B1 small>Conference:</B1>}
        {!!props.conference && (
          <B2 small deEmphasized>
            {props.conference}
            &nbsp;
          </B2>
        )}
        {!!props.sessionType && <B1 small>Session Type:</B1>}
        {!!props.sessionType && (
          <B2 small deEmphasized>
            {props.sessionType}
            &nbsp;
          </B2>
        )}
      </BottomDetails>
    </ProfileCard>
  );
};
interface HeaderInterface {
  conferences: number;
  sessions: number;
  posters: number;
  congressAvgPerYear: number;
  topCongresss: string;
  total: number;
}
const getConAvgMoneyPerYear = (congress: CongressInterface[]) => {
  const years = new Set<number>();
  congress.forEach(cv => {
    years.add(new Date(cv.presentedDate).getFullYear());
  });
  return congress.length / years.size;
};

const genrateCongress = (
  docs: CongressInterface[],
  keyForTop?: string
): HeaderInterface => {
  const sums = docs.reduce(
    (pv, cv) => {
      if (cv.conference in pv.counter) {
        cv.type === "poster"
          ? (pv.counter[cv.conference].poster += 1)
          : (pv.counter[cv.conference].session += 1);
      } else {
        pv.counter[cv.conference] = {
          session: cv.type === "session" ? 0 : 1,
          poster: cv.type === "poster" ? 1 : 0
        };
      }
      return pv;
    },
    { counter: {} } as {
      counter: { [key: string]: { session: number; poster: number } };
    }
  );
  const key = { name: "", total: 0 };
  Object.keys(sums.counter).forEach(r => {
    if (
      (keyForTop && keyForTop === r) ||
      (!keyForTop &&
        sums.counter[r].poster + sums.counter[r].session > key.total)
    ) {
      key.name = r;
      key.total = sums.counter[r].poster + sums.counter[r].session;
    }
  });

  return {
    conferences: docs.length,
    sessions: docs.filter(e => {
      return e.type === "session";
    }).length,
    posters: docs.filter(e => {
      return e.type === "poster";
    }).length,
    congressAvgPerYear: getConAvgMoneyPerYear(docs),
    topCongresss: key.name,
    total: key.total
  };
};
const getHeader = (
  header: HeaderInterface,
  OpenPaymemnts: CongressInterface[]
) => {
  const top = getTopCongress(congressGroupedByConfrenceName(OpenPaymemnts));
  return (
    <Header>
      <div style={{ marginBottom: "10px" }}>
        <H5 deEmphasized>SUMMARY</H5>
      </div>
      <ClinicalTrialShadedGrid>
        <StatsContainerFlex>
          <StatFlex>
            <B1>Total&nbsp;</B1> <B2> {header.conferences}</B2>{" "}
          </StatFlex>
          <StatFlex>
            <B1>Posters&nbsp;</B1>
            <B2>{header.posters}</B2>{" "}
          </StatFlex>
          <StatFlex>
            <B1>Sessions&nbsp;</B1>
            <B2>{header.sessions}</B2>{" "}
          </StatFlex>
        </StatsContainerFlex>
        <>{top}</>
      </ClinicalTrialShadedGrid>
    </Header>
  );
};

const congressGroupedByConfrenceName = (
  congress: CongressInterface[]
): {
  name: string;
  total: number;
}[] => {
  const grouped: { [conference: string]: CongressInterface[] } = _.groupBy(
    congress,
    (e: CongressInterface) => e.conference
  );
  const totalGrouped: { [conference: string]: number } = Object.keys(
    grouped
  ).reduce(
    (pv: { [conference: string]: number }, cv: string) => {
      pv[cv] = grouped[cv].length;
      return pv;
    },
    {} as { [conference: string]: number }
  );

  const set = Object.keys(totalGrouped)
    .sort((a, b) => {
      return totalGrouped[b] - totalGrouped[a];
    })
    .reduce(
      (pv: { name: string; total: number }[], cv: string) => {
        pv.push({ total: totalGrouped[cv], name: cv });
        return pv;
      },
      [] as {
        name: string;
        total: number;
      }[]
    );
  return set.length > 3 ? set.slice(0, 3) : set;
};

const getTopCongress = (
  top: {
    name: string;
    total: number;
  }[]
) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
      <TopCongress>Top Congresses </TopCongress>
      {top.map((e, i) => {
        return (
          <div
            key={`top-congress-${e.name}-${i}`}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <TopCongressInfo> {e.name} </TopCongressInfo>
            <TopCongressInfo> {`${e.total} Presentations`} </TopCongressInfo>
          </div>
        );
      })}
    </div>
  );
};

const getHeaderStats = (
  searcherd: HeaderInterface,
  filteredDocs: CongressInterface[],
  congressCounts: CongressCounts
) => {
  const filtered = genrateCongress(filteredDocs, searcherd.topCongresss);
  const top = getTopCongress(congressGroupedByConfrenceName(filteredDocs));

  return (
    <Header>
      <div style={{ marginBottom: "10px" }}>
        <H5 deEmphasized>SUMMARY</H5>
      </div>
      <ClinicalTrialShadedGrid>
        <StatsContainerFlex>
          <StatFlex>
            <B1>Total&nbsp;</B1>
            <StatsInLine>
              <Bold14>{searcherd.conferences}&nbsp;</Bold14>/
              <B2> {congressCounts.total}</B2>
            </StatsInLine>
          </StatFlex>
          <StatFlex>
            <B1>Posters&nbsp;</B1>
            <StatsInLine>
              <Bold14>{searcherd.posters}&nbsp;</Bold14>/
              <B2>{congressCounts.posters}</B2>
            </StatsInLine>
          </StatFlex>
          <StatFlex>
            <B1>Sessions&nbsp;</B1>
            <StatsInLine>
              <Bold14>{searcherd.sessions}&nbsp;</Bold14>/
              <B2>{congressCounts.sessions}</B2>
            </StatsInLine>
          </StatFlex>
        </StatsContainerFlex>
        {top}
      </ClinicalTrialShadedGrid>
    </Header>
  );
};

interface ProfileCongressCardClassDispatcher {
  setDocumentSearchBarQuery: (t: SetDocumentSearchBarQueryInterface) => void;
  setDocumentSearchBarFilterDate: (t: {
    filterDate: number;
    displayFilterDateString: string;
  }) => void;
  documentSearch: GenericSearchResultInterface[];
  searchBarState: { query: string[] };
  filterDate: number;
  filterDateText: string;
}

interface CongressCounts {
  total: number;
  posters: number;
  sessions: number;
}

export class ProfileCongressCardClass extends React.Component<
  CongressesInterface & ProfileCongressCardClassDispatcher,
  {
    sortMode: SortMode;
    viewFilter: any;
    typeFilter: any;
  }
> {
  state = {
    sortMode: SortMode.NEWEST,
    viewFilter: () => true,
    typeFilter: () => true
  };

  get congressCounts(): CongressCounts {
    return {
      total: this.props.congressTotal,
      posters: this.props.posters,
      sessions: this.props.sessions
    };
  }

  handleChange = (sortMode: SortMode) => {
    this.setState({ sortMode });
  };

  render() {
    const CongressFilterdByDate = filterDocsByDate(
      this.props.filterDate,
      this.props.filterDateText,
      this.props.congressess!
    );

    const CongressSearched = filterFoundDocs(
      this.props.documentSearch,
      CongressFilterdByDate
    );

    const summary =
      this.props.searchBarState.query.length > 0
        ? getHeaderStats(
            genrateCongress(CongressSearched),
            CongressSearched,
            this.congressCounts
          )
        : getHeader(
            genrateCongress(CongressFilterdByDate),
            CongressFilterdByDate
          );
    return (
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "20px",
            paddingLeft: "20px"
          }}
        >
          <ProfileDocumentSearch personId={this.props.personId} />
          <DateRangeDropdown />
        </div>
        <SearchBarQureyHelper />
        <SearchBarQureyDisplay />

        {summary}
        <MultiRender
          docs={this.props.congressess!.map(e => e as CombinedDocumentsType)}
          dateFilter={this.props.filterDate}
          types={[GenericSearchEnum.CONGRESS]}
          limitNoSearch={false}
        />
      </Card>
    );
  }
}
const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  searchBarState: getDocumentSearchBarState(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state)
});

const mapDispatchToProps = {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
};

export const ProfileCongressCard = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ProfileCongressCardClass as any);
