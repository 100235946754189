import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Root = styled.nav`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding-top: 15px;
`;

export const PaginationRoot: React.SFC = ({ children }) => (
  <Root role="navigation" aria-label="Pagination Navigation">
    {children}
  </Root>
);
