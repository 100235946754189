import * as React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { styled, B1, B2, H5 } from "@h1eng/ui-components";
import {
  OpenPaymentsInterface,
  OpenPaymentInterface,
  PaymentsPieGraph,
  PieGraphData
} from "@h1eng/interfaces";
import { formatStats } from "@h1eng/format-util";
import { PaymentTable } from "./Payments/PaymentsTable";
import {
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText
} from "../../../store/selectors";
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
} from "../../../store/actions";
import { PieGraphClass } from "./DataVisualizations/components/PieGraph";
import { filterDocsByDate } from "./SearchHelpFunctions";
import { DateRangeDropdown } from "../common/DateRangeDropdown";

const PaymentsHeaderGrid = styled.div`
  border-bottom: 2px solid rgb(246, 246, 246);
  padding-bottom: 10px;
  padding-left: 20px;
`;
const Column = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;

const PaymentsReceivedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Card = styled.div`
  background-color: #ffffff;
  border-radius: 2px;
  border: solid 1px #ecf0f1;
`;

const PaymentsHeader = (props: OpenPaymentsInterface) => {
  return (
    <PaymentsHeaderGrid>
      <div style={{ marginBottom: "10px" }}>
        <H5 deEmphasized>SUMMARY</H5>
      </div>
      {props.totalMoneyReceived && props.totalMoneyReceived !== 0 && (
        <Column>
          <B1>Total payments received&nbsp;</B1>
          <B2>${formatStats(props.totalMoneyReceived)}</B2>
        </Column>
      )}
      {props.avgMoneyPerYear && (
        <Column>
          <B1>Avg payments per year&nbsp;</B1>
          <B2>${formatStats(props.avgMoneyPerYear)} </B2>
        </Column>
      )}
    </PaymentsHeaderGrid>
  );
};

export const createGraphData = (
  key: string,
  payments: OpenPaymentInterface[]
) => {
  const result = _.chain(payments)
    .groupBy(key)
    .map((e: OpenPaymentInterface[], name: string) => {
      const amount = e.reduce((pv, cv) => (pv += cv.amount), 0);
      return { amount, name };
    })
    .value()
    .sort((a, b) => b.amount - a.amount);

  if (result.length > 3) {
    const set = result.slice(0, 3);
    const add = result.slice(3, result.length);
    const amount = add.reduce((pv, cv) => (pv += cv.amount), 0);
    set.push({ amount, name: `Other(${result.length - 3})` });
    return set as PaymentsPieGraph[];
  }

  return result as PaymentsPieGraph[];
};

export const payemntsByCompany = (payments: OpenPaymentInterface[]) => {
  const data: { [payer: string]: OpenPaymentInterface[] } = _.groupBy(
    payments!,
    "payerName"
  );
  const keys = Object.keys(data);
  return { data, keys };
};
export const sortInnerPayments = (keys: string[], data: any) => {
  keys.forEach(
    e =>
      (data[e] = _.orderBy(data[e], (a: OpenPaymentInterface) => {
        return a.date;
      }))
  );
  return data;
};
export class PaymentsSectionClass extends React.Component<
  OpenPaymentsInterface & { filterDate: number; filterDateText: string }
> {
  get payments(): OpenPaymentInterface[] {
    return filterDocsByDate(
      this.props.filterDate,
      this.props.filterDateText,
      this.props.payments
    );
  }

  render() {
    const { props } = this;
    const byPayer = createGraphData("payerName", this.payments);
    const byTreatment = createGraphData("drugOrDeviceName", this.payments);
    const byPayerData: PieGraphData = { payments: byPayer };
    const byTreatmentData: PieGraphData = { payments: byTreatment };
    const set = payemntsByCompany(this.payments);
    return (
      <Card>
        <PaymentsReceivedContainer>
          <div
            style={{
              marginRight: "20px",
              marginLeft: "20px",
              justifyContent: "flex-end",
              width: "100%",
              display: "flex"
            }}
          >
            <div style={{ width: "65%" }} />
            <DateRangeDropdown />
          </div>
          <PaymentsHeader {...props} />
          <div
            style={{
              display: "flex",
              borderBottom: "2px solid rgb(246, 246, 246)"
            }}
          >
            <PieGraphClass data={byPayerData} title={"Company Payments"} />
            <PieGraphClass data={byTreatmentData} title={"By Treatment"} />
          </div>
          <PaymentTable payments={set.data} />
        </PaymentsReceivedContainer>
      </Card>
    );
  }
}
const mapStateToProps = (state: any) => ({
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state)
});

const mapDispatchToProps = {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
};

export const PaymentsSection = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsSectionClass as any);
