/* tslint:disable:cyclomatic-complexity */
const Fuse = require("fuse.js");
import {
  TextFilter,
  CustomSortBy,
  CustomSortByConstructor
} from "@h1eng/interfaces";
import * as uniqid from "uniqid";
import { ENDPOINTS } from "./api-endpoints";

/**
 * Used to get the initial options, so filters
 * can be selected without searching
 */
export const getInitialOptions = async () => {
  const res = await fetch(ENDPOINTS.searchFilters.initialOptions, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include"
  });

  return res.json();
};

export const getOptions = async (filter: TextFilter): Promise<string[]> => {
  const res = await fetch(ENDPOINTS.searchFilters.getSuggestion, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(filter)
  });

  return res.json();
};

export const searchOptions = async (
  filter: TextFilter,
  options: string[]
): Promise<string[]> => {
  const fuseConfig = {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1
  };

  if (!filter.query || !options || options.length < 1) {
    return [];
  }

  try {
    const fuse = new Fuse(options, fuseConfig);

    return fuse
      .search(filter.query)
      .map((i: number) => options[i])
      .filter(Boolean);
  } catch (e) {
    console.log(e);

    return [];
  }
};

// Custom Sorting

export const fetchSortOptions = async (
  userId: string
): Promise<CustomSortBy[]> => {
  const res = await fetch(
    `${ENDPOINTS.searchFilters.customSorting}?userId=${userId}`,
    {
      method: "GET",
      ...createCustomSortingPayload(userId)
    }
  );

  return res.json();
};

export const createSortOption = async (
  newOption: CustomSortByConstructor,
  options: CustomSortBy[],
  userId: string
): Promise<CustomSortBy[]> => {
  const customSorting = [
    {
      ...newOption,
      id: uniqid.time()
    },
    ...options
  ];

  const res = await postCustomSorting(
    createCustomSortingPayload(userId, customSorting)
  );

  return res;
};

export const editSortOption = async (
  newOption: CustomSortBy,
  options: CustomSortBy[],
  userId: string
): Promise<CustomSortBy[]> => {
  const untouched = options.filter(i => i.id !== newOption.id);

  const customSorting = [newOption, ...untouched];

  const res = await postCustomSorting(
    createCustomSortingPayload(userId, customSorting)
  );

  return res;
};

export const deleteSortOption = async (
  toDelete: string,
  options: CustomSortBy[],
  userId: string
): Promise<CustomSortBy[]> => {
  const customSorting = options.filter(i => i.id !== toDelete);

  const res = await postCustomSorting(
    createCustomSortingPayload(userId, customSorting)
  );

  return res;
};

function createCustomSortingPayload(
  userId: string,
  customSorting?: CustomSortBy[]
) {
  const res: Partial<RequestInit> = {
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include"
  };

  if (customSorting) {
    res.body = JSON.stringify({
      userId,
      customSorting
    });
  }

  return res;
}

async function postCustomSorting(payload: Partial<RequestInit>) {
  const res = await fetch(ENDPOINTS.searchFilters.customSorting, {
    method: "POST",
    ...payload
  });

  return res.json();
}
