import * as React from "react";
import { connect } from "react-redux";
import {
  PublicationInterface,
  GenericSearchResultInterface,
  GraphDatum,
  YearCount
} from "@h1eng/interfaces";
import { filterDocsByDate, filterFoundDocs } from "../../SearchHelpFunctions";
import {
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText,
  getDocumentSearch
} from "../../../../../store/selectors";
import { BAR_GRAPH_LIMIT } from "..";
import { BarGraph } from "../components/BarGraph";
import { LineGraph } from "../components/LineGraph";
import { fillInterimYears } from "../lib/helpers";

interface GraphProps {
  publications: PublicationInterface[];
}

interface DispatchProps {
  filterDate: number;
  filterDateText: string;
  documentSearch: GenericSearchResultInterface[];
}

class Publications extends React.Component<GraphProps & DispatchProps> {
  /**
   * All publications for a KOL within the a time span
   *
   * @readonly
   * @memberof Publications
   */
  get publications() {
    return filterDocsByDate(
      this.props.filterDate,
      this.props.filterDateText,
      this.props.publications
    );
  }

  /**
   * Publications based on search queries
   *
   * @readonly
   * @memberof Publications
   */
  get searchedPublications() {
    return filterFoundDocs(this.props.documentSearch, this.publications);
  }

  /**
   * Formats the data in a way that the graph can render it
   *
   * @readonly
   * @memberof Publications
   */
  getData() {
    const counts: YearCount = this.publications.reduce(
      this.publicationsReducer,
      {} as YearCount
    );

    const { searchedPublications } = this;

    for (const publication of searchedPublications) {
      const year = this.getPublicationYear(publication);
      if (year) {
        counts[year] = counts[year] || { year, total: 0, searched: 0 };

        counts[year].searched += 1;
      }
    }

    const filledCounts = fillInterimYears(counts, this.props.filterDateText);

    const res = Object.values(filledCounts);

    return res.sort((a: any, b: any) => a.year - b.year) as GraphDatum[];
  }

  render() {
    const data = this.getData();

    const props = {
      data,
      yAxisLabel: "Publications Count",
      title: "Publications By Year",
      primaryColor: "#80d0e1"
    };

    return (
      <div style={{ marginBottom: 20 }}>
        {data.length > BAR_GRAPH_LIMIT ? (
          <LineGraph {...props} />
        ) : (
          <BarGraph {...props} />
        )}
      </div>
    );
  }

  private getPublicationYear = (publication: PublicationInterface) => {
    if (!publication.datePublished) return;

    return new Date(publication.datePublished).getFullYear();
  };

  /**
   * Method used for creating an object of publication counts
   *
   * @memberof Publications
   */
  private publicationsReducer = (
    res: YearCount,
    publication: PublicationInterface
  ) => {
    const year = this.getPublicationYear(publication);
    if (!year) return res;

    if (!res.hasOwnProperty(year)) {
      res[year] = {
        year,
        total: 0,
        searched: 0
      } as GraphDatum;
    }

    res[year].total += 1;

    return res;
  };
}

const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state)
});

export const PublicationsVisualization = connect<any, any, any>(
  mapStateToProps
)(Publications);
