import * as React from "react";

export const XAxisTick: React.SFC<any> = props => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#333"
        transform="rotate(-45)"
        style={{
          fontFamily: "Montserrat",
          fontSize: 12
        }}
      >
        {payload.value}
      </text>
    </g>
  );
};
