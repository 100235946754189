import * as React from "react";
import { connect } from "react-redux";
import {
  ClinicalTrialInterface,
  GenericSearchResultInterface,
  GraphDatum,
  YearCount
} from "@h1eng/interfaces";
import { filterDocsByDate, filterFoundDocs } from "../../SearchHelpFunctions";
import {
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText,
  getDocumentSearch
} from "../../../../../store/selectors";
import { BAR_GRAPH_LIMIT } from "../";
import { BarGraph } from "../components/BarGraph";
import { LineGraph } from "../components/LineGraph";
import { fillInterimYears } from "../lib/helpers";

interface GraphProps {
  trials: ClinicalTrialInterface[];
}

interface DispatchProps {
  filterDate: number;
  filterDateText: string;
  documentSearch: GenericSearchResultInterface[];
}

class ClinicalTrials extends React.Component<GraphProps & DispatchProps> {
  /**
   * All trials for a KOL within the a time span
   *
   * @readonly
   * @memberof ClinicalTrials
   */
  get trials() {
    return filterDocsByDate(
      this.props.filterDate,
      this.props.filterDateText,
      this.props.trials
    );
  }

  /**
   * Trials based on search queries
   *
   * @readonly
   * @memberof ClinicalTrials
   */
  get searchedTrials() {
    return filterFoundDocs(this.props.documentSearch, this.trials);
  }

  /**
   * Formats the data in a way that the graph can render it
   *
   * @readonly
   * @memberof ClinicalTrials
   */
  getData() {
    const counts: YearCount = this.trials.reduce(
      this.trialsReducer,
      {} as YearCount
    );

    const { searchedTrials } = this;

    for (const trial of searchedTrials) {
      const year = this.getTrialYear(trial);
      if (year) {
        counts[year] = counts[year] || { year, total: 0, searched: 0 };

        counts[year].searched += 1;
      }
    }

    const filledCounts = fillInterimYears(counts, this.props.filterDateText);

    const res = Object.values(filledCounts);

    return res.sort((a: any, b: any) => a.year - b.year) as GraphDatum[];
  }

  render() {
    const data = this.getData();

    const props = {
      data,
      yAxisLabel: "Clinical Trials Count",
      title: "Clinical Trials in Progress By Year",
      primaryColor: "#7dfccc"
    };

    return (
      <div style={{ marginBottom: 20 }}>
        {data.length > BAR_GRAPH_LIMIT ? (
          <LineGraph {...props} />
        ) : (
          <BarGraph {...props} />
        )}
      </div>
    );
  }

  private getTrialYear = (trial: ClinicalTrialInterface) => {
    if (!trial.startDate) return;

    try {
      return new Date(trial.startDate).getFullYear();
    } catch (e) {
      return;
    }
  };

  /**
   * Method used for creating an object of trial counts
   *
   * @memberof ClinicalTrials
   */
  private trialsReducer = (res: YearCount, trial: ClinicalTrialInterface) => {
    const year = this.getTrialYear(trial);
    if (!year) return res;

    if (!res.hasOwnProperty(year)) {
      res[year] = {
        year,
        total: 0,
        searched: 0
      } as GraphDatum;
    }

    res[year].total += 1;

    return res;
  };
}

const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state)
});

export const ClinicalTrialsVisualization = connect<any, any, any>(
  mapStateToProps
)(ClinicalTrials);
