/**
 * Gets the number of pages in a paginated array
 */
export const pageCount = ({
  count,
  pageSize = 15
}: {
  count: number;
  pageSize?: number;
}) => {
  return Math.ceil(count / pageSize);
};
