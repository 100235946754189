import { styled } from "@h1eng/ui-components";

export const ProfileCard = styled.div<{ boxShadowColor?: string }>`
  display: flex;
  flex-direction: column;
  box-shadow: inset 4px 0px 0px ${props => props.boxShadowColor};
  border-top: 1px solid #ecf0f1;
  border-bottom: 1px solid #ecf0f1;
  padding: 20px;
`;

export const HorizontalProfileRow = styled.div`
  display: flex;
  flex-direction: row;
`;
