import { styled, themeColors } from "@h1eng/ui-components";
import * as React from "react";
import OutsideAlerter from "../../OutsideAlerter";
import { UserPersonList } from "../../../../../../../node_modules/@h1eng/interfaces";
import { withRouter, RouteComponentProps } from "react-router";
import { fetchAvailableLists } from "../../../apis/list";
import { currentUser, getSelectedProject } from "../../../store/selectors";
import { connect } from "react-redux";
import { UserVO } from "../../../models/UserVO";

const OPTIONS = styled.div`
  background-color: #f6f6f6;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: inherit;
`;
const OPTION = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${themeColors.themeBodyDefualt}
  padding: 10px;
`;

const DropDownContainer = styled.div`
  display: flex;
  z-index: 100000;
  flex-direction: column;
`;

const StyledActionSelector = styled.div`
  border-bottom: 1px solid black;
`;
const StyledActionSelectorStyled = styled.div``;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  margin-left: 10px;
`;
const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  margin-left: 10px;
`;
const ActionsBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  &:hover {
    cursor: pointer;
  }
`;

const SelectedOption = styled.div`
  border-bottom: solid 2px #80d0e1;
  font-size: 12px;
  height: 25px;
`;
const SelectedOptionNoBottom = styled.div`
  font-size: 12px;
  height: 25px;
`;
const Path = styled.div`
  margin-left: 10px;
  :hover {
    background-color: #eeeeee;
    cursor: pointer;
    cursor: hand;
  }
  padding-right: 10px;
`;
const Veiw = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  padding-right: 20px;
`;

const DropDownText = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
`;
const DropDownTextOpen = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  color: #333333;
`;

interface PassedProps {
  paths: UserPersonList[];
  selected: UserPersonList;
}
type CombinedProps = { user: UserVO; projectId: string } & PassedProps &
  RouteComponentProps<any>;

export class ProfileSavedListClass extends React.Component<CombinedProps, any> {
  state = {
    open: false,
    subpath: "",
    vewing: "Test"
  };
  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const ActionSelector = this.state.open
      ? StyledActionSelector
      : StyledActionSelectorStyled;

    const selectedOptionPicker = !this.state.open ? (
      <DropDownText>{this.props.selected.listName}</DropDownText>
    ) : (
      <DropDownTextOpen>{this.props.selected.listName}</DropDownTextOpen>
    );
    const Selected = this.state.open ? SelectedOptionNoBottom : SelectedOption;
    return (
      <div
        style={{
          zIndex: 400000,
          display: "flex",
          justifyContent: "flex-end",
          height: "40px",
          maxHeight: "40px",
          paddingTop: "20px"
        }}
      >
        <OutsideAlerter callBack={() => this.setState({ open: false })}>
          <DropDownContainer>
            <ActionSelector onClick={() => this.toggleOpen()}>
              <ActionsBarContainer>
                <Veiw>Saved List:</Veiw>
                <Selected>{selectedOptionPicker}</Selected>
                {this.state.open ? <ArrowDown /> : <ArrowUp />}
              </ActionsBarContainer>
            </ActionSelector>
            {this.state.open && this.renderOpen()}
          </DropDownContainer>
        </OutsideAlerter>
      </div>
    );
  }
  renderOpen() {
    const inner = this.props.paths.map((a: UserPersonList) => (
      <Path
        onClick={() => {
          fetchAvailableLists(this.props.user.id, this.props.projectId).then(
            e => {
              e.forEach((element: any) => {
                if (element.listPersonJoins.find((i: any) => i.id === a.id)) {
                  this.props.history.push({
                    pathname: `/saved-lists/${element.id}`
                  });
                }
              });
            }
          );
        }}
      >
        {<OPTION>{a.listName}</OPTION>}
      </Path>
    ));
    return <OPTIONS>{inner}</OPTIONS>;
  }
}

export const mapStateToProps = (state: any) => ({
  user: currentUser(state),
  projectId: getSelectedProject(state)
});

export const ProfileSavedList = connect<any, any, any>(
  mapStateToProps,
  {}
)(withRouter(ProfileSavedListClass));
