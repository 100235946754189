import * as React from "react";
import { connect } from "react-redux";
import { FilterSection } from "../FilterSection";
import { RootState } from "../../../../../store/reducers";
import { getCongressesFiltersCount } from "../../../../../store/selectors";
import { TextFilterWrapper } from "../../components/TextFilterWrapper";
import { CongressesMinCount } from "./CongressesMinCount";
import { NameFilters } from "./NameFilters";
// import { TypeCheckboxFilters } from "./TypeCheckboxFilters";

interface MappedStateProps {
  filterCount: number;
}

type Props = MappedStateProps;

function CongressesComponent(props: Props) {
  return (
    <FilterSection
      sectionKey="congresses"
      title="Congresses"
      count={props.filterCount}
    >
      <TextFilterWrapper>
        <CongressesMinCount />
      </TextFilterWrapper>
      <TextFilterWrapper>
        <NameFilters />
      </TextFilterWrapper>
      {/* <TextFilterWrapper>
        <TypeCheckboxFilters />
      </TextFilterWrapper> */}
    </FilterSection>
  );
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  filterCount: getCongressesFiltersCount(state)
});

export const Congresses = connect(mapStateToProps)(CongressesComponent);
