import * as React from "react";
import { connect } from "react-redux";
import {
  CustomSortBy,
  CustomSortByConstructor,
  DefaultScoreWeights
} from "@h1eng/interfaces";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { CustomSortOptionManager } from "../CustomSortOptionManager";
import { createCustomSortingOption } from "../../../../../../store/actions";
import { RootState } from "../../../../../../store/reducers/index";
import { validate } from "./validate";

const styles = (theme: Theme) =>
  createStyles({
    dialogContentRoot: {
      overflowX: "hidden",
      paddingTop: theme.spacing.unit * 3
    },
    contentRoot: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "auto"
    }
  });

interface MappedStateProps {
  sortOptions: CustomSortBy[];
}

interface DispatchProps {
  create: (entity: CustomSortByConstructor) => void;
}

interface ComponentProps {
  onClose: () => void;
}

type Props = ComponentProps &
  MappedStateProps &
  DispatchProps &
  WithStyles<typeof styles>;

interface State {
  sortOption: CustomSortByConstructor;
}

const defaultSortOption: CustomSortByConstructor = {
  ...DefaultScoreWeights,
  name: ""
};

class CreateComponent extends React.Component<Props, State> {
  state: State = {
    sortOption: defaultSortOption
  };

  get submitDisabled() {
    return (
      this.state.sortOption.name.trim() === "" ||
      Boolean(this.validationMessage)
    );
  }

  get validationMessage() {
    return validate(this.state.sortOption, this.props.sortOptions);
  }

  handleOptionUpdate = (sortOption: CustomSortByConstructor) => {
    this.setState({ sortOption });
  };

  handleSave = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (event) {
      event.preventDefault();
    }
    const { sortOption } = this.state;

    if (this.submitDisabled) return;

    this.props.create(sortOption);
    this.props.onClose();
  };

  render() {
    const { classes } = this.props;
    const { sortOption } = this.state;

    return (
      <>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <form onSubmit={this.handleSave}>
            <CustomSortOptionManager
              errorMessage={this.validationMessage}
              customSortBy={sortOption}
              onUpdate={this.handleOptionUpdate}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            role="button"
            onClick={() => {
              this.props.onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleSave}
            disabled={this.submitDisabled}
          >
            Create List
          </Button>
        </DialogActions>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  sortOptions: state.searchFilters.customSortingOptions
});

const mapDispatchToProps: DispatchProps = {
  create: createCustomSortingOption.request
};

export const Create = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateComponent));
