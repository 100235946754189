import * as React from "react";
import { styled, B4 } from "@h1eng/ui-components";
import OutsideAlerter from "../../OutsideAlerter";

const BulkActionsContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 1000;
  flex-direction: column;
`;
const StyledActionSelector = styled.div`
  border-bottom: 3px solid #060464;
  width: 150px;
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
`;
const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid black;
`;

const ActionsBarContainer = styled.div`
  display: flex;
  margin-bottom: 7px;
  align-items: center;
  justify-content: space-between;
`;
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;

  &:hover {
    width: calc(100%);
    background-color: #eeeeee;
    cursor: pointer;
    cursor: hand;
  }
`;

const ActionsContainer = styled.div`
  width: 300px;
  display: flex;
  background-color: #fafafa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const Title = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
`;

const SortBy = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
`;

export enum SortMode {
  RELEVANCE,
  KOLSCORES
}

interface DropDownSelectorProps {
  handleChange: (sortMode: SortMode) => void;
  sortMode: SortMode;
}

export class ScoreSelector extends React.Component<DropDownSelectorProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      newListModalOpen: false,
      renderModal: null,
      value: this.props.sortMode
    };
  }
  toggleOpen() {
    this.setState({ open: !this.state.open });
  }
  renderOpen() {
    return (
      <ActionsContainer>
        <div>
          <Actions>
            <div
              style={{ display: "flex", paddingLeft: "24px" }}
              onClick={() => {
                this.props.handleChange(SortMode.KOLSCORES);
              }}
            >
              <B4>{"Score"}</B4>
            </div>
          </Actions>
          <Actions>
            <div
              style={{ display: "flex", paddingLeft: "24px" }}
              onClick={() => {
                this.props.handleChange(SortMode.RELEVANCE);
              }}
            >
              <B4>{"Relevance"}</B4>
            </div>
          </Actions>
        </div>
      </ActionsContainer>
    );
  }
  render() {
    return (
      <div style={{ zIndex: 4000, width: "150px", display: "flex" }}>
        <div style={{ paddingRight: "17px" }}>
          <SortBy>Sort By:</SortBy>
        </div>
        <OutsideAlerter callBack={() => this.setState({ open: false })}>
          <BulkActionsContainer>
            <StyledActionSelector onClick={() => this.toggleOpen()}>
              <ActionsBarContainer>
                <Title>
                  {" "}
                  {this.props.sortMode === SortMode.RELEVANCE
                    ? "Relevance"
                    : "Score"}{" "}
                </Title>
                {this.state.open ? <ArrowDown /> : <ArrowUp />}
              </ActionsBarContainer>
            </StyledActionSelector>
            {this.state.open && this.renderOpen()}
          </BulkActionsContainer>
        </OutsideAlerter>
      </div>
    );
  }
}

// return (
//   <>
//     <SoryBy>Sort by:</SoryBy>
//     <DROP_DOWN_SELECTOR_CONTAINER
//       value={this.props.sortMode}
//       onChange={(e: any) => {
//         this.props.handleChange(parseInt(e.target.value, 10));
//       }}
//     >
//       <option value={SortMode.RELEVANCE}>Relevance</option>
//       <option value={SortMode.KOLSCORES}>Score</option>
//     </DROP_DOWN_SELECTOR_CONTAINER>
//   </>
