import {
  createAsyncAction,
  createStandardAction,
  ActionType
} from "typesafe-actions";

export interface KolCardsParams {
  projectId: string;
  userId: string;
  personIds: string[];
}

export const kolCardsActionGet = createAsyncAction(
  "kolCards.request",
  "kolCards.success",
  "kolCards.failure"
)<KolCardsParams, any[], Error>();

export const kolCardsActionGetSearch = createAsyncAction(
  "kolCardsActionGetSearch.request",
  "kolCardsActionGetSearch.success",
  "kolCardsActionGetSearch.failure"
);

export const kolSavedListsCardsActionGet = createAsyncAction(
  "kolSavedListsCardsActionGet.request",
  "kolSavedListsCardsActionGet.success",
  "kolSavedListsCardsActionGet.failure"
)<KolCardsParams, any[], Error>();

export const setKolCardsLoading = createStandardAction("kolCards.setLoading")<
  boolean
>();

export const deleteSavedListKolCards = createStandardAction(
  "kolCards.deleteSavedListItems"
)<never>();

export type KOLCardAction =
  | ActionType<typeof kolCardsActionGet>
  | ActionType<typeof kolCardsActionGetSearch>
  | ActionType<typeof kolSavedListsCardsActionGet>
  | ActionType<typeof setKolCardsLoading>
  | ActionType<typeof deleteSavedListKolCards>;
