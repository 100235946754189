import * as React from "react";
import { DropdownOptions } from "./DropdownOptions";
import { DropdownOptionInterface } from "./DropdownOption";
import OutsideAlerter from "../../../OutsideAlerter";
import { styled } from "@h1eng/ui-components";
import { ArrowDown } from "./Arrows";

const Root = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  min-height: 62px;
  align-items: center;
  font-family: Montserrat;
`;

const LabelText = styled.span`
  color: #333333;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
`;

const SelectedOption = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 5px;
  min-width: 150px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
`;

const Wrapper = styled.div`
  position: relative;
`;

export interface DropdownProps {
  options: DropdownOptionInterface[];
  selected: string;
  label?: string;
  onSelect: (value: any, text: string) => any;
}

export interface DropdownState {
  open: boolean;
}

export class Dropdown extends React.Component<DropdownProps, DropdownState> {
  state: DropdownState = {
    open: false
  };

  handleSelect = (value: any, text: string) => {
    this.setState({ open: false }, () => {
      this.props.onSelect(value, text);
    });
  };

  setOpen = (open: boolean) => () => {
    this.setState({ open });
  };

  render() {
    const { label, selected, options } = this.props;
    const { open } = this.state;

    return (
      <Root>
        {!!label && <LabelText className="labelText">{label}</LabelText>}

        <Wrapper>
          {open ? (
            <OutsideAlerter callBack={this.setOpen(false)}>
              <DropdownOptions
                options={options}
                onSelect={this.handleSelect}
                selected={selected}
                close={this.setOpen(false)}
              />
            </OutsideAlerter>
          ) : (
            <SelectedOption
              className="selectedOption"
              onClick={this.setOpen(true)}
            >
              {selected}
              <ArrowDown style={{ marginLeft: 10 }} />
            </SelectedOption>
          )}
        </Wrapper>
      </Root>
    );
  }
}
