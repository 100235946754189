import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  requestCountryOptions,
  setCountryQuery,
  resetInitialOptions,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  map,
  filter,
  catchError
} from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { searchOptions } from "../../../apis/searchFilters";
import { DEBOUNCE_TIME } from ".";

const setCountryQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setCountryQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestCountryOptions.request(state$.value.searchFilters.country)
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getCountryOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestCountryOptions.request)),
    switchMap(({ payload }) =>
      from(
        searchOptions(
          payload,
          state$.value.searchFilters.initialOptions.country
        )
      ).pipe(
        map(requestCountryOptions.success),
        catchError(
          pipe(
            requestCountryOptions.failure,
            of
          )
        )
      )
    )
  );

export default [setCountryQueryFlow, getCountryOptionsFlow];
