import * as React from "react";
import { styled, B2 } from "@h1eng/ui-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
`;

const Title = styled.h4`
  color: #333333;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  margin: 0;
`;

const Img = styled.img`
  width: 181px;
  height: auto;
  margin-bottom: 35px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 256;
`;

const imgUrl = "https://d10ba5vkyum61s.cloudfront.net/img/noresults.png";

export const NoResultsFound: React.SFC<{}> = props => {
  return (
    <Root>
      <Img src={imgUrl} />
      <TextWrapper>
        <Title>No Results Found</Title>
        <B2>Try changing your search or expanding your filters.</B2>
      </TextWrapper>
    </Root>
  );
};
