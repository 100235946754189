import * as React from "react";
import { connect } from "react-redux";
import { getSearchFilters } from "../../../../store/selectors";
import {
  setInstitutionValues,
  setInstitutionQuery
} from "../../../../store/actions";
import { RootState } from "../../../../store/reducers";
import { FilterAutosuggest as Autosuggest } from "../FilterAutosuggest";

interface MappedStateProps {
  options: string[];
  values: string[];
  inputValue: string;
}

interface DispatchProps {
  setValues: (values: string[]) => void;
  handleInputChange: (inputVal: string) => void;
}

type Props = MappedStateProps & DispatchProps;

class InstitutionFiltersComponent extends React.Component<Props> {
  render() {
    const { options, values, setValues } = this.props;

    return (
      <Autosuggest
        values={values}
        setValues={setValues}
        suggestions={options}
        inputLabel="Institution"
        inputValue={this.props.inputValue}
        setInputValue={this.props.handleInputChange}
      />
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => {
  const { institution } = getSearchFilters(state);

  return {
    options: institution.options,
    values: institution.values,
    inputValue: institution.query
  };
};

const mapDispatchToProps: DispatchProps = {
  setValues: setInstitutionValues,
  handleInputChange: setInstitutionQuery
};

export const InstitutionFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionFiltersComponent);
