import * as React from "react";

import { UserVO } from "../models/UserVO";
import { RootState } from "../store/reducers";
import { currentUser } from "../store/selectors/authSelectors";
import { connect } from "react-redux";
import { setToken } from "../store/actions/user";
import { withRouter, RouteComponentProps } from "react-router";
import { ENDPOINTS } from "../apis/api-endpoints";

import { styled, themeColors, SECONDARY_BUTTON } from "@h1eng/ui-components";

interface WelcomeProps {
  user: UserVO | null;
}

interface WelcomeFuncs {
  setToken: (token: string) => void;
}

const PaneTitle = styled.h1`
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 90px;
`;

const Pane = styled.div`
  text-align: center;
  border: 3px solid white;
  border-radius: 10px;
  margin: auto;
  margin-top: 50px;
  max-width: 500px;
  padding-bottom: 20px;
  background-color: ${themeColors.themeBackgroundPurple};
`;

class WelcomePageClass extends React.Component<
  WelcomeProps & WelcomeFuncs & RouteComponentProps<any>
> {
  componentDidMount() {
    this.checkAuthentication();
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  checkAuthentication() {
    if (this.props.user) this.props.history.push("/curie/search");
  }

  startAutoLogin = () => {
    // const { pathname, search } = this.props.location;
    // const pathString = encodeURIComponent(`${pathname}${search}`);
    // window.location.assign(`${ENDPOINTS.login}?location=${pathString}`);

    window.location.assign(`${ENDPOINTS.login}`);
  };

  renderH1Welcome = () => (
    <Pane>
      <PaneTitle>H1.</PaneTitle>
      <SECONDARY_BUTTON id="sign-in-button" onClick={this.startAutoLogin}>
        Log in with Okta
      </SECONDARY_BUTTON>
    </Pane>
  );

  /*
  renderJuulWelcome = () => (
    <div>
      WELCOME TO JUUL's H1 APP {process.env.SUBDOMAIN}
      <button id="sign-in-button" onClick={this.startAutoLogin}>
        Sign in with Okta!
      </button>
    </div>
  );*/

  render() {
    // this show render based on subdomain rather than env
    // so main can support multiple subdomains
    /*switch (process.env.SUBDOMAIN) {
      case "juul":
        return this.renderJuulWelcome();
    } */
    return this.renderH1Welcome();
  }
}

const mapStateToProps = (state: RootState): WelcomeProps => ({
  user: currentUser(state)
});

const mapDispatchToProps = {
  setToken
};

export const WelcomePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WelcomePageClass));
