import * as React from "react";
import { H4, styled, B1, L1 } from "@h1eng/ui-components";
import { RootState } from "../../../store/reducers";
import { connect } from "react-redux";
import { ListMeta } from "../ListManagement/ListManagementInterfaces";
import { MODAL } from "../../Modal";
import { ModalMessage, ModalMessageProps } from "../../modals/genericModal";
import { selectListId } from "../../../store/actions";
import {
  getAvailableLists,
  getCardsLoadingStatus,
  getSelectedListId
} from "../../../store/selectors";
import { withRouter, RouteComponentProps } from "react-router-dom";

const Delete = styled.div`
  &:hover {
    cursor: pointer;
  }
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
`;

const SidePanel = styled.div`
  background-image: linear-gradient(to bottom, #0e0a9d, #0aaacd);
`;

const NoLists = styled.div`
  font-size: 14px;
  font-family: Montserrat;
  color: #ffffff;
  font-weight: bold;
  -webkit-text-decoration: none;
  text-decoration: none;
`;

const CountPill = styled.div<{ active: boolean }>`
  padding: 5px 15px;
  border-radius: 20px;
  background-color: ${props =>
    props.active ? "#0c6ebb" : "rgba(0, 0, 0, 0.26)"};
  display: inline-block;
`;

const ListNavItem = styled.div<{ active?: boolean }>`
  padding: 20px;
  border-top: 1px solid white;
  background-color: ${props => (props.active ? "rgba(0, 0, 0, 0.38)" : "none")};
  cursor: pointer;
`;

const CountPillAndDeleteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  deleteList: (listId: string) => void;
}

interface MappedDispatchProps {
  setSelectedList: (id: string) => void;
}

interface MappedStateProps {
  selectedListId: string | null;
  listsAvailable: ListMeta[];
  loading: boolean;
}

type ListMenuProps = Props &
  MappedDispatchProps &
  MappedStateProps &
  RouteComponentProps;

interface ListMenuState {
  modalOpen: boolean;
}

class ListMenuClass extends React.Component<ListMenuProps, ListMenuState> {
  static modalMessageProps = {
    title: "Delete List",
    boldText: "This list will be deleted.  This action can not be undone.",
    buttonText: "Are you sure you want to delete this list?"
  };

  state: ListMenuState = {
    modalOpen: false
  };

  get modalMessageProps(): ModalMessageProps {
    return {
      ...ListMenuClass.modalMessageProps,
      onClose: this.setModalOpen(false),
      action: this.handleModalCTA
    };
  }

  setModalOpen = (modalOpen: boolean) => (event?: any) => {
    this.setState({ modalOpen });
  };

  handleModalCTA = () => {
    if (this.props.selectedListId) {
      this.props.deleteList(this.props.selectedListId);
      this.setState({ modalOpen: false });
    }
  };

  getDate = (date: string) => {
    const inputDate = new Date(date);
    const todaysDate = new Date();
    if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      return "Today";
    }
    return inputDate.toLocaleDateString("en-US");
  };

  render() {
    const { listsAvailable, selectedListId } = this.props;
    return (
      <>
        <MODAL open={this.state.modalOpen} onClose={this.setModalOpen(false)}>
          <ModalMessage {...this.modalMessageProps} />
        </MODAL>

        <SidePanel>
          <ListNavItem>
            <div style={{ color: "white" }}>
              <H4 darkBackground>Saved Lists</H4>
            </div>
          </ListNavItem>

          {!listsAvailable ||
            (listsAvailable.length === 0 && (
              <ListNavItem>
                <NoLists>No Lists Saved</NoLists>
              </ListNavItem>
            ))}
          {listsAvailable.map((list: ListMeta) => {
            const active = list.id === selectedListId;
            return (
              <ListNavItem
                key={list.id}
                active={active}
                onClick={() => {
                  this.props.history.push(`/saved-lists/${list.id}`);
                }}
              >
                <L1 darkBackground to={`/saved-lists/${list.id}`}>
                  {list.title}
                </L1>
                <br />
                <br />
                <CountPillAndDeleteContainer>
                  <CountPill active={active}>
                    <B1 darkBackground>{list.length}</B1>
                  </CountPill>
                  <B1 darkBackground> {this.getDate(list.updateDate || "")}</B1>
                  <Delete>
                    <div
                      style={{ display: "flex", cursor: "ponter" }}
                      onClick={this.setModalOpen(true)}
                    >
                      <img
                        style={{ paddingRight: "5px" }}
                        src={
                          "https://s3.amazonaws.com/h1-assets/img/trashwhite.svg"
                        }
                      />
                      Delete
                    </div>
                  </Delete>
                </CountPillAndDeleteContainer>
              </ListNavItem>
            );
          })}
        </SidePanel>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  selectedListId: getSelectedListId(state),
  listsAvailable: getAvailableLists(state),
  loading: getCardsLoadingStatus(state)
});

const mapDispatchToProps: MappedDispatchProps = {
  setSelectedList: selectListId
};

export const ListMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListMenuClass));
