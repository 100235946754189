import { pageCount } from "./pageCount";

export const paginate = ({
  items,
  pageNum = 0,
  pageSize = 15
}: {
  items: any[];
  pageNum?: number;
  pageSize?: number;
}) => {
  if (
    pageNum < 0 ||
    pageSize < 1 ||
    pageNum > pageCount({ count: items.length, pageSize })
  ) {
    return [];
  }

  return items.slice(pageNum * pageSize, (pageNum + 1) * pageSize);
};
