import * as React from "react";
import { styled } from "@h1eng/ui-components";

interface OwnProps {
  legendItems: { color: string; key: string }[];
}
const TitleContainer = styled.div`
  padding-left: 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  color: #333333;
  font-weight: 500;
  padding-bottom: 10px;
  font-family: "Montserrat";
`;

export class PieGraphLegend extends React.Component<OwnProps> {
  render() {
    const legend = this.props.legendItems.map((e, i) => {
      return (
        <div style={{ minHeight: "40px" }} key={`legend-${i}-${e.key}`}>
          <div
            key={i}
            style={{
              float: "left",
              backgroundColor: e.color,
              width: "25px",
              height: "25px",
              borderRadius: "50%"
            }}
          />

          <TitleContainer>
            {e.key === "payerName" || e.key === "drugOrDeviceName"
              ? "Other"
              : e.key === null || e.key === "null"
              ? "Unknown"
              : e.key}
          </TitleContainer>
        </div>
      );
    });
    return <div>{legend} </div>;
  }
}
