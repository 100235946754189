import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  requestPaymentCompanyOptions,
  setPaymentCompanyQuery,
  requestPaymentDrugOrDeviceOptions,
  setPaymentDrugOrDeviceQuery,
  requestPaymentFundingTypeOptions,
  setPaymentFundingTypeQuery,
  resetInitialOptions,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  map,
  filter,
  catchError
} from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { searchOptions } from "../../../apis/searchFilters";
import { DEBOUNCE_TIME } from ".";

const setPaymentCompanyQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setPaymentCompanyQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestPaymentCompanyOptions.request(
              state$.value.searchFilters.payments.company
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );
const getPaymentCompanyOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestPaymentCompanyOptions.request)),
    switchMap(({ payload }) =>
      from(
        searchOptions(
          state$.value.searchFilters.payments.company,
          state$.value.searchFilters.initialOptions.payments.company
        )
      ).pipe(
        map(requestPaymentCompanyOptions.success),
        catchError(
          pipe(
            requestPaymentCompanyOptions.failure,
            of
          )
        )
      )
    )
  );

const setPaymentDrugOrDeviceQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setPaymentDrugOrDeviceQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestPaymentDrugOrDeviceOptions.request(
              state$.value.searchFilters.payments.drugOrDevice
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );
const getPaymentDrugOrDeviceOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestPaymentDrugOrDeviceOptions.request)),
    switchMap(({ payload }) =>
      from(
        searchOptions(
          payload,
          state$.value.searchFilters.initialOptions.payments.drugOrDevice
        )
      ).pipe(
        map(requestPaymentDrugOrDeviceOptions.success),
        catchError(
          pipe(
            requestPaymentDrugOrDeviceOptions.failure,
            of
          )
        )
      )
    )
  );

const setPaymentFundingTypeQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setPaymentFundingTypeQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestPaymentFundingTypeOptions.request(
              state$.value.searchFilters.payments.fundingType
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );
const getPaymentFundingTypeOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestPaymentFundingTypeOptions.request)),
    switchMap(({ payload }) =>
      from(
        searchOptions(
          payload,
          state$.value.searchFilters.initialOptions.payments.fundingType
        )
      ).pipe(
        map(requestPaymentFundingTypeOptions.success),
        catchError(
          pipe(
            requestPaymentFundingTypeOptions.failure,
            of
          )
        )
      )
    )
  );

export default [
  setPaymentCompanyQueryFlow,
  getPaymentCompanyOptionsFlow,
  setPaymentDrugOrDeviceQueryFlow,
  getPaymentDrugOrDeviceOptionsFlow,
  setPaymentFundingTypeQueryFlow,
  getPaymentFundingTypeOptionsFlow
];
