import * as React from "react";
import { Link } from "react-router-dom";
import { styled } from "@h1eng/ui-components";
import { colors } from "../../KOLResult/ResultCards/colors";

interface WorksLinkProps {
  to: string;
  label: string;
  value: number | string;
  color: Color;
}

const Root = styled(Link)`
  margin-bottom: 8px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelColor = styled.div`
  width: 3px;
  height: 12px;
  margin-right: 6px;
  border-radius: 25%;
`;

const LabelText = styled.span`
  color: #435d6b;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-right: 6px;
  cursor: inherit;
`;

const ValueText = styled(LabelText)`
  margin-right: 0;
  color: #333;
  font-weight: bold;
`;

type Color = keyof typeof colors;

export const WorksLink: React.FunctionComponent<WorksLinkProps> = ({
  to,
  label,
  value,
  color
}) => (
  <Root to={to}>
    <LabelWrapper>
      <LabelColor style={{ backgroundColor: colors[color] || "transparent" }} />
      <LabelText>{label}</LabelText>
    </LabelWrapper>
    <ValueText>{value}</ValueText>
  </Root>
);
