import { TitlesAndAssociationInterface } from "./profileInterfaces";
import { ScoredDocumentData } from "./documentScores";

export type KOLType =
  | "Institutional Pedigree"
  | "Industry Partner"
  | "Rising Star"
  | "Research Scholar";

export interface WorksCount {
  searched: number;
  total: number;
}

export interface Works {
  publications: WorksCount;
  trials: WorksCount;
  congresses: WorksCount;
  /**
   * The count of grants; not the monetary amount
   */
  grants: WorksCount;
}
export interface ResultCardData {
  personId: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  title: string;
  organization: string;
  location: string;
  score: number;
  KOLTypes: KOLType[];
  trendHistory: number[];
  trendPresent: string;
  countPublications: number;
  countClinicalTrials: number;
  scores: { value: number; id: string; year: string };
  sumPayments: number;
  congresses: number;
  grants: number;
  sumGrants: number;
  specialty: string[];
  grantDates?: number[];
  congressesDates: number[];
  paymentDates: number[];
  publicationDates: number[];
  trailDates: number[];
  citationCount: number;
  citationCountAvg: number;
}

export type ScoredResultCardData = ResultCardData & {
  scoredDocuments: ScoredDocumentData;
};

export interface LiteProfiles {
  payload: LiteProfile[];
}
export interface LiteProfile {
  firstName: string;
  lastName: string;
  phoneNumbers: string[];
  city: string;
  state: string;
  titles: string[];
  email: string[];
  designations: string[];
  titleAndAssociations: TitlesAndAssociationInterface[];
}

export enum CardViewOpt {
  SEARCH,
  SAVED_LISTS
}
