import * as React from "react";
import { connect } from "react-redux";
import { FilterSection } from "../FilterSection";
import { RootState } from "../../../../../store/reducers";
import { getPaymentsFiltersCount } from "../../../../../store/selectors";
import { TextFilterWrapper } from "../../components/TextFilterWrapper";
import { PaymentsMinAmount } from "./PaymentsMinAmount";
import { CompanyFilters } from "./CompanyFilters";
import { DrugOrDeviceFilters } from "./DrugOrDeviceFilters";
import { FundingTypeFilters } from "./FundingTypeFilters";

interface MappedStateProps {
  filterCount: number;
}

type Props = MappedStateProps;

function PaymentsComponent(props: Props) {
  return (
    <FilterSection
      sectionKey="payments"
      title="Payments"
      count={props.filterCount}
    >
      <TextFilterWrapper>
        <PaymentsMinAmount label="Minimum amount" />
      </TextFilterWrapper>
      <TextFilterWrapper>
        <CompanyFilters />
      </TextFilterWrapper>
      <TextFilterWrapper>
        <DrugOrDeviceFilters />
      </TextFilterWrapper>
      <TextFilterWrapper>
        <FundingTypeFilters />
      </TextFilterWrapper>
    </FilterSection>
  );
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  filterCount: getPaymentsFiltersCount(state)
});

export const Payments = connect(mapStateToProps)(PaymentsComponent);
