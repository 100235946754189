import * as React from "react";
import MuiCheckbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = createStyles({
  root: {
    padding: "15px"
  }
});

class CheckboxClass extends React.Component<CheckboxProps> {
  static defaultProps: Partial<CheckboxProps> = {
    color: "default"
  };

  render() {
    const { classes, ...props } = this.props;

    return (
      <MuiCheckbox
        classes={{
          root: classes!.root
        }}
        {...props}
      />
    );
  }
}

export const Checkbox = withStyles(styles)(CheckboxClass);
