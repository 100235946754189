import * as React from "react";
import { styled, keyframes } from "@h1eng/ui-components";
import { ProfileCard } from "./ProfileShared";

const loadingPulse = keyframes`
from {
 background-color: #f2f2f2;
}

to {
  background-color: #e9E7E7;
}
`;

const LoadingCardWrapper = styled.div`
  background-color: white;
  border-radius: 2px;
  padding: 20px;
`;

const CardLoadingIndicator = styled.div`
  height: 15px;
  width: 100%;
  animation: ${loadingPulse} 0.6s ease infinite;
  animation-direction: alternate;
`;

const IndicatorWrapper = styled.div<{ width?: string; marginBottom: string }>`
  width: ${props => props.width || "100%"};
  margin-bottom: ${props => props.marginBottom};
`;

const LoadingCard = (
  <ProfileCard boxShadowColor={"#80d0e1"}>
    <IndicatorWrapper marginBottom="10px" width="100px">
      <CardLoadingIndicator />
    </IndicatorWrapper>

    <IndicatorWrapper marginBottom="5px">
      <CardLoadingIndicator />
    </IndicatorWrapper>

    <IndicatorWrapper marginBottom="20px" width="55%">
      <CardLoadingIndicator />
    </IndicatorWrapper>

    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 100px)",
        gridColumnGap: "20px"
      }}
    >
      <CardLoadingIndicator />
      <CardLoadingIndicator />
      <CardLoadingIndicator />
    </div>
  </ProfileCard>
);

export const LoadingState = () => {
  const loadingCards = [1, 2, 3, 4, 5].map(i => {
    return <div key={i}>{LoadingCard}</div>;
  });
  return <div>{loadingCards}</div>;
};
