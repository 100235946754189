import * as React from "react";
import { B2, B1 } from "@h1eng/ui-components";
import { images } from "./images";

interface Props {
  listName: string;
}

export const ListCreatedConfirmation: React.SFC<Props> = ({ listName }) => (
  <>
    <B2>Saved to "{listName}"</B2>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "20px"
      }}
    >
      <img style={{ marginRight: "5px" }} src={images.saveSuccess} />
      <B1>Saved!</B1>
    </div>
  </>
);
