import { CustomSortBy, CustomSortByConstructor } from "@h1eng/interfaces";

export const validate = (
  sortOption: CustomSortBy | CustomSortByConstructor,
  options: CustomSortBy[]
): string | undefined => {
  const hasDuplicateNames = options
    .filter(i => {
      // @ts-ignore
      const id: string | undefined = sortOption.id;
      if (id === undefined) return true;

      return id !== i.id;
    })
    .find(i => i.name.trim() === sortOption.name.trim());

  if (hasDuplicateNames) {
    return `A configuration already exists with the name "${sortOption.name}"`;
  }

  return;
};
