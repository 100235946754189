import * as React from "react";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { getDisplayLoadingMessage } from "../../../store/selectors";
import { clearLoadingMessage } from "../../../store/actions";
import { RootState } from "../../../store/reducers";

interface MappedStateProps {
  display: boolean;
}

interface DispatchProps {
  close: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    snackbarContentRoot: {
      backgroundColor: theme.palette.primary.main,
      flexWrap: "inherit"
    },
    snackbarContentMessage: {
      color: theme.palette.primary.contrastText,
      fontWeight: 500
    },
    message: {
      display: "flex",
      alignItems: "center"
    },
    icon: {
      fontSize: 20
    },
    snackbarContentAction: {
      alignSelf: "flex-start"
    }
  });

type Props = MappedStateProps & DispatchProps & WithStyles<typeof styles>;

class SearchLoadingMessageProviderComponent extends React.Component<Props> {
  handleClose = () => {
    this.props.close();
  };

  render() {
    return (
      <>
        {this.props.children}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.props.display}
          ContentProps={{
            "aria-describedby": "search-loading-message",
            classes: {
              root: this.props.classes.snackbarContentRoot,
              message: this.props.classes.snackbarContentMessage,
              action: this.props.classes.snackbarContentAction
            }
          }}
          message={
            <span
              id="search-loading-message"
              className={this.props.classes.message}
            >
              This search is taking quite a while...please wait and your results
              should be here shortly.
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon className={this.props.classes.icon} />
            </IconButton>
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  display: getDisplayLoadingMessage(state)
});

const mapDispatchToProps: DispatchProps = {
  close: clearLoadingMessage
};

export const SearchLoadingMessageProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SearchLoadingMessageProviderComponent));
