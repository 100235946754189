import * as React from "react";
import { styled } from "@h1eng/ui-components";

const DROP_DOWN_SELECTOR_CONTAINER = styled.select`
  width: 100px;
  border: none;
  outline: none;
  background-color: white;
  padding: 10px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: bold;
  color: black;
  font-family: Montserrat;
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  &:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const SoryBy = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #333333;
`;
export enum SortMode {
  NEWEST,
  OLDEST
}

interface DropDownSelectorProps {
  handleChange: (event: SortMode) => void;
}

export class DropDownSelector extends React.Component<DropDownSelectorProps> {
  render() {
    return (
      <>
        <SoryBy>Sort by:</SoryBy>
        <DROP_DOWN_SELECTOR_CONTAINER
          onChange={(e: any) =>
            this.props.handleChange(parseInt(e.target.value, 10))
          }
        >
          <option value={SortMode.NEWEST}>Newest</option>
          <option value={SortMode.OLDEST}>Oldest</option>
        </DROP_DOWN_SELECTOR_CONTAINER>
      </>
    );
  }
}
