import * as React from "react";
import { H4, B2, L1 } from "@h1eng/ui-components";
import { NullState as NullStateWrapper } from "../KOLResult/NullState";

export const NullState: React.SFC = () => (
  <NullStateWrapper>
    <H4>No Saved Opinion Leaders</H4>
    <br />
    <B2>You have not saved any opinion leaders to your list.</B2>
    <br />
    <L1 to="/curie/search">View Opinion Leaders</L1>
  </NullStateWrapper>
);
