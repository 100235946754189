import * as React from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { colors, Footer } from "../../../ui-components/dist";

const style: any = {
  padding: "100px",
  minHeight: "500px",
  backgroundColor: colors.neutralBackground,
  textAlign: "center"
};

export const notFound = (props: any) => {
  return (
    <Paper style={style}>
      <Typography gutterBottom variant="headline">
        We couldn't find what you're looking for...
      </Typography>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Button variant="raised" color="primary">
          Take me home
        </Button>
      </Link>
    </Paper>
  );
};

class NotFoundPage extends React.Component {
  render() {
    return (
      <>
        {notFound(this.props)}
        <Footer />
      </>
    );
  }
}

export default NotFoundPage;
