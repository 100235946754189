import * as React from "react";
import { connect } from "react-redux";
import { ProfileCard } from "../ProfileShared";
import { RootState } from "../../../../store/reducers";
import { styled, B1, B2, B4, H3, H5 } from "@h1eng/ui-components";
import {
  getSelectedProject,
  currentUser,
  getSelectedListId,
  getAvailableLists,
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDateDisplayText,
  getDocumentSearchBarFilterDate
} from "../../../../store/selectors";
import { UserVO } from "../../../../models/UserVO";
import { ListMeta } from "../../ListManagement/ListManagementInterfaces";
import {
  ListManipulationInterface,
  addToList,
  setPage,
  searchCollaborators
} from "../../../../store/actions";
import { LiteProfile } from "@h1eng/interfaces";
import { ENDPOINTS } from "../../../../apis/api-endpoints";
import { findCollaborators } from "../../../../apis/search";
import { getLiteProfile } from "../../../../apis/requestKol";
import { MultiRender } from "../MultiDocRender";
import { Card } from "../ProfileElements";
import { CollaboratorCard, CollaboratorsCardProps } from "./CollaboratorsCard";
import { ProfileDocumentSearch } from "../ProfileDocumentSearch";
import { ProfileSortByGeneric } from "../ProfileSoryByGeneric";
import {
  SearchBarQureyHelper,
  SearchBarQureyDisplay
} from "../SearchHelpFunctions";
import { NoResultsFound } from "../NoResultsFound";
import { DateRangeDropdown } from "../../common/DateRangeDropdown";
import {
  getPersonCollaboratorsCountMap,
  getPersonCollaborationsDocIds,
  getCollaborators,
  getPage,
  getSize,
  getLiteProfiles,
  getLoading,
  getSortedIds
} from "../../../../store/selectors/collaborators";

const RangeContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  height: 40px;
  background-color: #f6f6f6;
  margin-bottom: -1px;
`;
const RangeCenter = styled.div`
  text-align: center;
  color: #333333;
  font-family: Montserrat;
  font-weight: 600;
`;
const RangeLeft = styled.div`
  text-align: left;
  color: #0aaacd;
  font-family: Montserrat;
  font-weight: 600;
`;
const RangeRight = styled.div`
  text-align: right;
  color: #0aaacd;
  font-family: Montserrat;
  font-weight: 600;
`;
const ArrowContainer = styled.div`
  display: flex;
`;

interface CollaboratorsProps {
  personId: string;
}
interface RootStateProps {
  user: UserVO;
  projectId: string;
  selectedListId: string | null;
  listsAvailable: ListMeta[];
  sortedIds: string[];
  searchBarState: { query: string[]; personId: string; types: any[] };
  filterDate: number;
  filterDateText: string;
  loading: boolean;
  collaborations: string[];
  collaborators: {
    personId: string;
    docs: { [number]: string[] };
  }[];
  personCollaboratorsCountMap: { [number]: number };
  liteProfiles: LiteProfile[];
  page: number;
  listSize: number;
  setPage: (page: number) => void;
  searchCollaborators: (obj: GetCollaboratorsInterface) => void;
}

type Props = RootStateProps & DispatchFromProps & CollaboratorsProps;

export class CollaboratorsClass extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.scrollRef = React.createRef();
    const terms =
      this.props.searchBarState.query.length > 0
        ? this.props.searchBarState.query
        : undefined;
  }

  componentWillReceiveProps(nextProps: Props) {}

  query(personId: string, terms: string[]) {}

  getStart(): number {
    const { page, listSize } = this.props;
    return page * listSize + 1;
  }

  getEnd(): number {
    const { page, listSize } = this.props;
    const ordered = this.props.sortedIds.filter(
      e => e.personId !== this.props.personId
    );
    const size = page * listSize + listSize;
    if (ordered.length < size) {
      return ordered.length;
    }
    return size;
  }

  renderLeftSideSelector() {
    const { listSize } = this.props;
    if (this.getStart() - listSize <= 0) {
      return null;
    }

    return (
      <ArrowContainer>
        <div
          style={{ marginRight: "30px", cursor: "pointer" }}
          onClick={() => {
            try {
              window.scrollTo(0, this.scrollRef.current.offsetTop);
            } catch (e) {}
            this.props.setPage(0);
          }}
        >
          {`<<`}
        </div>
        <div
          style={{ width: "100%", cursor: "pointer" }}
          onClick={() => {
            try {
              window.scrollTo(0, this.scrollRef.current.offsetTop);
            } catch (e) {}
            this.props.setPage(this.props.page - 1);
          }}
        >
          {`< ${this.getStart() - this.props.listSize} - ${this.getStart() -
            1} `}
        </div>
      </ArrowContainer>
    );
  }

  renderRightSideSelector() {
    const ordered = this.props.sortedIds.filter(
      e => e.personId !== this.props.personId
    );
    if (this.getEnd() >= ordered.length) {
      return null;
    }

    return (
      <ArrowContainer>
        <div
          style={{ width: "100%", cursor: "pointer" }}
          onClick={() => {
            try {
              window.scrollTo(0, this.scrollRef.current.offsetTop);
            } catch (e) {}
            this.props.setPage(this.props.page + 1);
          }}
        >
          {`${this.getEnd() + 1} - ${this.getEnd() + this.props.listSize} >`}
        </div>
        <div
          style={{ marginLeft: "30px", cursor: "pointer" }}
          onClick={() => {
            const length = ordered.length;
            const page = Math.floor(length / this.props.listSize);

            this.props.setPage(page);

            try {
              window.scrollTo(0, this.scrollRef.current.offsetTop);
            } catch (e) {}
          }}
        >
          {`>>`}
        </div>
      </ArrowContainer>
    );
  }

  render() {
    const ordered =
      this.props.loading === true
        ? []
        : this.props.collaborators.filter(
            e => e.personId !== this.props.personId
          );

    return (
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "20px",
            paddingLeft: "20px",
            paddingTop: "15px"
          }}
          ref={this.scrollRef}
        >
          <div style={{ paddingBottom: "15px" }}>
            <ProfileDocumentSearch
              searchHint={"Search for this researcher’s collaborators"}
              personId={this.props.personId}
            />
          </div>
          <div style={{ paddingBottom: "15px", width: "100%" }}>
            <DateRangeDropdown />
          </div>
        </div>
        {this.props.searchBarState.query.length > 0 && (
          <div style={{ paddingBottom: "15px" }}>
            <SearchBarQureyHelper />
            <SearchBarQureyDisplay />
          </div>
        )}

        {this.props.loading && (
          <div
            style={{ marginTop: "40px", textAlign: "center", height: "1000px" }}
          >
            <H3>{`Loading...`}</H3>
          </div>
        )}

        {!this.props.loading &&
          ordered.length > 0 &&
          ordered.map((p, i) => {
            const e = this.props.liteProfiles.find(a => a.id === p.personId);
            const set = {
              firstName: e.firstName,
              personId: e.id,
              email: e.email,
              lastName: e.lastName,
              phone: e.phoneNumbers[0] || null,
              collaboratorsDocs: this.props.collaborations,
              docs: p.docs,
              designations: e.designations,
              payments: e.payments,
              titleAndAssociations: e.titleAndAssociations
            } as CollaboratorsCardProps;
            return <CollaboratorCard key={i} {...set} />;
          })}
        {ordered.length > 0 && (
          <div style={{ width: "100%" }}>
            <RangeContainer>
              <RangeLeft>{this.renderLeftSideSelector()}</RangeLeft>
              <RangeCenter>
                {this.getStart()} - {this.getEnd()}
              </RangeCenter>
              <RangeRight>{this.renderRightSideSelector()}</RangeRight>
            </RangeContainer>
          </div>
        )}
        {ordered.length === 0 && this.props.loading === false && (
          <NoResultsFound />
        )}
      </Card>
    );
  }
}

interface DispatchFromProps {
  addToList: (opts: ListManipulationInterface) => void;
}

export const mapStateToProps = (state: RootState): RootStateProps => ({
  projectId: getSelectedProject(state)!,
  user: currentUser(state)!,
  selectedListId: getSelectedListId(state),
  listsAvailable: getAvailableLists(state),
  searchBarState: getDocumentSearchBarState(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state),
  personCollaboratorsCountMap: getPersonCollaboratorsCountMap(state),
  collaborations: getPersonCollaborationsDocIds(state),
  collaborators: getCollaborators(state),
  liteProfiles: getLiteProfiles(state),
  page: getPage(state),
  listSize: getSize(state),
  loading: getLoading(state),
  sortedIds: getSortedIds(state)
});

export const mapDispatchToProps = {
  addToList: addToList.request,
  searchCollaborators: searchCollaborators.request,
  setPage
};

export const Collaborators = connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaboratorsClass);
