import * as React from "react";
import styled from "styled-components";
import { Link, LinkProps } from "react-router-dom";
import { NavBarVariant } from "./NavBarVariant";

const BaseH1Logo = styled(Link)<{ color: string }>`
  font-family: Montserrat;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;

  color: ${(props: any) => props.color};
  cursor: pointer;
`;

const Root = styled.div`
  position: relative;
`;

const BackPortalMount = styled.div`
  position: absolute;
  top: 55px;
  left: 7px;
`;

interface H1LogoProps {
  variant: NavBarVariant;
}
export const H1Logo: React.SFC<H1LogoProps & LinkProps> = (props: any) => {
  const colors = {
    [NavBarVariant.DEFAULT]: "#060464",
    [NavBarVariant.GRAY]: "#060464",
    [NavBarVariant.DARK]: "white"
  };

  return (
    <Root>
      <BaseH1Logo {...props} color={colors[props.variant]}>
        H1.
      </BaseH1Logo>
      <BackPortalMount id="back-to-profile-portal-mount" />
    </Root>
  );
};
