import { styled } from "@h1eng/ui-components";

export const TextFilterWrapper = styled.div`
  display: flex;
  margin-left: 18px;
  margin-right: 18px;
  width: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  margin-bottom: 10px;
`;
