import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

const Label = styled.div`
  color: #333333;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: 4px;
  width: 80%;
`;

const Value = styled.div`
  color: #333333;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 4px;
  width: 100%;
`;

export interface GridSectionItem {
  label: string | React.ReactNode;
  value?: string | number | null | React.ReactNode;
}

interface GridSectionProps {
  items: GridSectionItem[];
}

export const GridSection: React.SFC<GridSectionProps> = props => {
  const { items } = props;

  return (
    <Root>
      {items.map((item, itemIndex) => (
        <Row key={`item-${item.label}-${item.value}-${itemIndex}`}>
          <Label>{item.label}</Label>
          <Value>
            {Array.isArray(item.value)
              ? item.value.map((val, valIndex) => (
                  <React.Fragment key={`item-${itemIndex}-${valIndex}`}>
                    {val}
                    <br />
                  </React.Fragment>
                ))
              : item.value}
          </Value>
        </Row>
      ))}
    </Root>
  );
};
