/* tslint:disable:react-unused-props-and-state */
import * as React from "react";
import { connect } from "react-redux";
import { styled } from "../../../../ui-components/dist";
import { createGrowler, removeGrowler } from "../../store/actions/growler";
import { GrowlerTypes } from "./GrowlerTypes";
import { RootState } from "../../store/reducers";
import { GrowlerMessage } from "./GrowlerMessage";
import { TitleIcon } from "../../store/actions";

const GROWLER_BACKGROUND = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 10000000;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 10px;
  margin-top: 10px;
  width: 400px;
  margin-left: 20px;
`;

const GROWLER_CONTENT = styled.div`
  max-width: 800px;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
`;

interface GrowlerProps {
  open: boolean;
  onClose: () => any;
  onClick: () => any;
}

export interface GrowlerlState {
  open: boolean;
}

export class GrowlerClass extends React.Component<
  GrowlerProps & DispatchProps & RootState,
  any
> {
  render() {
    const growlerKeys: any[] = Object.keys(
      this.props.growlers.growlers
    ) as any[];
    if (!growlerKeys || growlerKeys.length === 0) {
      return null;
    }

    return (
      <div style={{ position: "fixed", right: "0", zIndex: 10 }}>
        <GROWLER_BACKGROUND>
          <GROWLER_CONTENT>
            {growlerKeys.map((e: any, i) => {
              return (
                <GrowlerMessage
                  key={`growler-msg-${i}`}
                  onClose={() => {
                    this.props.removeGrowler(e);
                  }}
                  growlerData={this.props.growlers.growlers[e]}
                />
              );
            })}
          </GROWLER_CONTENT>
        </GROWLER_BACKGROUND>
      </div>
    );
  }
}

export const mapStateToProps = (state: any) => state;

export const mapDispatchToProps = (dispatch: any) => {
  return {
    createNewGrowler: (
      title: string,
      description: string,
      growler: GrowlerTypes,
      titleIcon: TitleIcon
    ) => {
      dispatch(createGrowler({ title, description, growler, titleIcon }));
    },
    removeGrowler: (id: number) => dispatch(removeGrowler(id))

    // getListOption: () => dispatch(getListOptions.request())
  };
};
interface DispatchProps {
  createNewGrowler: (
    title: string,
    description: string,
    growler: GrowlerTypes,
    titleIcon: TitleIcon
  ) => void;
  removeGrowler: (id: number) => void;
}

export const Growlers = connect<RootState, DispatchProps, GrowlerProps>(
  mapStateToProps,
  mapDispatchToProps
)(GrowlerClass);
