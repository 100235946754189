import { createStandardAction, ActionType } from "typesafe-actions";

export interface DocumentSearchBarQueryInterface {
  personId: string;
  types: any[];
  query: string[];
}
export interface SetFilterDateDocumentSearchBar {
  filterDate: number;
  displayFilterDateString: string;
}
export const setDocumentSearchBarQuery = createStandardAction(
  "documents.searchbar.set.query"
)<DocumentSearchBarQueryInterface>();

export const setDocumentSearchBarFilterDate = createStandardAction(
  "documents.searchbar.filter.set.query"
)<SetFilterDateDocumentSearchBar>();

export type DocumentSearchBarAction =
  | ActionType<typeof setDocumentSearchBarFilterDate>
  | ActionType<typeof setDocumentSearchBarQuery>;
