/* tslint:disable:cyclomatic-complexity */
import { ListMeta } from "../../components/curie/ListManagement/ListManagementInterfaces";
import { getType } from "typesafe-actions";
import {
  ListAction,
  selectListId,
  clearSelectedId,
  getListForUser,
  getListOptions,
  setSavedListPageNum
} from "../actions/lists";

export interface ListState {
  lists: { [key: string]: { personLists: ListMeta[] } };
  listAvailable: ListMeta[];
  selectedId: string | null;
  listAvailableLoaded: boolean;
  savedListPageNum: number;
  savedListPageSize: number;
}

const initialState: ListState = {
  lists: {},
  listAvailable: [],
  selectedId: null,
  listAvailableLoaded: false,
  savedListPageNum: 0,
  savedListPageSize: 15
};

export const listCardReducer = (
  state: ListState = initialState,
  action: ListAction
): ListState => {
  switch (action.type) {
    case getType(setSavedListPageNum): {
      return { ...state, savedListPageNum: action.payload };
    }
    case getType(selectListId): {
      return { ...state, selectedId: action.payload };
    }
    case getType(clearSelectedId): {
      return { ...state, selectedId: null };
    }
    case getType(getListForUser.success): {
      return {
        listAvailableLoaded: true,
        ...state,
        lists: {
          ...state.lists,
          ...action.payload
        }
      };
    }
    case getType(getListOptions.success): {
      const newState = Object.assign({}, state, {
        listAvailable: action.payload
      });
      return newState;
    }

    default:
      return state;
  }
};
