import { styled } from "@h1eng/ui-components";
import { NavBarVariant } from "./NavBarVariant";
import { Dictionary } from "lodash";
import * as React from "react";

const BasedDropdownContent = styled.div<{ backgroundColor?: string }>`
  position: absolute;
  width: 122.59px;
  z-index: 10000;
  top: 64px;
  padding: 20px;
  background-color: ${props => props.backgroundColor};
`;

interface DropdownContentProps {
  variant: NavBarVariant;
}

export const DropDownContent: React.SFC<DropdownContentProps> = props => {
  const colors: Dictionary<{ background: string }> = {
    [NavBarVariant.DEFAULT]: {
      background: "#060464"
    },
    [NavBarVariant.GRAY]: {
      background: "#ededed"
    },
    [NavBarVariant.DARK]: {
      background: "rgba(60,60,120,1)"
    }
  };

  return (
    <BasedDropdownContent
      {...props}
      backgroundColor={colors[props.variant].background}
    >
      {props.children}
    </BasedDropdownContent>
  );
};
