import * as React from "react";
import { styled } from "./themes/styled-components";

interface ButtonFactoryColorInterface {
  textColor: string;
  backgroundColor: string;
  backgroundColorHover: string;
  backgroundColorActive: string;
  borderColor?: string;
}

interface ButtonFactoryPropsInterface {
  id?: string;
  fontSize: string;
  width: string;
  paddingX: string;
  onClick?: (e: any) => any;
}

interface ButtonFactoryInterface
  extends ButtonFactoryColorInterface,
    ButtonFactoryPropsInterface {}

const BUTTON = styled.button<ButtonFactoryInterface>`
  margin: 0;
  padding-left: ${props => props.paddingX};
  padding-right: ${props => props.paddingX};
  height: 42px;
  outline: none;
  font-family: Montserrat;
  font-size: ${props => props.fontSize};
  font-weight: 600;
  text-align: center;
  border-radius: 0px 4px 4px 0px;
  letter-spacing: 1px;
  width: ${props => props.width};
  color: ${props => props.textColor};
  border: ${props =>
    props.borderColor ? `1px solid ${props.borderColor}` : "none"};
  background-color: ${props => props.backgroundColor};
  &:hover {
    background-color: ${props => props.backgroundColorHover};
    cursor: pointer;
  }
  &:active {
    background-color: ${props => props.backgroundColorActive};
  }
`;

export const ButtonFactory: React.SFC<ButtonFactoryInterface> = props => {
  return <BUTTON {...props}>{props.children}</BUTTON>;
};

const generateButtonFactory = (colors: ButtonFactoryColorInterface) => (
  props: any
): JSX.Element => {
  const btnProps: ButtonFactoryInterface = {
    onClick: props.onClick,
    fontSize: props.small ? "12px" : "14px",
    width: props.block ? "100%" : "auto",
    paddingX: props.small ? "10px" : "22px",
    ...colors
  };
  return <ButtonFactory {...btnProps}>{props.children}</ButtonFactory>;
};

export interface ButtonInterface {
  id?: string;
  small?: boolean;
  block?: boolean;
  onClick: (e: any) => any;
}

export const PRIMARY_BUTTON: React.SFC<ButtonInterface> = generateButtonFactory(
  {
    backgroundColorActive: "#01002b",
    backgroundColorHover: "#9190cf",
    backgroundColor: "#060464",
    textColor: "#ffffff"
  }
);

export const SECONDARY_BUTTON: React.SFC<
  ButtonInterface
> = generateButtonFactory({
  backgroundColorActive: "#4033bf",
  backgroundColorHover: "#bbb4ff",
  backgroundColor: "#7061ff",
  textColor: "#ffffff"
});

export const OUTLINE_BUTTON: React.SFC<ButtonInterface> = generateButtonFactory(
  {
    backgroundColorActive: "#97cad5",
    backgroundColorHover: "#c6e5ec",
    backgroundColor: "none",
    textColor: "#0aaacd",
    borderColor: "#0aaacd"
  }
);
