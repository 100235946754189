/* tslint:disable:no-shadowed-variable */
const hoverProps = (props: any) => props.parentOwnsHover && props.isHover;

const hoverColor = ({ variant }: any, colors: any) => colors[variant].hover;

const backgroundHoverColor = ({ variant }: any, colors: any) =>
  colors[variant].backgroundHover;

const activeColor = ({ variant }: any, colors: any) => colors[variant].active;
const idleColor = ({ variant }: any, colors: any) => colors[variant].idle;
const backgroundActiveColor = ({ variant }: any, colors: any) =>
  colors[variant].backgroundActive;
const backgroundIdleColor = ({ variant }: any, colors: any) =>
  colors[variant].backgroundIdle;

const makeColorGetter = (hoverColor: any, activeColor: any, idleColor: any) => (
  props: any,
  colors: any
) =>
  hoverProps(props)
    ? hoverColor(props, colors)
    : props.active
    ? activeColor(props, colors)
    : idleColor(props, colors);

export const getColor = makeColorGetter(hoverColor, activeColor, idleColor);

export const getBackgroundColor = makeColorGetter(
  backgroundHoverColor,
  backgroundActiveColor,
  backgroundIdleColor
);
