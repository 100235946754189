import * as React from "react";
import { createPortal } from "react-dom";

export const mountPointSelector = "back-to-profile-portal-mount";

interface Props {
  children: React.ReactElement<any>;
}

export class BackLink extends React.Component<Props> {
  private mountpoint: HTMLDivElement | null;

  private el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentWillMount() {
    this.mountpoint = document.querySelector(`#${mountPointSelector}`);
  }

  componentDidMount() {
    if (this.mountpoint) {
      this.mountpoint.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (this.mountpoint) {
      this.mountpoint.removeChild(this.el);
    }
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}
