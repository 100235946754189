import { IconType, PositionType, LegendProps } from "recharts";
import { FULL_BAR_SIZE } from ".";
import { XAxisTick } from "./components/XAxisTick";
import { TooltipContent } from "./components/TooltipContent";

export const cartesianGrid = {
  stroke: "#ececec"
};

export const bar = {
  maxBarSize: FULL_BAR_SIZE,
  background: false
};

export const xAxis = {
  tickLine: false,
  label: {
    value: "Year",
    fill: "#333",
    position: "bottom" as PositionType,
    style: {
      fontFamily: "Montserrat",
      fontSize: 12
    },
    offset: 20
  },
  tickMargin: -5,
  tick: XAxisTick
};

export const yAxis = {
  axisLine: false,
  tickLine: false,
  tick: {
    fill: "#333",
    style: {
      fontFamily: "Montserrat",
      fontSize: 12
    }
  },
  allowDecimals: false,
  label: {
    angle: -90,
    fill: "#333",
    position: "insideBottomLeft" as PositionType,
    style: {
      fontFamily: "Montserrat",
      fontSize: 12
    },
    offset: 10
  }
};

export const barChart = {
  barGap: 0,
  margin: {
    right: 10,
    bottom: 50,
    left: 30,
    top: 5
  }
};

export const legend = {
  iconType: "circle" as IconType,
  wrapperStyle: {
    fontFamily: "Montserrat",
    fontSize: 14,
    bottom: 5,
    left: 90
  },
  align: "left" as any
};

export const tooltip = {
  separator: " ",
  cursor: {
    fill: "rgba(215, 214, 231, 0.5)"
  },
  content: TooltipContent
};

export const line = {
  dot: false,
  strokeWidth: 2
};

export const lineChart = {
  margin: {
    top: 5,
    right: 10,
    bottom: 50,
    left: 30
  }
};
