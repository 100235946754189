import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Root = styled.span`
  width: 100%;
  height: auto;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  font-family: "Montserrat";
  margin-left: 40px;
`;

export const GraphTitle: React.SFC = props => <Root>{props.children}</Root>;
