import * as React from "react";
import { B1, B2, styled, H5 } from "@h1eng/ui-components";
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
} from "../../../store/actions";
import { margins } from "../../Typeography";
import {
  GrantInterface,
  GrantsInterface,
  GenericSearchResultInterface,
  CombinedDocumentsType,
  GenericSearchEnum
} from "@h1eng/interfaces";
import { Card } from "./ProfileElements";
import { connect } from "react-redux";
import { ProfileCard, HorizontalProfileRow } from "./ProfileShared";
import { formatStats } from "@h1eng/format-util";
import { TrialLink } from "./AssetLink";
import {
  SetDocumentSearchBarQueryInterface,
  ProfileDocumentSearch
} from "./ProfileDocumentSearch";
import {
  getDocumentSearch,
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText
} from "../../../store/selectors";
import { MultiRender } from "./MultiDocRender";
import { Bold14 } from "./ProfileOverStats";
import {
  SearchBarQureyHelper,
  SearchBarQureyDisplay,
  filterDocsByDate,
  filterFoundDocs
} from "./SearchHelpFunctions";
import { DateRangeDropdown } from "../common/DateRangeDropdown";

const BottomDetails = styled.div`
  padding-top: ${margins.publicationsCard};
  > * {
    padding-right: 10px;
  }
  display: flex;
`;

const BottomDetailNoPad = styled.div`
  > * {
    padding-right: 10px;
  }
  display: flex;
`;

const ClinicalTrialShadeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
`;

const Column = styled.div`
  display: flex;
`;

const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ecf0f1;
`;

const Spacer = styled.div`
  margin-bottom: 5px;
`;

const getHeader = (filterDocs: GrantInterface[]) => {
  const stats = getCongressStats(filterDocs);
  return (
    <Header>
      <div style={{ marginBottom: "10px" }}>
        <H5 deEmphasized>SUMMARY</H5>
      </div>
      <ClinicalTrialShadeGrid>
        <Column>
          <B1>Grants Total&nbsp;</B1> <B2> {stats.grantsFilterd}</B2>
        </Column>
        <Column>
          <B1>Total Funding&nbsp;</B1>
          <B2>{`$${formatStats(stats.fundingFiltered)}`}</B2>
        </Column>
        <Column>
          <B1>Grants per Year&nbsp;</B1>
          <B2>{stats.average}</B2>
        </Column>
      </ClinicalTrialShadeGrid>
    </Header>
  );
};

const getCongressStats = (filterDocs: GrantInterface[]) => {
  const stats = filterDocs.reduce(
    (pv, cv) => {
      pv.fundingFiltered += cv.directFunds + cv.indirectFunds;
      pv.grantsFilterd += 1;
      return pv;
    },
    {
      grantsFilterd: 0,
      fundingFiltered: 0
    }
  );

  const averageStats = filterDocs.reduce(
    (pv, cv: GrantInterface) => {
      if (!(cv.fundingYear in pv.counter)) {
        pv.years += 1;
        pv.counter[cv.fundingYear] = 1;
        pv.total += 1;
      } else {
        pv.counter[cv.fundingYear] += 1;
        pv.total += 1;
      }

      return pv;
    },
    { years: 0, counter: {}, total: 0 } as {
      years: number;
      counter: { [key: string]: number };
      total: number;
    }
  );
  const gransAve = Math.floor(averageStats.total / averageStats.years) || 0;
  return {
    average: gransAve,
    ...stats
  };
};

const getHeaderSearched = (
  searchedDocs: GrantInterface[],
  filterDocs: GrantInterface[]
) => {
  const searchedStats = getCongressStats(searchedDocs);
  const filterdStats = getCongressStats(filterDocs);

  const grantsPerYear = (
    <Column>
      <B1>Grants per Year&nbsp;</B1>
      <Bold14>{Math.floor(searchedStats.average)}&nbsp;</Bold14>
      <B2>/{filterdStats.average}</B2>
    </Column>
  );

  return (
    <Header>
      <div style={{ marginBottom: "10px" }}>
        <H5 deEmphasized>SUMMARY</H5>
      </div>
      <ClinicalTrialShadeGrid>
        <Column>
          <B1>Grants Total&nbsp;</B1>
          <Bold14>{searchedStats.grantsFilterd}&nbsp;</Bold14>
          <B2>/{filterdStats.grantsFilterd}</B2>
        </Column>
        <Column>
          <B1>Total Funding&nbsp;</B1>
          <Bold14>{formatStats(searchedStats.fundingFiltered)}&nbsp;</Bold14>
          <B2>/{`$${formatStats(filterdStats.fundingFiltered)}`}</B2>
        </Column>
        {grantsPerYear}
      </ClinicalTrialShadeGrid>
    </Header>
  );
};
interface GrantsClassDispatcher {
  setDocumentSearchBarQuery: (t: SetDocumentSearchBarQueryInterface) => void;
  setDocumentSearchBarFilterDate: (
    t: { filterDate: number; displayFilterDateString: string }
  ) => void;
  documentSearch: GenericSearchResultInterface[];
  searchBarState: { query: string[] };
  filterDate: number;
  filterDateText: string;
}

export class GrantsSectionClass extends React.Component<
  GrantsInterface & GrantsClassDispatcher,
  any
> {
  render() {
    const GratnsFilterdByDate = filterDocsByDate(
      this.props.filterDate,
      this.props.filterDateText,
      this.props.grants!
    );

    const GrantsSearched = filterFoundDocs(
      this.props.documentSearch,
      GratnsFilterdByDate
    );

    const summary =
      this.props.searchBarState.query.length > 0
        ? getHeaderSearched(GrantsSearched, GratnsFilterdByDate)
        : getHeader(GratnsFilterdByDate);

    return (
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "20px",
            paddingLeft: "20px"
          }}
        >
          <ProfileDocumentSearch personId={this.props.personId} />
          <DateRangeDropdown />
        </div>
        <SearchBarQureyHelper />
        <SearchBarQureyDisplay />
        {summary}
        <MultiRender
          docs={this.props.grants!.map(e => e as CombinedDocumentsType)}
          dateFilter={this.props.filterDate}
          types={[GenericSearchEnum.GRANTS]}
          limitNoSearch={false}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  searchBarState: getDocumentSearchBarState(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state)
});

const mapDispatchToProps = {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
};

export const GrantsSection = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(GrantsSectionClass as any);

// @ts-ignore
export const GRANTCARD: React.SFC<GrantInterface> = props => {
  const total = props.indirectFunds + props.directFunds;
  return (
    <ProfileCard boxShadowColor={"#FFAC9A"}>
      <H5 deEmphasized>GRANT</H5>
      <Spacer />
      <TrialLink>{props.title}</TrialLink>
      <Spacer />
      <HorizontalProfileRow>
        <B1 small>Contact PI&nbsp;/&nbsp;Project&nbsp;Leader:&nbsp;</B1>
        <B2 small deEmphasized>
          {props.projectLead}
        </B2>
      </HorizontalProfileRow>
      <BottomDetails>
        {props.indirectFunds && props.directFunds && <B1 small>Total:</B1>}
        {props.indirectFunds && props.directFunds && (
          <B2 small deEmphasized>
            {total}
          </B2>
        )}
        {props.directFunds && <B1 small> Direct:</B1>}
        {props.directFunds && (
          <B2 small deEmphasized>
            {props.directFunds}
            &nbsp;
          </B2>
        )}
        {props.indirectFunds && <B1 small> IndirectFunds:</B1>}
        {props.indirectFunds && (
          <B2 small deEmphasized>
            {props.indirectFunds}
            &nbsp;
          </B2>
        )}
        {props.fundingYear && <B1 small> Funding&nbsp;Year:</B1>}
        {props.fundingYear && (
          <B2 small deEmphasized>
            {props.fundingYear}
            &nbsp;
          </B2>
        )}
      </BottomDetails>

      <BottomDetailNoPad>
        {props.sponsorOrganization && (
          <B1 small> Sponsor&nbsp;Organization:</B1>
        )}
        {props.sponsorOrganization && (
          <B2 small deEmphasized>
            {props.sponsorOrganization}
            &nbsp;
          </B2>
        )}

        {props.piOrganization && <B1 small> Organization&nbsp;of&nbsp;PI:</B1>}
        {props.piOrganization && (
          <B2 small deEmphasized>
            {props.piOrganization}
            &nbsp;
          </B2>
        )}
      </BottomDetailNoPad>
    </ProfileCard>
  );
};
