/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { themeColors, H4, styled, B1, B2 } from "@h1eng/ui-components";
import {
  PersonHeaderInterface,
  PersonContactInfo,
  TitlesAndAssociationInterface
} from "@h1eng/interfaces";
import { Card } from "./ProfileElements";
import { kolBadges } from "../KOLResult/KOLBadges";
import { createAndSortAffiliationEducation } from "./ProfileAffiliationsHelper";
import { SubViews } from "./Profile";
import { ProfileSavedList } from "./ProfileSavedList";
import { ListManager } from "../ListManagement/ListManager";
import { IconButton } from "../../IconButton";
import { openExcel } from "../KOLResult/helpers/ExcelLinkGenerator";
import { uniq } from "lodash";

const images: any = {
  saved: "https://d10ba5vkyum61s.cloudfront.net/img/list-management/saved.svg",
  download: "https://d10ba5vkyum61s.cloudfront.net/img/download.svg",
  trendGraph: "https://d10ba5vkyum61s.cloudfront.net/img/trend-graph.png"
};
const profileCircleIcon =
  "http://d10ba5vkyum61s.cloudfront.net/img/circleperson.svg";

const PersonCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  border-top: 5px solid ${themeColors.themeBackgroundPurple};
`;
const HORIZONTAL_CONTAINER = styled.div`
  && {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
  }
`;
const HorizontalContainerPersonInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;
const KolsCoreContainer = styled.div`
  text-align: center;
  position: absolute;
  right: 20px;
`;

const KolScoreDivider = styled.hr`
  border: 0;
  height: 1px;
  background: #737373;
  margin: 3px 0px;
`;
const AffiliationsLink = styled.div`
  color: #0aaacd;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  cursor: hand;
`;

// const PERSONWORKANDLICENSESCONTAINERCARD = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: left;
//   align-self: flex-start;
// `;
const DetailsContainerLeft = styled.div`
  display: flex;
`;
const DetailContainerRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const RightListContainerCard = styled.div`
  text-align: right;
`;
const Spacer = styled.div`
  margin-bottom: 5px;
`;
const IconBadge = styled.div`
  width: 81px;
  height: 81px;
  background: ${themeColors.themeBackgroundPurple} url(${profileCircleIcon})
    no-repeat center;
  border-radius: 50%;
  top: -41.5px;
  margin-bottom: -30px;
  position: absolute;
`;

const PersonSavedList: React.SFC<PersonHeaderInterface> = props => {
  if (props.savedList && props.savedList.length) {
    return (
      <RightListContainerCard>
        <ProfileSavedList
          paths={props.savedList}
          selected={props.savedList[0]}
        />
      </RightListContainerCard>
    );
  }
  return <></>;
};
const PersonContact: React.SFC<PersonContactInfo> = (props: any) => {
  return (
    <RightListContainerCard>
      {props.email && <B2>{props.email}</B2>}
      <Spacer />
      {props.phoneNumber && (
        <DetailContainerRight>
          <B1>Tel:&nbsp;</B1>
          <B2>{props.phoneNumber}</B2>
        </DetailContainerRight>
      )}
      {props.faxNumber && (
        <DetailContainerRight>
          <B1>Fax:&nbsp;</B1>
          <B2>{props.faxNumber}</B2>
        </DetailContainerRight>
      )}
      <Spacer />
      {props.location && (
        <div>
          {props.location.street && (
            <div style={{ textAlign: "right" }}>
              <B2>
                {props.location.street}
                ,&nbsp;
                {props.location.street}
              </B2>
            </div>
          )}
          <DetailContainerRight>
            {props.location.city && (
              <B1>
                {props.location.city}
                ,&nbsp;
                {props.location.state}
                ,&nbsp;
                {props.location.zip}
              </B1>
            )}
          </DetailContainerRight>
          <Spacer />
          <DetailContainerRight>
            {kolBadges({ types: props.kolBadges })}
          </DetailContainerRight>
        </div>
      )}
    </RightListContainerCard>
  );
};

const Affiliations = (
  props: TitlesAndAssociationInterface[],
  handleChange: any
) => {
  const sortedEduction = createAndSortAffiliationEducation(props);

  const other =
    sortedEduction.sections.other.length > 0 ? (
      <>
        {sortedEduction.sections.other[0].titles.map((e: any, i) => {
          return <B2 key={`item-${e}-${i}`}>{e}</B2>;
        })}
        <B2>{sortedEduction.sections.other[0].department}</B2>
        <B2>{sortedEduction.sections.other[0].school}</B2>
        <B2>{sortedEduction.sections.other[0].organizationName}</B2>

        <Spacer />
        <B1>
          {sortedEduction &&
            sortedEduction.sections &&
            sortedEduction.sections.other[0].location && (
              <>
                {sortedEduction.sections.other[0].location !== undefined &&
                //@ts-ignore
                sortedEduction.sections.other[0].location.state !== undefined
                  ? //@ts-ignore
                    `${sortedEduction.sections.other[0].location.city}, ${
                      sortedEduction.sections.other[0].location.state
                    }`
                  : ""}
              </>
            )}
        </B1>
        {sortedEduction.sections.other.length > 1 && (
          <AffiliationsLink onClick={() => handleChange(SubViews.EXPERIENCE)}>
            {`+ ${sortedEduction.sections.other.length - 1} more associations`}
          </AffiliationsLink>
        )}
      </>
    ) : (
      <></>
    );

  const educationAff = sortedEduction.sections.education;

  const education =
    sortedEduction.sections.education.length > 0 && educationAff ? (
      <>
        {educationAff[0].titles![0] && (
          <B2>{educationAff[0].titles![0]}&nbsp;</B2>
        )}
        {educationAff[0].organizationName && (
          <B1>{educationAff[0].organizationName}</B1>
        )}
        <Spacer />
        {sortedEduction.sections.education.length > 1 && (
          <AffiliationsLink onClick={() => handleChange(SubViews.EXPERIENCE)}>
            {`+ ${sortedEduction.sections.education.length -
              1} more credentials`}
          </AffiliationsLink>
        )}
      </>
    ) : (
      <></>
    );
  return (
    <>
      {other}
      <Spacer />
      {education}
    </>
  );
};
const ListManagerHolder = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`;

export const personCard = (
  props: PersonHeaderInterface,
  handleSubviewChange: any
) => {
  const name = `${props.firstName} ${
    props.middleName ? `${props.middleName} ` : " "
  } ${props.lastName}`;
  return (
    <div>
      <ListManagerHolder>
        <div style={{ display: "flex", justifyItems: "center" }}>
          <IconButton
            id="puppeteer-excel-download-btn"
            onClick={() => openExcel([props.id || ""])}
            img={images.download ? images.download : ""}
            active
          />
          <ListManager personId={props.id || ""} active />
        </div>
      </ListManagerHolder>
      <Card>
        <PersonCardContainer>
          <IconBadge />
          <br />
          <br />
          <H4>{name}</H4>
          <HORIZONTAL_CONTAINER style={{ marginBottom: "0px" }}>
            {props.designations && props.designations.length > 0 ? (
              <B1 deEmphasized small>
                {uniq(props.designations).join(", ")}{" "}
              </B1>
            ) : null}
          </HORIZONTAL_CONTAINER>

          <HorizontalContainerPersonInfo>
            <div>
              {props.titleAndAssociations &&
                Affiliations(props.titleAndAssociations, handleSubviewChange)}

              {props.npi && (
                <DetailsContainerLeft>
                  <B1>NPI:&nbsp;</B1>
                  <B2>{props.npi}</B2>
                </DetailsContainerLeft>
              )}
              {props.orcid && (
                <DetailsContainerLeft>
                  <B1>ORCID:&nbsp;</B1>
                  <B2>{props.orcid}</B2>
                </DetailsContainerLeft>
              )}
              <Spacer />
            </div>
            <div>
              {props.contactInfo &&
                props.contactInfo.map((c: any, i: any) => (
                  <PersonContact key={i} {...c} />
                ))}
              {props.savedList && <PersonSavedList {...props} />}
            </div>
          </HorizontalContainerPersonInfo>
        </PersonCardContainer>
      </Card>
    </div>
  );
};
