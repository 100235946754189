import * as React from "react";

export const COLORED_BACKGROUND = (props: any) => {
  return (
    <div
      className={props.className}
      style={{
        ...props.style,
        backgroundColor: props.color ? props.color : "rgba(0,0,0,0)"
      }}
    >
      {props.children}
    </div>
  );
};

export const LOCKED_WIDTH = (props: any) => {
  return (
    <div
      style={{ ...props.style, maxWidth: "1280px", margin: "0px auto" }}
      className={props.className}
    >
      {props.children}
    </div>
  );
};

export const IMAGE_BACKGROUND = (props: any) => {
  const style = {
    background: `url(${props.img})  center center / cover no-repeat`,
    width: "100%"
  };

  return (
    <div className={props.className} style={style}>
      {props.children}
    </div>
  );
};
