import {
  kolCardsActionGet,
  kolSavedListsCardsActionGet,
  setKolCardsLoading,
  clearLoadingMessage
} from "../actions";
import { isActionOf } from "typesafe-actions";
import { Epic, StateObservable } from "redux-observable";
import { filter, switchMap, map, catchError } from "rxjs/operators";
import { from, of, pipe, concat } from "rxjs";
import { getPersons } from "../../apis/kolCards";
import { RootState } from "../reducers";

export const fetchKolCardFlow: Epic<any, any, any> = action$ => {
  return action$.pipe(
    filter(isActionOf(kolCardsActionGet.request)),
    switchMap(action =>
      from(
        getPersons(
          action.payload.projectId,
          action.payload.userId,
          action.payload.personIds
        )
      ).pipe(
        map(kolCardsActionGet.success),
        catchError(
          pipe(
            kolCardsActionGet.failure,
            of
          )
        )
      )
    )
  );
};

export const clearLoadingMessageFlow: Epic<any, any, any> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        kolSavedListsCardsActionGet.success,
        kolCardsActionGet.success
      ])
    ),
    switchMap(() => of(clearLoadingMessage()))
  );

export const fetchKolSavedListsCardFlow: Epic<any, RootState, any> = (
  action$: any,
  state$: StateObservable<RootState>
) => {
  return action$.pipe(
    filter(isActionOf(kolSavedListsCardsActionGet.request)),
    switchMap(({ payload }) =>
      concat(
        of(setKolCardsLoading(true)),
        from(
          getPersons(payload.projectId, payload.userId, payload.personIds)
        ).pipe(
          map(kolSavedListsCardsActionGet.success),
          catchError(
            pipe(
              kolSavedListsCardsActionGet.failure,
              of
            )
          )
        )
      )
    )
  );
};
