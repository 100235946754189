import { ProjectsAction, getProjectsForUser } from "../actions/projects";
import { getType } from "typesafe-actions";

export interface ProjectState {
  projects: string[];
  selectedId: string | null;
}

const initialState = {
  projects: [],
  selectedId: null
} as ProjectState;

export const projectReducer = (
  state: ProjectState = initialState,
  action: ProjectsAction
): ProjectState => {
  switch (action.type) {
    case getType(getProjectsForUser.success): {
      return {
        ...state,
        projects: action.payload,
        selectedId: action.payload[0]
      };
    }

    default:
      return state;
  }
};
