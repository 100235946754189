import * as React from "react";
import { styled } from "@h1eng/ui-components";
import isNode from "../utils/isNode";

export interface PillInterface {
  textColor?: string;
  removable?: boolean;
  backgroundColor?: string;
  title?: string;
  onClick?: any;
  index?: number;
  opacity?: number;
}

const PILL = styled.div<{
  backgroundColor?: string;
  textColor?: string;
  opacity?: number;
}>`
  font-family: Montserrat;
  font-size: 12px
  font-weight: 600;
  display: inline-block;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 2.5px;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  opacity: ${props => props.opacity};
`;
// const INNER_PILL = styled.div<{
//   textColor?: string;
// }>`
//   color: ${props => props.textColor};
//   opacity: 1;
// `;

const PILL_INNER = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const PILL_INNER_CLOSE = styled.img`
  margin-left: 10px;
  cursor: pointer;
  width: 10px;
  height: 10px;
`;

// const CUIRE_FILTER_PILL = styled.div<{
//   backgroundColor?: string;
//   textColor?: string;
// }>`
//   font-family: Montserrat;
//   font-size: 14px
//   display: inline;
//   border-radius: 20px;
//   padding: 5px 10px;
//   background-color: ${props => props.backgroundColor};
//   color: ${props => props.textColor};
// `;

const images = {
  xcloseDark: "https://d10ba5vkyum61s.cloudfront.net/img/xclose-dark.svg"
};

const PILL_FACTORY: React.SFC<PillInterface> = props => {
  if (props.title && props.onClick) {
    return (
      <PILL {...props} onClick={() => {}}>
        <PILL_INNER>
          <span>{props.title}</span>
          <PILL_INNER_CLOSE onClick={props.onClick} src={images.xcloseDark} />
        </PILL_INNER>
      </PILL>
    );
  }

  return (
    <PILL {...props}>
      <PILL_INNER>{props.children}</PILL_INNER>
    </PILL>
  );
};

export const PILL_COLORS = {
  d7d6e7: "d7d6e7",
  ccf4e4: "ccf4e4"
};

const defaultProps: PillInterface = {
  backgroundColor: PILL_COLORS.ccf4e4,
  textColor: "#333",
  removable: false,
  opacity: 1
};

PILL_FACTORY.defaultProps = defaultProps;

export default PILL_FACTORY as React.SFC<PillInterface>;
