import * as React from "react";
import { styled } from "@h1eng/ui-components";

const LinkRoot = styled.a`
  color: #0aaacd;
  font-family: Montserrat;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
`;

interface Props {
  /**
   * Require aria-label for a11y
   */
  "aria-label": string;
  [key: string]: any;
}

export const PaginationLink: React.SFC<Props> = props => (
  <LinkRoot {...props} />
);
