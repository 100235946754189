import { ENDPOINTS } from "./api-endpoints";
import Axios from "axios";
const JSONAPIDeserializer = require("jsonapi-serializer").Deserializer;

const deserializer = new JSONAPIDeserializer({
  keyForAttribute: "camelCase"
});

export const getProjects = async (userId: string) =>
  Axios.get(`${ENDPOINTS.project}/${userId}?include=userProjectJoins`, {
    withCredentials: true
  })
    .then(async ({ data }: any) => {
      const cleaned = await deserializer.deserialize(data);
      return cleaned.userProjectJoins.map((j: any) => j.projectId) as string[];
    })
    .catch(e => {});
