/* tslint:disable:react-unused-props-and-state */
import * as React from "react";

import { RootState } from "../store/reducers";
import { connect } from "react-redux";
import { getAuthToken } from "../store/selectors";
import { logout } from "../store/actions";
import { withRouter, RouteComponentProps } from "react-router";

interface LogoutProps {
  token: string | null;
}

interface LogoutFuncs {
  logout: () => void;
}

class LogoutPageClass extends React.Component<
  LogoutProps & LogoutFuncs & RouteComponentProps<any>
> {
  componentDidMount() {
    this.props.logout();
    localStorage.clear();
    this.props.history.push("/");
  }

  render() {
    return (
      <div>Cookies Cleared. Must exit browser to cancel session for AUTH</div>
    );
  }
}

const mapStateToProps = (state: RootState): LogoutProps => ({
  token: getAuthToken(state)
});

const mapDispatchToProps = {
  logout
};

export const LogoutPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LogoutPageClass));
