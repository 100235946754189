import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  requestStateOptions,
  setStateQuery,
  resetInitialOptions,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  map,
  filter,
  catchError
} from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { searchOptions } from "../../../apis/searchFilters";
import { DEBOUNCE_TIME } from ".";

const setStateQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setStateQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestStateOptions.request(state$.value.searchFilters.state)
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getStateOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestStateOptions.request)),
    switchMap(({ payload }) =>
      from(
        searchOptions(payload, state$.value.searchFilters.initialOptions.state)
      ).pipe(
        map(requestStateOptions.success),
        catchError(
          pipe(
            requestStateOptions.failure,
            of
          )
        )
      )
    )
  );

export default [setStateQueryFlow, getStateOptionsFlow];
