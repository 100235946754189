import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import NotFoundPage from "../pages/404";

import { SavedListsPage } from "../components/curie/SavedListsPage";
import { CurieSearchPage } from "../components/curie/KOLSearch/containers/SearchPage";
import { ProfileController } from "../components/curie/Profile/ProfileController";

import { SignInPage } from "../pages/SignInPage";
import { WelcomePage } from "../pages/WelcomePage";
import { LogoutPage } from "../pages/LogoutPage";

const routes = (
  <Switch>
    <Redirect exact from="/" to="/curie/search" />
    <Route exact from="/curie/search" component={CurieSearchPage} />
    <Route exact path="/signin" component={SignInPage} />
    <Route exact path="/welcome" component={WelcomePage} />
    <Route exact path="/logout" component={LogoutPage} />
    <Route exact path="/saved-lists" component={SavedListsPage} />
    <Route path="/saved-lists/:listId" component={SavedListsPage} />
    <Route exact path="/curie/person/:personId" component={ProfileController} />
    <Route
      path="/curie/person/:personId/:section/:docId?"
      component={ProfileController}
    />
    <Route component={NotFoundPage} />
  </Switch>
);

export default routes;
