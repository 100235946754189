/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { SearchView } from "@h1eng/interfaces";

interface Props {
  searchView: SearchView;
  setSearchView: (view: SearchView) => void;
}

export const Selected = {};
export const Unselected = {};

export class ViewSelector extends React.Component<Props> {
  render() {
    const selected = "#060464";
    const unselected = "#FFFFFF";
    const fontSeleted = "#FFFFFF";
    const fontUnselected = "#333333";
    const button = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "41px",
      width: "148px",
      cursor: "pointer"
    };
    const text = {
      fontFamily: "Montserrat",
      fontSize: " 14px",
      fontWeight: 600,
      lineHeight: "18px"
    };
    const borderRightSide = {
      border: "1px solid #A39E92",
      borderRadius: "4px 0 0 4px"
    };

    const borderLeftSide = {
      border: "1px solid #A39E92",
      borderRadius: "0 4px 4px 0"
    };
    const setView = (sv: SearchView) => this.props.setSearchView(sv);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto"
        }}
      >
        <div
          style={{
            ...button,
            ...borderRightSide,
            backgroundColor:
              this.props.searchView === SearchView.SearchedWork
                ? selected
                : unselected
          }}
          onClick={() => setView(SearchView.SearchedWork)}
        >
          <div
            style={{
              ...text,
              color:
                this.props.searchView === SearchView.SearchedWork
                  ? fontSeleted
                  : fontUnselected
            }}
          >
            Searched Work
          </div>
        </div>
        <div
          style={{
            ...button,
            borderTop: "1px solid #A39E92",
            borderBottom: "1px solid #A39E92",
            backgroundColor:
              this.props.searchView === SearchView.TotalWork
                ? selected
                : unselected
          }}
          onClick={() => setView(SearchView.TotalWork)}
        >
          <div
            style={{
              ...text,
              color:
                this.props.searchView === SearchView.TotalWork
                  ? fontSeleted
                  : fontUnselected
            }}
          >
            Total Work
          </div>
        </div>
        <div
          style={{
            ...button,
            ...borderLeftSide,
            backgroundColor:
              this.props.searchView === SearchView.WorkOverTime
                ? selected
                : unselected
          }}
          onClick={() => setView(SearchView.WorkOverTime)}
        >
          <div
            style={{
              ...text,
              color:
                this.props.searchView === SearchView.WorkOverTime
                  ? fontSeleted
                  : fontUnselected
            }}
          >
            Work Over Time
          </div>
        </div>
      </div>
    );
  }
}
