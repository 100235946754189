import * as React from "react";
import { styled, B2, B1 } from "@h1eng/ui-components";

import { TitlesAndAssociationInterface } from "@h1eng/interfaces";

const FLEX_1 = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  list-style: none;
  padding: 0;
  background-color: #fff;
  color: #444;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const GIRDDATA = styled.div`
  padding-left: 20px;
`;

const AffiliationsTitle = styled.div`
  color: #737373;
  font-family: Montserrat;
  font-size: 12px;
  margin-left: 20px;
  font-weight: bold;
  padding-top: 20px;
`;

const AffiliationsRender = (props: TitlesAndAssociationInterface) => {
  return (
    <GIRDDATA>
      {props.organizationName && <B1>{props.organizationName}</B1>}
      {props.title && <B1>{props.title}</B1>}
      <B2>
        {props.location
          ? [props.location.city, props.location.state]
              .filter((x: any) => x)
              .join(", ")
          : null}
      </B2>
      {props.startDate && props.endDate && (
        <B2>{`${new Date(props.startDate || "").getFullYear()} - ${new Date(
          props.endDate || ""
        ).getFullYear()}`}</B2>
      )}
      <br />
    </GIRDDATA>
  );
};

export const ProfileAffiliationsAwardsSorted = (
  affiliations: TitlesAndAssociationInterface[]
) => {
  const awards = affiliations
    .sort(
      (a: TitlesAndAssociationInterface, b: TitlesAndAssociationInterface) => {
        if (
          a.startDate &&
          b.endDate &&
          typeof a.startDate === "string" &&
          typeof b.startDate === "string"
        ) {
          return (
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
          );
        }
        return 0;
      }
    )
    .map((t: TitlesAndAssociationInterface, i) => (
      <AffiliationsRender key={`t-${t.organizationName}-${i}`} {...t} />
    ));

  return (
    <div style={{ background: "white" }}>
      <AffiliationsTitle>AWARDS RECEIVED</AffiliationsTitle>
      <FLEX_1> {awards}</FLEX_1>
    </div>
  );
};
