import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { PieChart, ResponsiveContainer, Pie, Cell, Tooltip } from "recharts";
import { PieGraphData } from "@h1eng/interfaces";
import { PieGraphLegend } from "./PieGraphLegend";
import { tooltip } from "../styles";
import { formatStats } from "@h1eng/format-util";

interface OwnProps {
  data: PieGraphData;
  title: string;
}

const TitleContainer = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #1c1c1c;
  font-family: "Montserrat";
`;

const colors = ["#071362", "#BFFCE0", "#EE8E7B", "#D7D6E7"];
export class PieGraphClass extends React.Component<OwnProps> {
  static defaultProps = {
    colors: "#060464"
  };

  render() {
    const { title, data } = this.props;
    const dataFormated = data.payments.map((e, i) => {
      return {
        value: +e.amount.toFixed(2),
        name: e.name === "null" ? "Unknown" : e.name,
        formatter: (value: number) => {
          return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
        }
      };
    });

    return (
      <div
        style={{
          display: "flex",
          width: "50%",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "10px",
          paddingTop: "10px"
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "75%"
            }}
          >
            {!!title && <TitleContainer>{title}</TitleContainer>}
            <ResponsiveContainer height={200} width={"100%"}>
              <PieChart data={dataFormated}>
                <Tooltip active={true} {...tooltip} />
                <Pie
                  strokeOpacity={0}
                  isAnimationActive={false}
                  outerRadius={60}
                  data={dataFormated}
                  dataKey={"value"}
                  nameKey={"name"}
                >
                  {data.payments.map((entry, index) => (
                    <Cell fill={colors[index]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div style={{ display: "flex" }}>
            <PieGraphLegend
              legendItems={data.payments.map((e, i) => {
                return { color: colors[i], key: e.name };
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}
