import { createSelector } from "reselect";
import { RootState } from "../reducers";
import {
  getCurrentScoredDocuments,
  getCurrentPersonIds
} from "./searchResultSelectors";
import {
  ResultCardData,
  ScoredDocumentData,
  ScoredDocumentResult,
  ScoredResultCardData
} from "@h1eng/interfaces";
import { paginate } from "@h1eng/pagination";

export const getKolCardState = (state: RootState) => state.kolCards;

export const getGlobalState = (state: RootState) => state;

export function addScoredDocumentsToResultCard(
  card: ResultCardData,
  docs: ScoredDocumentData[]
) {
  const nullRange = { min: 0, max: 0 };
  const nullResult: ScoredDocumentResult = {
    value: 0,
    normalizedValue: 0,
    percentile: 0,
    minValue: 0,
    maxValue: 0
  };

  const nullData: ScoredDocumentData = {
    personId: card.personId,
    normalizedRange: nullRange,
    publications: nullResult,
    citations: nullResult,
    trials: nullResult,
    payments: nullResult,
    grants: nullResult,
    congresses: nullResult,
    paymentsCount: nullResult,
    grantsCount: nullResult,
    h1Score: 0
  };

  const scoredDocuments =
    docs.find(i => i.personId === card.personId) || nullData;

  const res: ScoredResultCardData = { ...card, scoredDocuments };

  return res;
}

export const getCards = createSelector(
  getGlobalState,
  state => {
    const { kolCards } = state;
    const scoredDocs = getCurrentScoredDocuments(state).persons;

    return kolCards.cards.map(card =>
      addScoredDocumentsToResultCard(card, scoredDocs)
    );
  }
);

export const getSavedListsKolCards = createSelector(
  getGlobalState,
  state => state.kolCards.savedListsKolCards
);

export const getCardsLoadingStatus = createSelector(
  getKolCardState,
  kolCardState => kolCardState.loading
);

export const getCachedCardsInPageRange = createSelector(
  getGlobalState,
  state => {
    const activeIds = getCurrentPersonIds(state);

    return state.kolCards.allCards
      .filter(e => activeIds.includes(e.personId))
      .sort(
        (a, b) => activeIds.indexOf(a.personId) - activeIds.indexOf(b.personId)
      )
      .map(i =>
        addScoredDocumentsToResultCard(
          i,
          state.searchResults.scoredDocuments.persons
        )
      );
  }
);
