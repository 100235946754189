import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { COLORED_BACKGROUND, LOCKED_WIDTH } from "./Layout";
import { colors } from "./styles/variables";

const baseFooterStyles = (theme: any) => ({
  padding: "100px 50px 50px 50px",
  [theme.breakpoints.down("xs")]: { textAlign: "center" }
});

const footerTitleStyles = {
  color: "white",
  textTransform: "uppercase",
  fontWeight: "bold",
  fontSize: "12px",
  marginTop: "20px"
};

const footerLinkStyles = {
  color: "rgba(255, 255, 255, .8)",
  textDecoration: "none",
  fontSize: "14px",
  display: "inline-block",
  "&:hover": { color: "white", cursor: "pointer" }
};

const parentOrgInfoStyles = (theme: any) => ({
  color: "white",
  textDecoration: "none",
  [theme.breakpoints.down("xs")]: {
    textAlign: "center",
    marginBottom: "50px"
  }
});

const learnMoreStyles = (theme: any) => ({
  [theme.breakpoints.down("xs")]: {
    marginTop: "30px"
  }
});

const footerStyles: any = (theme: any) => {
  return {
    footer: baseFooterStyles(theme),
    footerTitle: footerTitleStyles,
    footerLink: footerLinkStyles,
    footerText: { color: "rgba(255, 255, 255, .8)", fontSize: "12px" },
    footerCaption: { color: "white" },
    parentOrgInfo: parentOrgInfoStyles(theme),
    learnMore: learnMoreStyles(theme)
  };
};

const FOOTERLINK = withStyles(footerStyles)((props: any) => (
  <Typography style={{ margin: "10px 0px" }}>
    <a
      href={props.to}
      target={props.target ? props.target : ""}
      className={props.classes.footerLink}
    >
      {props.children}
    </a>
  </Typography>
));

const FooterLogo = withStyles(footerStyles)((props: any) => (
  <Grid item xs={12} sm={4} className={props.classes.parentOrgInfo}>
    <a href="/" style={{ textDecoration: "none", display: "inline-block" }}>
      <Typography variant="display3" style={{ color: "white" }}>
        <b>H1.</b>
      </Typography>
    </a>
  </Grid>
));

const ServicesColumn = withStyles(footerStyles)((props: any) => (
  <Grid item xs={12} sm={2}>
    <Typography className={props.classes.footerTitle}>Services</Typography>
    <FOOTERLINK to="/davinci">H1.DaVinci</FOOTERLINK>
  </Grid>
));

const ProductsColumn = withStyles(footerStyles)((props: any) => (
  <Grid item xs={12} sm={2}>
    <Typography className={props.classes.footerTitle}>Products</Typography>
    <FOOTERLINK to="/ada">H1.Ada</FOOTERLINK>
    <FOOTERLINK to="/curie">H1.Curie</FOOTERLINK>
    <FOOTERLINK to="/darwin">H1.Darwin</FOOTERLINK>
  </Grid>
));

const LearnMoreColumn = withStyles(footerStyles)((props: any) => (
  <Grid item xs={12} sm={2}>
    <Typography className={props.classes.footerTitle}>Learn More</Typography>
    <FOOTERLINK to="/about">About</FOOTERLINK>
    <FOOTERLINK to="/press">Press</FOOTERLINK>
    <FOOTERLINK target="_blank" to="https://learn.h1insights.com/careers">
      Careers
    </FOOTERLINK>
    <FOOTERLINK
      target="_blank"
      to="https://www.linkedin.com/company/h1insights/"
    >
      LinkedIn
    </FOOTERLINK>
    <FOOTERLINK target="_blank" to="https://learn.h1insights.com/blog">
      Blog
    </FOOTERLINK>
  </Grid>
));

const LegalColumn = withStyles(footerStyles)((props: any) => (
  <Grid item xs={12} sm={2}>
    <Typography className={props.classes.footerTitle}>Legal</Typography>
    <FOOTERLINK to="/terms.html" target="_blank">
      Terms of Use
    </FOOTERLINK>
    <FOOTERLINK to="/privacypolicy.html" target="_blank">
      Privacy Policy
    </FOOTERLINK>
  </Grid>
));

const FooterNotice = withStyles(footerStyles)((props: any) => (
  <Grid item xs={12} style={{ marginTop: "100px" }}>
    <Typography variant="caption" style={{ color: "rgba(255, 255, 255, .4)" }}>
      ©2018 Shore Group Associates. All rights reserved
    </Typography>
  </Grid>
));

const FooterGrid = withStyles(footerStyles)((props: any) => (
  <Grid container className={props.classes.footer}>
    <FooterLogo />
    <ServicesColumn />
    <ProductsColumn />
    <LearnMoreColumn />
    <LegalColumn />
    <FooterNotice />
  </Grid>
));

export const Footer = withStyles(footerStyles)(props => (
  <React.Fragment>
    <COLORED_BACKGROUND color={colors.primaryBackground}>
      <LOCKED_WIDTH>
        <FooterGrid />
      </LOCKED_WIDTH>
    </COLORED_BACKGROUND>
  </React.Fragment>
));
