import { isActionOf } from "typesafe-actions";
import { Epic } from "redux-observable";

import { filter, switchMap, map, catchError } from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { getProjectsForUser } from "../actions/projects";
import { getProjects } from "../../apis/projects";

export const fetchProjectsFlow: Epic<any, any, any> = action$ => {
  return action$.pipe(
    filter(isActionOf(getProjectsForUser.request)),
    switchMap(action =>
      from(getProjects(action.payload)).pipe(
        map(getProjectsForUser.success),
        catchError(
          pipe(
            getProjectsForUser.failure,
            of
          )
        )
      )
    )
  );
};
