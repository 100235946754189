import * as React from "react";
import { connect } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { GraphDatum } from "@h1eng/interfaces";
import {
  cartesianGrid,
  bar,
  xAxis,
  yAxis,
  barChart,
  legend,
  tooltip
} from "../styles";
import { getDocumentSearchBarQurey } from "../../../../../store/selectors";
import {
  getRenderSearched,
  getBarSize,
  getXAxisInterval
} from "../lib/helpers";
import { GraphTitle } from "./GraphTitle";

interface BarGraphProps {
  data: GraphDatum[];
  yAxisLabel?: string;
  title?: string;
  primaryColor: string;
  secondaryColor?: string;
}

interface DispatchProps {
  searchQuery: any[];
}

class BarGraphClass extends React.Component<BarGraphProps & DispatchProps> {
  static defaultProps = {
    secondaryColor: "#d7d6e7"
  };

  render() {
    const { data, title, primaryColor, secondaryColor } = this.props;
    const renderSearched = getRenderSearched(this.props);
    const barSize = getBarSize(this.props);

    return (
      <div>
        {!!title && <GraphTitle>{title}</GraphTitle>}
        <ResponsiveContainer aspect={2}>
          <BarChart {...barChart} data={data}>
            <CartesianGrid {...cartesianGrid} />
            <Bar
              {...bar}
              barSize={barSize}
              dataKey="total"
              fill={primaryColor}
              name="Total"
            />
            {renderSearched && (
              <Bar
                {...bar}
                barSize={barSize}
                dataKey="searched"
                fill={secondaryColor}
                name="Matched keywords"
              />
            )}
            <XAxis
              {...xAxis}
              interval={getXAxisInterval(data.length)}
              dataKey="year"
            />
            <YAxis
              {...yAxis}
              label={{ ...yAxis.label, value: this.props.yAxisLabel }}
            />
            <Tooltip {...tooltip} />
            {renderSearched && <Legend {...legend} />}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  searchQuery: getDocumentSearchBarQurey(state)
});

export const BarGraph = connect<any, any, any>(mapStateToProps)(
  BarGraphClass as any
);
