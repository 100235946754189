import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 482px;
  border: 2px solid #e8e6e1;
  border-radius: 8px 8px 0 0;
  background-color: #ffffff;
  flex-grow: 1;
  height: fit-content;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 256px;
  align-items: center;
`;

const Title = styled.span`
  color: #333333;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  margin-bottom: 4px;
`;

const Text = styled.span`
  color: #333333;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  max-width: 200px;
  margin-bottom: 15px;
`;

export const NoActiveQuery: React.SFC = () => (
  <Root>
    <ContentWrapper>
      <Img src="https://d10ba5vkyum61s.cloudfront.net/img/no-search.png" />
      <Title>Please enter a search term</Title>
      <Text>
        Try searching for a term or name in the search field to see results
      </Text>
    </ContentWrapper>
  </Root>
);
