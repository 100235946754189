import { ENDPOINTS } from "./api-endpoints";

export const getPersons = (
  projectId: string,
  userId: string,
  personIds: string[]
) => {
  return fetch(`${ENDPOINTS.kolCards}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({
      personIds,
      projectId,
      userId
    })
  }).then(res => res.json());
};
