import * as React from "react";
import { styled } from "@h1eng/ui-components";

const NullStateContainer = styled.div`
  background-color: white;
  border: solid 1px #ecf0f1;
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Content = styled.div`
  width: 350px;
  margin-top: 20px;
`;

const images = {
  zeroState: "https://d10ba5vkyum61s.cloudfront.net/img/zerostate.png"
};

export const NullState: React.SFC<{}> = props => {
  return (
    <NullStateContainer>
      <img style={{ width: "240px" }} src={images.zeroState} />
      <Content>{props.children}</Content>
    </NullStateContainer>
  );
};
