import { NavBar } from "../nav";
import { NavBarVariant } from "../nav/NavBarVariant";
import { UserVO } from "../../models/userVO";
import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducers";
import { currentUser } from "../../store/selectors/authSelectors";
import { withRouter, RouteComponentProps } from "react-router";

interface ShellProps {
  user: UserVO;
}

/* tslint:disable:no-empty-interface */
interface ShellFuncs {}
/* tslint:enable:no-empty-interface */

class LoggedInShellClass extends React.Component<
  ShellProps & ShellFuncs & RouteComponentProps<any>
> {
  visitWelcomePage = () => {
    this.props.history.push("/welcome");
  };

  async checkAuthentication() {
    if (!this.props.user) this.visitWelcomePage();
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  render() {
    return this.props.user ? (
      <>
        <NavBar variant={NavBarVariant.GRAY} underline user={this.props.user} />
        {this.props.children}
      </>
    ) : (
      <div />
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state: RootState): ShellProps => ({
  user: currentUser(state)!
});

export const LoggedInShell = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoggedInShellClass));
