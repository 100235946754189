/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { Ellipsis } from "./Ellipsis";
import { Truncate, TruncateProps } from "./Truncate";

const StyledTruncate = styled(Truncate)`
  font-family: Montserrat;
`;

interface TextTruncateProps {
  style?: React.CSSProperties;
  expandLabel?: React.ReactNode;
  minimizeLabel?: React.ReactNode;
  renderMinimizeLabel?: boolean;
}

interface TextTruncateState {
  expanded: boolean;
  truncated: boolean;
}

type Props = TextTruncateProps & TruncateProps;

export class TextTruncate extends React.Component<Props, TextTruncateState> {
  static defaultProps: Partial<Props> = {
    expandLabel: <>...&nbsp;See&nbsp;More</>,
    minimizeLabel: <>Show&nbsp;Less</>,
    renderMinimizeLabel: true
  };

  state: TextTruncateState = {
    expanded: false,
    truncated: false
  };

  handleTruncate = (truncated: boolean) => {
    this.setState((state: TextTruncateState) => {
      if (state.truncated !== truncated) return { truncated };

      return state;
    });
  };

  toggleLines = (event: React.SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    this.setState((state: TextTruncateState) => ({
      expanded: !state.expanded
    }));
  };

  render() {
    const {
      children,
      expandLabel,
      minimizeLabel,
      lines,
      renderMinimizeLabel,
      width,
      ...props
    } = this.props;

    const { expanded, truncated } = this.state;

    return (
      <div>
        <StyledTruncate
          lines={!expanded && lines}
          width={!expanded ? width : undefined}
          ellipsis={
            <Ellipsis onClick={this.toggleLines.bind(this)} color="#0AAACD">
              {expandLabel}
            </Ellipsis>
          }
          onTruncate={this.handleTruncate}
          {...props}
        >
          {children}
        </StyledTruncate>
        {renderMinimizeLabel && !truncated && expanded && (
          <span>
            {" "}
            <Ellipsis onClick={this.toggleLines.bind(this)} color="#0AAACD">
              {minimizeLabel}
            </Ellipsis>
          </span>
        )}
      </div>
    );
  }
}
