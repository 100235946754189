import * as React from "react";

import { UserVO } from "../models/UserVO";
import { RootState } from "../store/reducers";
import { currentUser } from "../store/selectors/authSelectors";
import { connect } from "react-redux";
import { setToken } from "../store/actions/user";
import { withRouter, RouteComponentProps } from "react-router";

interface SignInProps {
  user: UserVO | null;
}

interface SignInFuncs {
  setToken: (token: string) => void;
}

class SignInPageClass extends React.Component<
  SignInProps & SignInFuncs & RouteComponentProps<any>
> {
  componentDidMount() {
    this.checkAuthentication();
  }

  componentDidUpdate() {
    if (this.props.user) this.props.history.push("/curie/search");
  }

  checkAuthentication() {
    const token = decodeURIComponent(
      this.props.location.search.replace("?token=", "")
    );
    if (token) this.props.setToken(token);
  }
  render() {
    return <div>Authenticating....</div>;
  }
}

const mapStateToProps = (state: RootState): SignInProps => ({
  user: currentUser(state)
});

const mapDispatchToProps = {
  setToken
};

export const SignInPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignInPageClass));
