import {
  FilterSearchInterface,
  GenericSearchInterface,
  GetCollaboratorsInterface,
  GetCollaboratorsDetialsInterface,
  ScoredDocumentResults,
  ScoredDocumentData
} from "@h1eng/interfaces";
// import axios from "axios";
import { ENDPOINTS } from "./api-endpoints";

// const searchPeople2 = async (filters: FilterSearchInterface) =>
//   axios
//     .post(ENDPOINTS.peopleSearch, { filters }, { withCredentials: true })
//     .then(({ data }: { data: { id: string }[] }) => data.map((e: any) => e.id))
//     .catch((e: any) => {
//       console.error(e);
//     });
export const searchPeople = async (filters: any) => {
  const asw = await fetch(`${ENDPOINTS.peopleSearch}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ filters })
  });

  const data: {
    totalHits: number;
    scoredDocuments: ScoredDocumentResults;
  } = await asw.json();

  return data;
};

export const searchDocuments = async (search: GenericSearchInterface) => {
  const asw = await fetch(`${ENDPOINTS.genericSearch}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(search)
  });
  const data = await asw.json();
  return data;
};

export const findCollaborators = async (search: GetCollaboratorsInterface) => {
  const asw = await fetch(`${ENDPOINTS.findCollaborators}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(search)
  });
  const data = await asw.json();
  return data;
};

export const collaboratorsDetails = async (
  search: GetCollaboratorsDetialsInterface
) => {
  const asw = await fetch(`${ENDPOINTS.collaboratorsDetails}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(search)
  });
  const data = await asw.json();
  return data;
};

export const hasCollaborators = async (search: GetCollaboratorsInterface) => {
  const asw = await fetch(`${ENDPOINTS.hasCollaborators}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(search)
  });
  const data = await asw.json();
  return data;
};

export const clearScrollIdApi = async (scrollId: string) => {
  const asw = await fetch(`${ENDPOINTS.clearScrolld}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ scrollId })
  });
  const data = await asw.json();
  return data;
};
