import * as React from "react";
import Axios from "axios";
import { H3 } from "@h1eng/ui-components";
import {
  ProfileInterface,
  UserPersonList,
  GetCollaboratorsInterface
} from "@h1eng/interfaces";
import { Profile, SubViews, subviewForPath, pathForSubview } from "./Profile";
import { fetchListsForPersons } from "../../../apis/list";
import * as _ from "lodash";
import { ENDPOINTS } from "../../../apis/api-endpoints";
import { LoggedInShell } from "../../templates/LoggedInShell";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import {
  getSelectedProject,
  currentUser,
  getDocumentSearchBarState,
  getQuery
} from "../../../store/selectors";
import { UserVO } from "../../../models/UserVO";
import { hasCollaborators } from "../../../apis/search";
import { searchCollaborators, setPersonId } from "../../../store/actions";
import {
  setDocumentSearchBarQuery,
  DocumentSearchBarQueryInterface
} from "../../../store/actions";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface ProfileControllerStateInterface {
  profileData?: ProfileInterface;
  hasCollaborators: boolean;
  loading: boolean;
  subView?: SubViews;
}

interface MappedStateProps {
  projectId: string | null;
  user: UserVO | null;
  searchBarState: any;
  globalSearchQuery: string;
}

interface DispatchProps {
  setDocumentSearchBarQuery: (t: DocumentSearchBarQueryInterface) => void;
  searchCollaborators: (obj: GetCollaboratorsInterface) => void;
  setPersonId: (personId: string) => void;
}

type Props = DispatchProps &
  MappedStateProps &
  RouteComponentProps<{ personId: string; section?: string; docId?: string }>;

export class ProfileControllerClass extends React.Component<
  Props,
  ProfileControllerStateInterface
> {
  state = {
    hasCollaborators: false,
    loading: true,
    profileData: undefined,
    subView: undefined
  };

  style = {
    width: "100%",
    maxWidth: "980px",
    margin: "0 auto",
    padding: "100px 0px",
    display: "flex",
    justifyContent: "center"
  };

  componentWillMount() {
    const personId = this.props.match.params.personId;

    if (this.props.globalSearchQuery.length > 0) {
      this.props.setDocumentSearchBarQuery({
        query: this.props.globalSearchQuery.split(","),
        personId,
        types: []
      });
    }
  }

  componentDidMount() {
    const personId = this.props.match.params.personId;

    Axios.get(
      `${ENDPOINTS.profile}/${personId}?projectId=${this.props.projectId}`,
      { withCredentials: true }
    )
      .then(({ data }) => {
        this.props.setPersonId(personId);
        this.props.searchCollaborators({
          personId,
          terms: this.props.searchBarState.query
        });
        this.setState({ profileData: data.payload, loading: true });
      })
      .then(data => {
        return fetchListsForPersons(
          [personId],
          this.props.projectId!,
          this.props.user!.id
        );
      })
      .then((metaData: any) => {
        const { profileData } = this.state;
        if (profileData) {
          const userList: UserPersonList[] = _.flatten(
            metaData.map((e: any) => {
              return e.lists.map((i: any) => {
                return { id: i.id, listName: i.title } as UserPersonList;
              });
            })
          );
          // @ts-ignore
          profileData.header.savedList = userList;
          this.setState({ profileData });
        }
      })
      .then(() => {
        return hasCollaborators({ personId });
      })
      .then(e => {
        this.setState({ hasCollaborators: e, loading: false });
      });
  }

  handleSubviewChange = (subView: SubViews) => {
    const personId = this.props.match.params.personId;
    const url = `/curie/person/${personId}/${pathForSubview(subView)}`;
    this.props.history.push(url);
  };

  getSubView = () =>
    this.props.match.params.section
      ? subviewForPath(this.props.match.params.section)
      : SubViews.OVERVIEW;

  render() {
    return (
      <LoggedInShell>
        <div
          style={{
            backgroundColor: "#f6f6f6",
            minHeight: "100%",
            minWidth: " 1024px"
          }}
        >
          <div style={this.style}>
            {this.state.loading ? (
              <div style={{ textAlign: "center" }}>
                <H3>Loading...</H3>
              </div>
            ) : (
              <Profile
                {...this.state.profileData}
                hasCollaborators={this.state.hasCollaborators}
                subView={this.getSubView()}
                docId={this.props.match.params.docId}
                handleSubviewChange={this.handleSubviewChange}
              />
            )}
          </div>
        </div>
      </LoggedInShell>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  projectId: getSelectedProject(state),
  user: currentUser(state),
  searchBarState: getDocumentSearchBarState(state),
  globalSearchQuery: getQuery(state)
});

export const mapDispatchToProps: DispatchProps = {
  searchCollaborators: searchCollaborators.request,
  setDocumentSearchBarQuery,
  setPersonId
};

export const ProfileController = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileControllerClass));
