/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { styled } from "./themes/styled-components";
import { themeColors } from "./Colors";
import { Link, LinkProps } from "react-router-dom";
import { omit } from "lodash";

type WEIGHT_TYPE = 300 | 600 | "bold" | "inherit";

interface LinkInterface {
  size?: string;
  color?: string;
  fontWeight?: WEIGHT_TYPE;
  highlightColor?: string;
}
// factory interface
export interface LinkFactoryInterface {
  to: any;
  external: boolean;
  color: string;
  highlightColor: string;
  fontWeight: WEIGHT_TYPE;
  size: string;
}

// prefab interface
export interface L1Interface {
  to: any;
  external?: boolean;
  darkBackground?: boolean;
  weight?: WEIGHT_TYPE;
  size?: string;
}

export interface L2Interface {
  to: any;
  external?: boolean;
  weight?: WEIGHT_TYPE;
  size?: string;
  target?: string;
}

const omitProps = ["size", "color", "fontWeight", "highlightColor"];

const InternalLink = styled((props: LinkInterface & LinkProps) => (
  <Link {...omit(props, omitProps) as LinkProps} />
))`
  font-size: ${props => props.size};
  font-family: Montserrat;
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight};
  text-decoration: none;
  &:hover {
    color: ${props => props.highlightColor};
    cursor: pointer;
  }
`;

const ExternalLink = styled((props: LinkInterface) => (
  <a {...omit(props, omitProps) as any} />
))`
  font-size: ${props => props.size};
  font-family: Montserrat;
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight};
  text-decoration: none;
  &:hover {
    color: ${props => props.highlightColor};
    cursor: pointer;
  }
`;

export const LinkFactory: React.SFC<
  LinkFactoryInterface & { href?: string }
> = props => {
  const { external, children, ...otherProps } = props;

  if (external === true) {
    return (
      <ExternalLink href={props.to} {...otherProps}>
        {children}
      </ExternalLink>
    );
  }

  return <InternalLink {...otherProps}>{children}</InternalLink>;
};

export const L1: React.SFC<L1Interface> = props => {
  const fontWeight = props.weight || "bold";
  const size = props.size || "14px";
  const external = props.external || false;
  const color = props.darkBackground
    ? themeColors.themeLinkDarkBackground
    : themeColors.themeLinkDefualt;
  const highlightColor = props.darkBackground
    ? themeColors.themeLinkDarkBackgroundHover
    : themeColors.themeLinkDefaultHover;

  const factoryProps: LinkFactoryInterface = {
    fontWeight,
    external,
    color,
    size,
    highlightColor,
    to: props.to
  };

  return <LinkFactory {...factoryProps}>{props.children}</LinkFactory>;
};

export const L2: React.SFC<L2Interface> = props => {
  const fontWeight = props.weight || 600;
  const size = props.size || "14px";
  const external = props.external || false;
  const color = themeColors.themeBodyDefualt;
  const highlightColor = themeColors.themeLinkDefualt;

  const factoryProps: LinkFactoryInterface = {
    fontWeight,
    external,
    color,
    size,
    highlightColor,
    to: props.to
  };

  return <LinkFactory {...factoryProps}>{props.children}</LinkFactory>;
};
