import * as React from "react";
import { Link } from "react-router-dom";
import { styled } from "@h1eng/ui-components";
import { BackLink } from "./BackLink";

const StyledLink = styled(Link)`
  color: #333333;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  white-space: nowrap;
  &:before {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    bottom: 1px;
    right: 3px;
    position: relative;
    vertical-align: middle;
    transform: rotate(-135deg);
  }
`;

interface BackLinkProps {
  text?: string;
  to: string;
  [key: string]: any;
}

const defaultBackLinkProps = {
  text: "Back to Profile",
  to: "/"
};

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children || null;
  }
}

export const withBackLink = (Component: any, backLinkProps: BackLinkProps) => (
  props: any
) => {
  const { text, ...otherBackLinkProps } = {
    ...defaultBackLinkProps,
    ...backLinkProps
  };

  return (
    <>
      <ScrollToTopOnMount />
      <BackLink>
        <StyledLink {...otherBackLinkProps}>{text}</StyledLink>
      </BackLink>
      <Component {...props} />
    </>
  );
};

export default withBackLink;
