import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Anchor = styled.a`
  color: #0aaacd;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

interface ExternalLinkProps {
  target?: string;
  rel?: string;
  [key: string]: any;
}

export const ExternalLink: React.SFC<ExternalLinkProps> = ({
  target = "_blank",
  rel = "noreferrer noopener",
  ...props
}) => <Anchor target={target} rel={rel} {...props} />;
