import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Dictionary } from "lodash";
import { NavBarVariant } from "./NavBarVariant";

const BasedDropDownLink = styled(Link)<{ hoverColor?: string }>`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  color: ${props => props.color};
  &:hover {
    color: ${props => props.hoverColor};
  }
`;

export const DropDownLink = (props: any) => {
  const colors: Dictionary<{ link: string; linkHover: string }> = {
    [NavBarVariant.DEFAULT]: {
      link: "white",
      linkHover: "#aeffde"
    },
    [NavBarVariant.GRAY]: {
      link: "#737373",
      linkHover: "#333"
    },
    [NavBarVariant.DARK]: {
      link: "white",
      linkHover: "#aeffde"
    }
  };

  return (
    <BasedDropDownLink
      color={colors[props.variant].link}
      hoverColor={colors[props.variant].linkHover}
      {...props}
    >
      {props.children}
    </BasedDropDownLink>
  );
};
