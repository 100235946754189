import * as React from "react";

export const withImages = (images: object) => (COMPONENT: any) => {
  const WithImages = class WithImagesClass extends React.Component {
    render() {
      return <COMPONENT images={images} {...this.props} />;
    }
  };
  return WithImages;
};
