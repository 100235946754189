export const openExcel = (ids: string[]) => {
  if (process.env.NODE_ENV !== "development" && process.env.PROXIED_APP) {
    return window.open(
      `https://${process.env.BACKEND_API}${
        process.env.EXCEL_API_PATH
      }/persons?ids=${ids.join(",")}`
    );
  }

  const requestType =
    process.env.NODE_ENV !== "development"
      ? `https://${process.env.BACKEND_API}`
      : "http";
  const url =
    (process.env.PROXIED_APP
      ? ""
      : `${requestType}://${process.env.EXCEL_API_HOST}:${
          process.env.EXCEL_API_PORT
        }`) + `${process.env.EXCEL_API_PATH}/persons?ids=${ids.join(",")}`;

  return window.open(url);
};
