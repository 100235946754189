import {
  createAsyncAction,
  createStandardAction,
  ActionType
} from "typesafe-actions";

export const getProjectsForUser = createAsyncAction(
  "projects/request",
  "projects/success",
  "projects/failure"
)<string, string[], Error>();

export const selectProjectId = createStandardAction("projects/selectId")<
  string
>();

export type GetProjectsAction = ActionType<typeof getProjectsForUser>;
export type SelectProjectAction = ActionType<typeof selectProjectId>;

export type ProjectsAction = GetProjectsAction | SelectProjectAction;
