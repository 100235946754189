import { styled } from "@h1eng/ui-components";

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #ccc;
`;

export const ListActionButton = styled.div`
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`;
