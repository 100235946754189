import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import { RootState } from "../../reducers";
import { initialState as SearchInitialState } from "../../reducers/search";
import { debounceTime, switchMap, takeUntil, skip } from "rxjs/operators";
import { of } from "rxjs";
import {
  searchElastic,
  setSortBy,
  setQuery,
  setSpecialtyValues,
  setCountryValues,
  setStateValues,
  setInstitutionValues,
  setPublicationMinCount,
  setPublicationJournalValues,
  setPublicationTypeValues,
  setTrialMinCount,
  setTrialStatusValues,
  setTrialPhaseValues,
  setTrialStudyTypeValues,
  setTrialFunderTypeValues,
  setTrialSponsorValues,
  setCongressMinCount,
  setCongressNameValues,
  setCongressTypeValues,
  setGrantMinAmount,
  setGrantFunderValues,
  setPaymentMinAmount,
  setPaymentCompanyValues,
  setPaymentDrugOrDeviceValues,
  setPaymentFundingTypeValues,
  searchFromStoreState,
  clearSearchFilters
} from "../../actions";
import { FilterSearchInterface } from "@h1eng/interfaces";
import { DEBOUNCE_TIME } from ".";

const searchOnUpdatedFiltersFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(
      setSortBy,
      setQuery,
      setSpecialtyValues,
      setCountryValues,
      setStateValues,
      setInstitutionValues,
      setPublicationJournalValues,
      setPublicationTypeValues,
      setTrialStatusValues,
      setTrialPhaseValues,
      setTrialStudyTypeValues,
      setTrialFunderTypeValues,
      setTrialSponsorValues,
      setCongressNameValues,
      setCongressTypeValues,
      setGrantFunderValues,
      setPaymentCompanyValues,
      setPaymentDrugOrDeviceValues,
      setPaymentFundingTypeValues
    ),
    switchMap(() =>
      state$.value.searchFilters.query.length > 0
        ? of(searchElastic.request(buildSearchElasticArgs(state$.value)))
        : of(searchElastic.success(getElasticSuccessInitialState()))
    )
  );

const searchWithStoreStateValuesFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(searchFromStoreState),
    switchMap(() =>
      of(searchElastic.request(buildSearchElasticArgs(state$.value)))
    )
  );

const debounceNumberValuesAndSearchFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(
      setPublicationMinCount,
      setTrialMinCount,
      setCongressMinCount,
      setGrantMinAmount,
      setPaymentMinAmount
    ),
    debounceTime(DEBOUNCE_TIME),
    switchMap(() =>
      state$.value.searchFilters.query.length > 0
        ? of(searchElastic.request(buildSearchElasticArgs(state$.value))).pipe(
            takeUntil(action$.pipe(skip(1)))
          )
        : of(searchElastic.success(getElasticSuccessInitialState()))
    )
  );

const handleClearSearchFiltersFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(clearSearchFilters),
    switchMap(() =>
      state$.value.searchFilters.query !== ""
        ? of(searchFromStoreState())
        : action$
    )
  );

function getElasticSuccessInitialState() {
  return {
    totalHits: SearchInitialState.totalHits,
    pageNum: SearchInitialState.pageNum,
    pageSize: SearchInitialState.pageSize,
    scoredDocuments: { ...SearchInitialState.scoredDocuments }
  };
}

function buildSearchElasticArgs(state: RootState): FilterSearchInterface {
  return {
    userId: state.user!.user!.id,
    query: state.searchFilters.query.split(",").filter(i => i !== ""),
    projectId: state.projects.selectedId as string,
    filters: state.searchFilters,
    sortBy: state.searchFilters.sortBy
  };
}

export default [
  searchOnUpdatedFiltersFlow,
  debounceNumberValuesAndSearchFlow,
  searchWithStoreStateValuesFlow,
  handleClearSearchFiltersFlow
];
