/* tslint:disable:react-unused-props-and-state */
import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { ordinalNumber } from "@h1eng/format-util";

interface Props {
  label: string;
  total: number;
  value: number;
  statDescriptor: string;
  percentile: number;
  formatter?: (num: number) => string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
`;

const StatsWrapper = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 6px 10px;
`;

const StatValue = styled.span`
  color: #333333;
  font-family: Montserrat;
  font-size: 12px;
  line-height: 15px;
`;

const StatLabel = styled(StatValue)`
  font-weight: 600;
`;

export const TooltipContent: React.SFC<Props> = ({
  label,
  total,
  value,
  formatter,
  statDescriptor
  // percentile
}) => {
  return (
    <Root>
      <Title>{label}</Title>
      <StatsWrapper>
        <StatLabel>{(!!formatter && formatter(value)) || value}</StatLabel>
        <StatValue>This researcher's {statDescriptor}</StatValue>
        <StatLabel>{(!!formatter && formatter(total)) || total}</StatLabel>
        <StatValue>Top researcher's {statDescriptor}</StatValue>
        {/* <StatLabel>{ordinalNumber(percentile)}</StatLabel>
        <StatValue>percentile</StatValue> */}
      </StatsWrapper>
    </Root>
  );
};
