import { ReactElement } from "react";
import { createStandardAction, ActionType } from "typesafe-actions";
import { GrowlerTypes } from "../../components/growler/GrowlerTypes";
export interface GrowlerInterface {
  title: string;
  description: string;
  titleIcon: TitleIcon;
  growler: GrowlerTypes;
}

export type TitleIcon = "check" | "warning" | ReactElement<any>;

export const createGrowler = createStandardAction("create/Growler")<
  GrowlerInterface
>();

export const findAndClose = createStandardAction("findAndRemove/Growler")<
  GrowlerInterface
>();
export const removeGrowler = createStandardAction("remove/Growler")<number>();

export type GrowlerAction =
  | ActionType<typeof createGrowler>
  | ActionType<typeof removeGrowler>
  | ActionType<typeof findAndClose>;
