import {
  createAsyncAction,
  ActionType,
  createStandardAction
} from "typesafe-actions";
import { UserVO } from "../../models/UserVO";

export const currentUserGet = createAsyncAction(
  "user.request",
  "user.success",
  "user.failure"
)<string, UserVO | null, Error>();

export const setToken = createStandardAction("setToken")<string>();
export const logout = createStandardAction("logout")<void>();

export type GetCurrentUserAction = ActionType<typeof currentUserGet>;
export type SetTokenAction = ActionType<typeof setToken>;
export type logoutAction = ActionType<typeof logout>;

export type UserAction = GetCurrentUserAction | SetTokenAction | logoutAction;
