import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { pageCount, getItemRangeInPage } from "@h1eng/pagination";
import { PaginationRoot } from "./PaginationRoot";
import { PaginationLink } from "./PaginationLink";

export interface PaginationProps {
  pageNum: number;
  pageSize: number;
  total: number;
  loadPage: (pageNum: number) => void;
  scrollOnPageChange?: boolean;
}

const RangeCenter = styled.div`
  text-align: center;
  color: #333333;
  font-family: Montserrat;
  font-weight: 600;
`;

export class Pagination extends React.Component<PaginationProps> {
  static defaultProps = {
    scrollOnPageChange: true
  };

  handleLinkClick = (pageNum: number) => (
    e: React.SyntheticEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();

    if (this.props.scrollOnPageChange) window.scrollTo(0, 0);

    this.props.loadPage(pageNum);
  };

  get pageCount() {
    return pageCount({
      count: this.props.total,
      pageSize: this.props.pageSize
    });
  }

  get prevRange() {
    return getItemRangeInPage({
      pageNum: this.props.pageNum - 1,
      perPage: this.props.pageSize,
      count: this.props.total
    });
  }

  get currentRange() {
    return getItemRangeInPage({
      pageNum: this.props.pageNum,
      perPage: this.props.pageSize,
      count: this.props.total
    });
  }

  get nextRange() {
    return getItemRangeInPage({
      pageNum: this.props.pageNum + 1,
      perPage: this.props.pageSize,
      count: this.props.total
    });
  }

  render() {
    const { pageNum } = this.props;
    const { prevRange, currentRange, nextRange } = this;

    return (
      <PaginationRoot>
        <div style={{ marginRight: "auto" }}>
          {pageNum > 0 && (
            <>
              <PaginationLink
                aria-label={`Goto Start`}
                style={{ marginRight: 15 }}
                onClick={this.handleLinkClick(0)}
              >
                {"<<"}
              </PaginationLink>
              <PaginationLink
                aria-label={`Goto Page ${pageNum}`}
                onClick={this.handleLinkClick(pageNum - 1)}
              >
                {`< ${prevRange[0]} - ${prevRange[1]}`}
              </PaginationLink>
            </>
          )}
        </div>
        <RangeCenter>{`${currentRange[0]} - ${currentRange[1]}`}</RangeCenter>
        <div style={{ marginLeft: "auto" }}>
          {pageNum + 1 < this.pageCount && (
            <PaginationLink
              aria-label={`Goto Page ${pageNum + 2}`}
              onClick={this.handleLinkClick(pageNum + 1)}
            >
              {`${nextRange[0]} - ${nextRange[1]} >`}
            </PaginationLink>
          )}
        </div>
      </PaginationRoot>
    );
  }
}
