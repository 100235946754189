import { getType } from "typesafe-actions";
import {
  searchCollaborators,
  CollaboratorsAction,
  setPage,
  getCollaboratorsDetials,
  getLiteProfileAction,
  setPersonId
} from "../actions/collaborators";
import {
  CollaboratosrMeta,
  CollaboratorsDetail,
  LiteProfile
} from "@h1eng/interfaces";
export interface CollaboratorState
  extends CollaboratosrMeta,
    CollaboratorsDetail {
  loading: boolean;
  page: number;
  size: number;
  liteProfiles: LiteProfile[];
  loadingLiteProfile: boolean;
  loadingDetails: boolean;
  personId: string;
}

const initialState = {
  collaborations: [],
  collaborators: [],
  personCollaboratorsCountMap: {},
  loading: true,
  page: 0,
  size: 15,
  liteProfiles: [],
  sortedIds: [],
  loadingLiteProfile: true,
  loadingDetails: true,
  personId: ""
} as CollaboratorState;

export const CollaboratorReducer = (
  state: CollaboratorState = initialState,
  action: CollaboratorsAction
): CollaboratorState => {
  switch (action.type) {
    case getType(searchCollaborators.success): {
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    }
    case getType(searchCollaborators.request): {
      return {
        ...state,
        page: 0,
        loading: true
      };
    }
    case getType(getLiteProfileAction.success): {
      return {
        ...state,
        loadingLiteProfile: false,
        liteProfiles: action.payload.payload
      };
    }
    case getType(getLiteProfileAction.request): {
      return {
        ...state,
        loadingLiteProfile: true
      };
    }
    case getType(getCollaboratorsDetials.request): {
      return {
        ...state,
        loadingDetails: true
      };
    }
    case getType(getCollaboratorsDetials.success): {
      return {
        ...state,
        ...action.payload,
        loadingDetails: false
      };
    }
    case getType(setPage): {
      return {
        ...state,
        page: action.payload
      };
    }
    case getType(setPersonId): {
      return {
        ...state,
        personId: action.payload
      };
    }

    default:
      return state;
  }
};
