import * as React from "react";
import { ScoredResultCardData } from "@h1eng/interfaces";
import { styled } from "@h1eng/ui-components";
import { Link } from "react-router-dom";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const TitleLink = styled(Link)`
  color: #102c3c;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  margin-bottom: 6px;
  &:hover {
    color: #0aaacd;
  }
`;

export const PersonDataText = styled.div`
  color: #435d6b;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    margin-right: 24px;
    max-width: 60%;
  }
`;

export const InfoColumnLabel = styled.div`
  color: #435d6b;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 6px;
`;

export const Text = styled.span`
  color: #102c3c;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

export const BoldText = styled(Text)`
  font-weight: bold;
`;

interface Props {
  person: ScoredResultCardData;
  listButton: React.ReactElement<any>;
}

export function getTotalWorks({
  countClinicalTrials,
  countPublications,
  grants,
  congresses
}: {
  countClinicalTrials: number;
  countPublications: number;
  grants: number;
  congresses: number;
}): number {
  return countClinicalTrials + countPublications + grants + congresses;
}

export class ResultCardContent extends React.Component<Props> {
  get works() {
    const { person } = this.props;
    const { scoredDocuments } = person;

    let searched =
      scoredDocuments.publications.value +
      scoredDocuments.congresses.value +
      scoredDocuments.trials.value;

    if (scoredDocuments.grantsCount) {
      searched += scoredDocuments.grantsCount.value;
    }

    const total = getTotalWorks(person);

    return { total, searched };
  }

  render() {
    const { person, listButton } = this.props;
    const { searched, total } = this.works;

    return (
      <Root>
        <TitleLink to={`/curie/person/${person.personId}`}>{`${
          person.firstName
        } ${(!!person.middleName && `${person.middleName} `) || ""}${
          person.lastName
        }`}</TitleLink>
        <PersonDataText>{person.title}</PersonDataText>
        <PersonDataText>{person.organization}</PersonDataText>
        {person.location.trim() !== "," && (
          <PersonDataText>{person.location}</PersonDataText>
        )}
        <InfoWrapper>
          {person.specialty.length > 0 && (
            <InfoColumn>
              <InfoColumnLabel>Specialty</InfoColumnLabel>
              <BoldText>{person.specialty.join(", ")}</BoldText>
            </InfoColumn>
          )}
          <InfoColumn>
            <InfoColumnLabel>Works</InfoColumnLabel>
            <div>
              <BoldText>{searched}/</BoldText>
              <Text>{total}</Text>
            </div>
          </InfoColumn>
        </InfoWrapper>
        <div>{listButton}</div>
      </Root>
    );
  }
}
