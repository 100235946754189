import { connectProps } from "../MinCount";
import { RootState } from "../../../../../store/reducers";
import { setPublicationMinCount } from "../../../../../store/actions";

const mapStateToProps = (state: RootState) => ({
  minCount: state.searchFilters.publications.minCount.value
});

const mapDispatchToProps = {
  setMinCount: setPublicationMinCount
};

export const PublicationsMinCount = connectProps(
  mapStateToProps,
  mapDispatchToProps
);
