import * as React from "react";
import { Dropdown, DropdownOptionInterface } from "./Dropdown";
import { connect } from "react-redux";
import {
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText
} from "../../../store/selectors";
import { setDocumentSearchBarFilterDate } from "../../../store/actions";
import { styled } from "@h1eng/ui-components";

const Root = styled.div`
  display: flex;
  margin-right: auto;
  margin-left: 25px;
`;

interface DispatchProps {
  setDocumentSearchBarFilterDate: (
    t: { filterDate: number; displayFilterDateString: string }
  ) => void;
  filterDate: number;
  filterDateText: string;
}

class DateRangeDropdownClass extends React.Component<DispatchProps> {
  static options: DropdownOptionInterface[] = [
    {
      value: 5,
      text: "Last 5 years"
    },
    {
      value: 10,
      text: "Last 10 years"
    },
    {
      value: 100,
      text: "All Time"
    }
  ];

  onOptionSelected = (value: number, text: string) => {
    const filterDate = new Date().setFullYear(new Date().getFullYear() - value);

    this.props.setDocumentSearchBarFilterDate({
      filterDate,
      displayFilterDateString: text
    });
  };

  render() {
    return (
      <Root>
        <Dropdown
          label="Date range:"
          selected={this.props.filterDateText}
          options={DateRangeDropdownClass.options}
          onSelect={this.onOptionSelected}
        />
      </Root>
    );
  }
}

const mapStateToProps = (state: any) => ({
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state)
});

const mapDispatchToProps = {
  setDocumentSearchBarFilterDate
};

export const DateRangeDropdown = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(DateRangeDropdownClass as any);
