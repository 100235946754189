import * as React from "react";
import { connect } from "react-redux";
import { getTrialsFilters } from "../../../../../store/selectors";
import {
  setTrialStatusValues,
  setTrialStatusQuery
} from "../../../../../store/actions";
import { RootState } from "../../../../../store/reducers";
import { FilterAutosuggest as Autosuggest } from "../../FilterAutosuggest";

interface MappedStateProps {
  options: string[];
  values: string[];
  inputValue: string;
}

interface DispatchProps {
  setValues: (values: string[]) => void;
  handleInputChange: (inputVal: string) => void;
}

type Props = MappedStateProps & DispatchProps;

class StatusFiltersComponent extends React.Component<Props> {
  render() {
    const { options, values, setValues } = this.props;

    return (
      <Autosuggest
        values={values}
        setValues={setValues}
        suggestions={options}
        inputLabel="Status"
        inputValue={this.props.inputValue}
        setInputValue={this.props.handleInputChange}
      />
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => {
  const { status } = getTrialsFilters(state);

  return {
    options: status.options,
    values: status.values,
    inputValue: status.query
  };
};

const mapDispatchToProps: DispatchProps = {
  setValues: setTrialStatusValues,
  handleInputChange: setTrialStatusQuery
};

export const StatusFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusFiltersComponent);
