export const colors = {
  primary: "#0aaacd",
  secondary: "#ccf4e4",
  tertiary: "#7061ff",
  primaryBackground: "#060464",
  neutralBackground: "#f6f6f6",
  physician: "#060464",
  researcher: "#ffc4b7",
  publication: "#80d0e1",
  trial: "#aeffde"
};

export const styleUtils = {
  stroke: "rgba(0, 0, 0, 0.12)",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  shadow: "0 3px 5px 0 rgba(0, 0, 0, 0.52)"
};
