import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { getSelectedProject } from "../../../store/selectors";
import { RootState } from "../../../store/reducers";
import { getInitialFilterOptions } from "../../../store/actions";
import { TextFilterWrapper } from "./components/TextFilterWrapper";
import { ClearAllButton } from "./components/ClearAllButton";
import { SpecialtyFilters } from "./components/SpecialtyFilters";
// import { CountryFilters } from "./components/CountryFilters";
import { StateFilters } from "./components/StateFilters";
import { InstitutionFilters } from "./components/InstitutionFilters";
import { AdvancedFilters } from "./AdvancedFilters";

export const FILTER_CARD_WIDTH = 256;

export const FILTER_CARD_RIGHT_MARGIN = 20;

export const FilterCard = styled.div`
  width: ${FILTER_CARD_WIDTH}px;
  margin-right: ${FILTER_CARD_RIGHT_MARGIN}px;
`;

interface MappedStateProps {
  projectId: string | null;
}
interface DispatchProps {
  fetchInitialOptions: () => void;
}

const Root = styled.div`
  padding-top: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border: 2px solid #e8e6e1;
  border-radius: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 40px;
`;

const HeaderRoot = styled.div`
  margin: 0px 18px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export class FiltersComponent extends React.Component<
  MappedStateProps & DispatchProps
> {
  componentDidMount() {
    this.props.fetchInitialOptions();
  }

  render() {
    return (
      <Root>
        <HeaderRoot>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ marginRight: 10 }}
            >
              Filters
            </Typography>
          </div>
          <ClearAllButton />
        </HeaderRoot>
        <TextFilterWrapper>
          <SpecialtyFilters />
        </TextFilterWrapper>
        {/* <TextFilterWrapper>
          <CountryFilters />
        </TextFilterWrapper> */}
        <TextFilterWrapper>
          <StateFilters />
        </TextFilterWrapper>
        <TextFilterWrapper>
          <InstitutionFilters />
        </TextFilterWrapper>
        <AdvancedFilters />
      </Root>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  projectId: getSelectedProject(state)
});

const mapDispatchToProps: DispatchProps = {
  fetchInitialOptions: getInitialFilterOptions.request
};

export const Filters = connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersComponent);
