import * as React from "react";
import { styled, B2, B1 } from "@h1eng/ui-components";
import { HoverTooltip } from "../../Tooltip";
import {
  TitlesAndAssociationInterface,
  AffiliationClasses
} from "@h1eng/interfaces";
import * as _ from "lodash";

const FLEX_3 = styled.div`
  display: grid;
  grid-template-rows: 1fr
  grid-template-columns: 1fr 1fr 1fr
  grid-gap: 10px;
  grid-auto-flow: dense;
  list-style: none;
  margin: 1em auto;
  padding: 0;
  background-color: #fff;
  color: #444;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const VERTICAL = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;
const GIRDDATA = styled.div`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
`;

const AffiliationsTitle = styled.div`
  color: #737373;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
`;

const AddressRoot = styled.div`
  font-family: Montserrat;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
`;

export class AffiliationsRender extends React.Component<
  TitlesAndAssociationInterface
> {
  get isEducation(): boolean {
    const { props } = this;
    return !!(
      props.affiliationType === AffiliationClasses.EDUCATION &&
      props.degree &&
      props.discipline
    );
  }

  get cityState() {
    const { props } = this;
    if (!props.location) return null;

    return [props.location.city, props.location.state]
      .filter((x: any) => x)
      .join(", ");
  }

  get dates() {
    const { startDate, endDate } = this.props;
    if (!startDate || !endDate) return null;

    return (
      <B2>
        {`${new Date(startDate || "").getFullYear()} - ${new Date(
          endDate || ""
        ).getFullYear()}`}
      </B2>
    );
  }

  get formattedAddress() {
    if (!this.props.location) return null;
    const { street, address, address_1, address_2, zip } = this.props.location;

    const parts = [
      street,
      address,
      address_1,
      address_2,
      this.cityState ? `${this.cityState} ${zip}` : null
    ].filter(i => i);

    if (!parts.length) return;

    return (
      <AddressRoot>
        {parts.map((part, i) => (
          <div key={`part-${part}-${i}`}>{part}</div>
        ))}
      </AddressRoot>
    );
  }

  render() {
    const { props, formattedAddress } = this;

    return (
      <GIRDDATA>
        <HoverTooltip
          style={{ left: 40, bottom: "100%" }}
          content={formattedAddress}
        >
          <B1>{props.organizationName}</B1>
          {this.isEducation && (
            <B2>
              {props.degree}, {props.discipline}
            </B2>
          )}
          {props.titles && <B2>{props.titles}</B2>}
          <B2>{this.cityState}</B2>
          {this.dates}
          <br />
        </HoverTooltip>
      </GIRDDATA>
    );
  }
}

export const ProfileAffiliationsSorted = (
  rows: number,
  affiliations: TitlesAndAssociationInterface[]
) => {
  // Temporary patch until data is fixed
  const aff = [];
  const map = new Map();
  for (const item of affiliations) {
    if (!map.has(item.organizationName)) {
      map.set(item.organizationName, true);
      aff.push(item);
    }
  }

  const result = _.chain(aff)
    .groupBy("affiliationType")
    .value();
  const keys = Object.keys(result).sort((a: string, b: string) => {
    return result[a].length - result[b].length;
  });
  const FLEX = FLEX_3;
  const affliantions = keys.map((e: string, i) => {
    return (
      <VERTICAL key={`affiliation-${e}-${i}`}>
        <AffiliationsTitle>{e}</AffiliationsTitle>
        {result[e]
          .sort(
            (
              a: TitlesAndAssociationInterface,
              b: TitlesAndAssociationInterface
            ) => {
              if (
                a.startDate &&
                b.endDate &&
                typeof a.startDate === "string" &&
                typeof b.startDate === "string"
              ) {
                return (
                  new Date(a.startDate).getTime() -
                  new Date(b.startDate).getTime()
                );
              }
              return 0;
            }
          )
          .map((t: TitlesAndAssociationInterface, idx) => {
            return (
              <AffiliationsRender
                key={`t-${t.organizationName}-${idx}`}
                {...t}
              />
            );
          })}
      </VERTICAL>
    );
  });

  return <FLEX> {affliantions}</FLEX>;
};
