import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  requestInstitutionOptions,
  setInstitutionQuery,
  resetInitialOptions,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  map,
  filter,
  catchError
} from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { searchOptions } from "../../../apis/searchFilters";
import { DEBOUNCE_TIME } from ".";

const setInstitutionQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setInstitutionQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestInstitutionOptions.request(
              state$.value.searchFilters.institution
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getInstitutionOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestInstitutionOptions.request)),
    switchMap(({ payload }) =>
      from(
        searchOptions(
          payload,
          state$.value.searchFilters.initialOptions.institution
        )
      ).pipe(
        map(requestInstitutionOptions.success),
        catchError(
          pipe(
            requestInstitutionOptions.failure,
            of
          )
        )
      )
    )
  );

export default [setInstitutionQueryFlow, getInstitutionOptionsFlow];
