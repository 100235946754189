/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { H5, styled, B1, B2 } from "@h1eng/ui-components";
import { formatStats } from "@h1eng/format-util";
import * as _ from "lodash";
import { withRouter, RouterProps } from "react-router";
import {
  GenericSearchEnum,
  PersonOverviewInterface,
  GenericSearchResultInterface,
  ClinicalTrialInterface,
  CombinedDocumentsType,
  OpenPaymentInterface,
  CongressInterface,
  GrantInterface,
  PublicationInterface
} from "@h1eng/interfaces";
import { setDocumentSearchBarQuery } from "../../../store/actions";
import { connect } from "react-redux";
import { ProfileStatDetail } from "./ProfileStatDetail";

import {
  getDocumentSearch,
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText
} from "../../../store/selectors";
import { switchDocumentDateSort } from "./MultiDocRender";
import { SubViews, pathForSubview } from "./Profile";

const PersonOverviewCardContainer = styled.div`
  grid-template-columns: auto auto auto;
  padding: 20px;
  z-index: 20;
  display: grid;
`;

const Details = styled.div`
  word-wrap: break-word;
  margin-bottom: 20px;
`;

const Detail = styled.div`
  display: flex;
  width: 100%;
  > * {
    padding-right: 10px;
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 5px;
`;

const StyledH5 = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  color: #737373;
  &:hover {
    cursor: pointer;
    color: #0aaacd;
  }
`;

export const Bold14 = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
`;

const DetialText = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  color: #333333;
`;

interface DetailsStatsInterface {
  kind: GenericSearchEnum;
  title: string;
  count: number | string | null;
  formatStats: boolean;
  asMoney?: boolean;
  key: string;
  path: string;
}

interface OverviewSectionDispatchProps {
  documentSearch: GenericSearchResultInterface[];
  searchBarState: { query: string[] };
  filterDate: number;
  filterText: string;
  docs: CombinedDocumentsType[];
}

type Props = PersonOverviewInterface &
  OverviewSectionDispatchProps &
  RouterProps;

export const findMostCommon = (all: string[]) => {
  const words = all.filter(x => x);
  if (words.length === 0) {
    return "";
  }

  const mostCommon = _.reduce(_.toPairs(_.countBy(words)), (prev, curr) =>
    prev[1] > curr[1] ? prev : curr
  );

  return mostCommon![0];
};

export function getTrialOverview(trials: ClinicalTrialInterface[]) {
  const clincalTrialsCompletedCount = trials.filter(e => e.completed).length;
  const clincalTrialsInProgressCount = trials.filter(e => e.inprogress).length;
  const clincalTrialsTerminatedCount = trials.filter(e => e.completed).length;

  return {
    clincalTrialsCount: trials.length,
    clincalTrialsCompletedCount,
    clincalTrialsInProgressCount,
    clincalTrialsTerminatedCount,
    topCondition: findMostCommon(
      _.flatten(trials.map(t => t.conditions!.map(c => c)))
    ),
    topIntervention: findMostCommon(_.flatten(trials.map(t => t.intervention!)))
  };
}

class ProfileStatsClass extends React.Component<
  Props,
  { computedDiff: PersonOverviewInterface; filtered: PersonOverviewInterface }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      computedDiff: {} as PersonOverviewInterface,
      filtered: {
        id: props.id
      } as PersonOverviewInterface
    };
  }

  componentDidMount() {
    const docs = this.getDateFilterDoc();
    this.setState({
      computedDiff: {
        id: this.props.id,
        ...this.generateCongress(docs, true),
        ...this.generateClinicalTrialsDetailsOutOf(docs, true),
        ...this.generateClinicalPaymets(docs, true),
        ...this.generatePublication(docs, true),
        ...this.generateGrants(docs, true)
      },
      filtered: {
        id: this.props.id,
        ...this.generateClinicalTrialsDetailsOutOf(docs, false),
        ...this.generateClinicalPaymets(docs, false),
        ...this.generatePublication(docs, false),
        ...this.generateCongress(docs, false),
        ...this.generateGrants(docs, false)
      } as PersonOverviewInterface
    }),
      /* tslint:disable:no-unused-expression
       * @TODO: Should this be fixed or removed?
       */
      () => this.forceUpdate();
    // tslint:enable:no-unused-expression
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.searchBarState.query !== prevProps.searchBarState.query ||
      this.props.documentSearch !== prevProps.documentSearch ||
      this.props.filterDate !== prevProps.filterDate
    ) {
      const docs = this.getDateFilterDoc();
      this.setState({
        computedDiff: {
          id: this.props.id,
          ...this.generateCongress(docs, true),
          ...this.generateClinicalTrialsDetailsOutOf(docs, true),
          ...this.generateClinicalPaymets(docs, true),
          ...this.generatePublication(docs, true),
          ...this.generateGrants(docs, true)
        },
        filtered: {
          id: this.props.id,
          ...this.generateClinicalTrialsDetailsOutOf(docs, false),
          ...this.generateClinicalPaymets(docs, false),
          ...this.generatePublication(docs, false),
          ...this.generateCongress(docs, false),
          ...this.generateGrants(docs, false)
        } as PersonOverviewInterface
      }),
        /* tslint:disable:no-unused-expression
         * @TODO: Should this be fixed or removed?
         */
        () => this.forceUpdate();
      // tslint:enable:no-unused-expression
    }
  }

  generateClinicalTrialsDetailsOutOf = (docs: any, filterSearch: boolean) => {
    let data = {};
    if (docs) {
      data = getTrialOverview(
        docs
          .filter(
            (e: any) =>
              e.kind === GenericSearchEnum.CLINICALTRIAL &&
              (!filterSearch ||
                this.props.documentSearch.map(i => i.id).includes(e.id))
          )
          .map((e: any) => e as ClinicalTrialInterface)
      );
      if (data) {
        return data;
      }
    }
    return data;
  };

  getTotalMoneyRecived = (payments: OpenPaymentInterface[]) => {
    return payments.reduce((pv: number, cv: OpenPaymentInterface) => {
      if (cv.amount) pv += cv.amount;
      return pv;
    }, 0);
  };

  getAvgMoneyPerYear = (payments: OpenPaymentInterface[]) => {
    const years = [] as number[];
    const totalMoney = 0;
    payments.forEach(cv => {
      years.push(new Date(cv.date).getFullYear());
      totalMoney += Number.parseFloat(cv.amount.toString());
    });
    const max = Math.max(...years);
    const min = Math.min(...years);
    const size = max - min;

    if (size === 0) {
      return 0;
    }

    return totalMoney / size;
  };

  getAvgGrantsMoneyPerYear = (grants: GrantInterface[]) => {
    const years = [] as number[];
    const totalMoney = 0;
    const total = grants.forEach(cv => {
      totalMoney += Number.parseFloat(cv.directFunds.toString());
      totalMoney += Number.parseFloat(cv.indirectFunds.toString());
      years.push(new Date(cv.fundingYear * 1000).getFullYear());
    });

    const max = Math.max(...years);
    const min = Math.min(...years);
    const size = max - min;
    if (size === 0) {
      return 0;
    }

    return totalMoney / size;
  };

  getConAvgMoneyPerYear = (congress: CongressInterface[]) => {
    const years = new Set<number>();
    congress.forEach(cv => {
      years.add(new Date(cv.presentedDate).getFullYear());
    });
    return congress.length / years.size;
  };

  generateClinicalPaymets = (
    docs: CombinedDocumentsType[],
    filterSearch: boolean
  ) => {
    const data = {};
    if (docs) {
      const payments = docs
        .filter(
          e =>
            e.kind === GenericSearchEnum.PAYMENTS &&
            (!filterSearch ||
              this.props.documentSearch.map(i => i.id).includes(e.id))
        )
        .map(e => e as OpenPaymentInterface);

      const d = {
        totalMoneyReceived: this.getTotalMoneyRecived(payments) || 0,
        avgMoneyPerYear: this.getAvgMoneyPerYear(payments) || 0,
        topCompany: findMostCommon(
          (payments || []).map(payment => payment.payerName)
        ),
        topDrug: findMostCommon(
          (payments || []).map(payment => payment.drugOrDeviceName)
        )
      };

      if (d) {
        return d;
      }
    }
    return data;
  };

  getDateFilterDoc() {
    if (!this.props.filterDate || this.props.filterText === "All time") {
      return this.props.docs;
    }

    const filterDate = new Date(this.props.filterDate);

    return this.props.docs.filter(e => {
      const date = switchDocumentDateSort(e);
      if (!date) {
        return false;
      }
      const docDate = new Date(date);
      return docDate.getTime() - filterDate.getTime() > 0;
    });
  }

  generateGrants = (docs: CombinedDocumentsType[], filterSearch: boolean) => {
    const data = {};

    if (docs) {
      const grants = docs
        .filter(
          e =>
            e.kind === GenericSearchEnum.GRANTS &&
            (!filterSearch ||
              this.props.documentSearch.map(i => i.id).includes(e.id))
        )
        .map(e => e as GrantInterface);

      const d = {
        grantsTotalFunding: grants.reduce((pv, cv) => {
          return pv + Number(cv.indirectFunds) + Number(cv.directFunds);
        }, 0),
        grantsReceived: grants.length || 0,
        grantsAvgFundingPerYear: this.getAvgGrantsMoneyPerYear(grants) || 0
      };

      if (d) {
        return d;
      }
    }
    return data;
  };
  generatePublication = (
    docs: CombinedDocumentsType[],
    filterSearch: boolean
  ) => {
    const data = {};

    if (docs) {
      const pubs = docs
        .filter(
          e =>
            e.kind === GenericSearchEnum.PUBLICATION &&
            (!filterSearch ||
              this.props.documentSearch.map(i => i.id).includes(e.id))
        )
        .map(e => e as PublicationInterface);
      return {
        publications: pubs.length,
        citations: pubs.reduce((pv, cv) => {
          if (cv.citationsCount && cv.citationsCount.toString()) {
            pv += Number.parseFloat(cv.citationsCount!.toString() || "0") || 0;
          }
          return pv;
        }, 0)
      };
    }

    return data;
  };

  generateCongress = (docs: CombinedDocumentsType[], filterSearch: boolean) => {
    const data = {};
    if (docs) {
      const con = docs
        .filter(
          e =>
            e.kind === GenericSearchEnum.CONGRESS &&
            (!filterSearch ||
              this.props.documentSearch.map(i => i.id).includes(e.id))
        )
        .map(e => e as CongressInterface);
      if (con.length > 0) {
        return {
          conferences: con.length,
          congressAvgPerYear: this.getConAvgMoneyPerYear(con)
        };
      }
      return {
        conferences: 0,
        congressAvgPerYear: 0
      };
    }

    return data;
  };

  detailsStatsWithPath = (path?: string) => (data: DetailsStatsInterface) =>
    this.detailsStatsHelper(data, path);

  detailsStatsHelper = (data: DetailsStatsInterface, path?: string) => {
    const stat =
      data.formatStats && typeof data.count === "number"
        ? formatStats(data.count)
        : data.count;
    if (
      stat === 0 ||
      stat === "0" ||
      stat === "0.0" ||
      stat === "0.00" ||
      !stat
    ) {
      return null;
    }

    const handleClick = path ? () => this.props.history.push(path) : undefined;

    if (data.title === "h-index" && data.count) {
      return (
        <Detail>
          <Bold14>{data.title}</Bold14>
          <B2 deEmphasized>{Number.parseInt(data.count.toString(), 10)}</B2>
        </Detail>
      );
    }
    /* tslint:disable:no-unused-expression
     * @TODO: Should this be fixed or removed?
     */
    //@ts-ignore
    this.state.computedDiff[data.key];
    // tslint:enable:no-unused-expression
    //@ts-ignore
    const statCompare = data.formatStats
      ? formatStats(this.state.computedDiff[data.key])
      : this.state.computedDiff[data.key];
    //@ts-ignore
    const fraction = data.asMoney ? (
      <Bold14>${statCompare}&nbsp;/</Bold14>
    ) : (
      <Bold14>{statCompare}&nbsp;/</Bold14>
    );

    const detail =
      this.props.searchBarState.query.length === 0 ? (
        <ProfileStatDetail denominator={stat} onClick={handleClick} />
      ) : (
        <ProfileStatDetail
          denominator={stat}
          onClick={handleClick}
          numerator={statCompare}
        />
      );

    return (
      <Detail>
        <Bold14>{data.title}</Bold14>
        {detail}
      </Detail>
    );
  };

  renderDetails = (set: DetailsStatsInterface[]) =>
    set.some(data => {
      const stat =
        data.formatStats && typeof data.count === "number"
          ? formatStats(data.count)
          : data.count;
      if (
        stat === 0 ||
        stat === "0" ||
        stat === "0.0" ||
        stat === "0.00" ||
        !stat
      ) {
        return false;
      }
      return true;
    });

  render() {
    const { props } = this;
    const clinicalTrialsDetails = [
      {
        kind: GenericSearchEnum.CLINICALTRIAL,
        title: "Total",
        count: this.state.filtered.clincalTrialsCount,
        formatStats: false,
        key: "clincalTrialsCount",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.CLINICAL
        )}`
      },

      {
        kind: GenericSearchEnum.CLINICALTRIAL,
        title: "Completed",
        count: this.state.filtered.clincalTrialsCompletedCount,
        formatStats: false,
        key: "clincalTrialsCompletedCount",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.CLINICAL
        )}`
      },
      {
        kind: GenericSearchEnum.CLINICALTRIAL,
        title: "Terminated",
        count: this.state.filtered.clincalTrialsTerminatedCount,
        formatStats: false,
        key: "clincalTrialsTerminatedCount",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.CLINICAL
        )}`
      },
      {
        kind: GenericSearchEnum.CLINICALTRIAL,
        title: "In-progress",
        count: this.state.filtered.clincalTrialsInProgressCount,
        formatStats: false,
        key: "clincalTrialsInProgressCount",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.CLINICAL
        )}`
      }
    ] as DetailsStatsInterface[];

    const openPaymentsDetails = [
      {
        kind: GenericSearchEnum.PAYMENTS,
        title: "Total money received",
        count: this.state.filtered.totalMoneyReceived,
        formatStats: true,
        asMoney: true,
        key: "totalMoneyReceived",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.PAYMENTS
        )}`
      },
      {
        kind: GenericSearchEnum.PAYMENTS,
        title: "Avg money per year",
        count: this.state.filtered.avgMoneyPerYear,
        formatStats: true,
        asMoney: true,
        key: "avgMoneyPerYear",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.PAYMENTS
        )}`
      }
    ] as DetailsStatsInterface[];

    const congressDetails = [
      {
        kind: GenericSearchEnum.CONGRESS,
        title: "Total",
        count: this.state.filtered.conferences,
        formatStats: false,
        key: "conferences",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.CONGRESSES
        )}`
      },
      {
        kind: GenericSearchEnum.CONGRESS,
        title: "Avg per year",
        formatStats: true,
        key: "congressAvgPerYear",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.CONGRESSES
        )}`
      }
    ] as DetailsStatsInterface[];

    const awardsDetails = [
      {
        kind: GenericSearchEnum.AFFILIATION,
        title: "Awards",
        count: this.state.filtered.awards,
        formatStats: false,
        key: "awards",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.AWARDS
        )}`
      }
    ] as DetailsStatsInterface[];

    const grantsDetails = [
      {
        kind: GenericSearchEnum.GRANTS,
        title: "Grants Received",
        count: this.state.filtered.grantsReceived,
        formatStats: false,
        key: "grantsReceived",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.GRANTS
        )}`
      },
      {
        kind: GenericSearchEnum.GRANTS,
        title: "Total funding",
        count: props.grantsTotalFunding,
        formatStats: true,
        asMoney: true,
        key: "grantsTotalFunding",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.GRANTS
        )}`
      },
      {
        kind: GenericSearchEnum.GRANTS,
        title: "Avg. funding per year",
        count: this.state.filtered.grantsAvgFundingPerYear,
        formatStats: true,
        asMoney: true,
        key: "grantsAvgFundingPerYear",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.GRANTS
        )}`
      }
    ] as DetailsStatsInterface[];

    const publicationDetails = [
      {
        kind: GenericSearchEnum.PUBLICATION,
        title: "Total",
        count: this.state.filtered.publications,
        formatStats: false,
        key: "publications",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.PUBLICATIONS
        )}`
      },
      {
        kind: GenericSearchEnum.PUBLICATION,
        title: "Citations",
        count: this.state.filtered.citations,
        formatStats: true,
        key: "citations",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.PUBLICATIONS
        )}`
      },
      {
        kind: GenericSearchEnum.PUBLICATION,
        title: "h-index",
        count: this.props.hiIndex,
        formatStats: true,
        key: "hiIndex",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.PUBLICATIONS
        )}`
      },
      {
        kind: GenericSearchEnum.PUBLICATION,
        title: "Altmetric total",
        count: this.state.filtered.altMetericTotal,
        formatStats: true,
        key: "altMetericTotal",
        path: `/curie/person/${this.props.id}/${pathForSubview(
          SubViews.PUBLICATIONS
        )}`
      }
    ] as DetailsStatsInterface[];

    return (
      <PersonOverviewCardContainer>
        {this.renderDetails(publicationDetails) && (
          <Details>
            <SectionTitle>
              <StyledH5
                onClick={() => {
                  this.props.history.push({
                    pathname: `/curie/person/${this.props.id}/${pathForSubview(
                      SubViews.PUBLICATIONS
                    )}`
                  });
                }}
              >
                PUBLICATIONS
              </StyledH5>
            </SectionTitle>
            {publicationDetails.map(
              this.detailsStatsWithPath(
                `/curie/person/${this.props.id}/${pathForSubview(
                  SubViews.PUBLICATIONS
                )}`
              )
            )}
          </Details>
        )}
        {this.renderDetails(clinicalTrialsDetails) && (
          <Details>
            <SectionTitle>
              <StyledH5
                onClick={() => {
                  this.props.history.push({
                    pathname: `/curie/person/${this.props.id}/${pathForSubview(
                      SubViews.CLINICAL
                    )}`
                  });
                }}
              >
                CLINICAL TRIALS
              </StyledH5>
            </SectionTitle>
            {clinicalTrialsDetails.map(
              this.detailsStatsWithPath(
                `/curie/person/${this.props.id}/${pathForSubview(
                  SubViews.CLINICAL
                )}`
              )
            )}
          </Details>
        )}
        {this.renderDetails(openPaymentsDetails) && (
          <Details>
            <SectionTitle>
              <StyledH5
                onClick={() => {
                  this.props.history.push({
                    pathname: `/curie/person/${this.props.id}/${pathForSubview(
                      SubViews.PAYMENTS
                    )}`
                  });
                }}
              >
                OPEN PAYMENTS
              </StyledH5>
            </SectionTitle>
            {openPaymentsDetails.map(
              this.detailsStatsWithPath(
                `/curie/person/${this.props.id}/${pathForSubview(
                  SubViews.PAYMENTS
                )}`
              )
            )}
          </Details>
        )}
        {this.renderDetails(congressDetails) && (
          <Details>
            <SectionTitle>
              <StyledH5
                onClick={() => {
                  this.props.history.push({
                    pathname: `/curie/person/${this.props.id}/${pathForSubview(
                      SubViews.CONGRESSES
                    )}`
                  });
                }}
              >
                CONGRESSES
              </StyledH5>
            </SectionTitle>
            {congressDetails.map(
              this.detailsStatsWithPath(
                `/curie/person/${this.props.id}/${pathForSubview(
                  SubViews.CONGRESSES
                )}`
              )
            )}
          </Details>
        )}

        {this.renderDetails(grantsDetails) && (
          <Details>
            <SectionTitle>
              <StyledH5
                onClick={() => {
                  this.props.history.push({
                    pathname: `/curie/person/${this.props.id}/${pathForSubview(
                      SubViews.GRANTS
                    )}`
                  });
                }}
              >
                GRANTS
              </StyledH5>
            </SectionTitle>
            {grantsDetails.map(
              this.detailsStatsWithPath(
                `/curie/person/${this.props.id}/${pathForSubview(
                  SubViews.GRANTS
                )}`
              )
            )}
          </Details>
        )}
        {this.renderDetails(awardsDetails) && (
          <Details>
            <SectionTitle>
              <StyledH5
                onClick={() => {
                  this.props.history.push({
                    pathname: `/curie/person/${this.props.id}/${pathForSubview(
                      SubViews.AWARDS
                    )}`
                  });
                }}
              >
                AWARDS
              </StyledH5>
            </SectionTitle>
            {awardsDetails.map(
              this.detailsStatsWithPath(
                `/curie/person/${this.props.id}/${pathForSubview(
                  SubViews.AWARDS
                )}`
              )
            )}
          </Details>
        )}
      </PersonOverviewCardContainer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  searchBarState: getDocumentSearchBarState(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterText: getDocumentSearchBarFilterDateDisplayText(state)
});

const mapDispatchToProps = {
  setDocumentSearchBarQuery
};

export const ProfileStats = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileStatsClass as any));
