import * as React from "react";
import { connect } from "react-redux";
import { getTrialsFilters } from "../../../../../store/selectors";
import {
  setTrialStudyTypeValues,
  setTrialStudyTypeQuery
} from "../../../../../store/actions";
import { RootState } from "../../../../../store/reducers";
import { FilterAutosuggest as Autosuggest } from "../../FilterAutosuggest";

interface MappedStateProps {
  options: string[];
  values: string[];
  inputValue: string;
}

interface DispatchProps {
  setValues: (values: string[]) => void;
  handleInputChange: (inputVal: string) => void;
}

type Props = MappedStateProps & DispatchProps;

class StudyTypeFiltersComponent extends React.Component<Props> {
  render() {
    const { options, values, setValues } = this.props;

    return (
      <Autosuggest
        values={values}
        setValues={setValues}
        suggestions={options}
        inputLabel="Study Type"
        inputValue={this.props.inputValue}
        setInputValue={this.props.handleInputChange}
      />
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => {
  const { studyType } = getTrialsFilters(state);

  return {
    options: studyType.options,
    values: studyType.values,
    inputValue: studyType.query
  };
};

const mapDispatchToProps: DispatchProps = {
  setValues: setTrialStudyTypeValues,
  handleInputChange: setTrialStudyTypeQuery
};

export const StudyTypeFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudyTypeFiltersComponent);
