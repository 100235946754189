import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { DropdownOption, DropdownOptionInterface } from "./DropdownOption";
import { ArrowUp } from "./Arrows";

const Root = styled.div`
  position: absolute;
  top: -34px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
  z-index: 4000;
  background-color: #fafafa;
  font-family: Montserrat;
  padding-bottom: 16px;
`;

export const SelectedOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 24px;
  min-width: 150px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const OptionsWrapper = styled.div`
  margin-top: 6px;
`;

export interface DropdownOptionsProps {
  options: DropdownOptionInterface[];
  selected: string;
  onSelect: (value: any, text: string) => any;
  close: () => void;
}

export const DropdownOptions: React.SFC<DropdownOptionsProps> = ({
  options,
  selected,
  onSelect,
  close
}) => {
  return (
    <Root>
      <SelectedOption onClick={close}>
        {selected} <ArrowUp style={{ marginLeft: 10 }} />
      </SelectedOption>
      <OptionsWrapper>
        {options.map((opt, i) => {
          return (
            <DropdownOption
              {...opt}
              key={`dropdown-option-${opt.text}-${opt.value}-${i}`}
              onSelect={onSelect}
            />
          );
        })}
      </OptionsWrapper>
    </Root>
  );
};
