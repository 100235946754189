/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { themeColors, styled } from "@h1eng/ui-components";
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
} from "../../../../store/actions";
import { connect } from "react-redux";
import { FILTER_CARD_WIDTH, FILTER_CARD_RIGHT_MARGIN } from "../../Filters";
import {
  FilterSearchInterface,
  SearchLibrary,
  SortBy
} from "@h1eng/interfaces";
import { ResultCardData } from "@h1eng/interfaces";
import { ResultCards } from "../../KOLResult/ResultCards";
import { UserVO } from "../../../../models/UserVO";
import {
  searchElastic,
  kolCardsActionGet,
  KolCardsParams,
  clearSelectedId,
  setQuery,
  setPageNum,
  setSortBy,
  searchFromStoreState
} from "../../../../store/actions";
import {
  currentUser,
  getCards,
  getSelectedProject,
  getCurrentPersonIds,
  getQuery,
  getTotalHits,
  getPageNum,
  getPageSize,
  getSortBy,
  isLoadingPeople,
  getSearchResultsCount
} from "../../../../store/selectors";
import DropdownSearchBar from "./DropdownSearchBar";
import { NoResultsFound } from "./NoResultsFound";
import { SetDocumentSearchBarQueryInterface } from "../../Profile/ProfileDocumentSearch";
import { RootState } from "../../../../store/reducers";

const Background = styled.div`
  background-color: ${themeColors.themeBackDropColor};
  min-height: 100vh;
  min-width: 1024px;
`;
const SearchCard = styled.div`
  padding-top: 20px;
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  max-width: 1280px;
  margin: 0 auto;
`;

const SearchBarAndResults = styled.div`
  width: 100%;
  padding: 20px;
`;

interface CurieSearchProps {
  kolCards: ResultCardData[];
  query: string;
  projectId: string;
  personIds: string[];
  user: UserVO;
  totalHits: number;
  getPage: number;
  getSize: number;
  getSortBy: SortBy;
  loading: boolean;
  searchResultsCount: number;
  filters: RootState["searchFilters"];
}

interface CurieSearchDispatcher {
  searchElastic: (search: FilterSearchInterface) => void;
  getKolCards: (params: KolCardsParams) => void;
  clearSelectedListId: () => void;
  setQuery: (query: string) => void;
  setDocumentSearchBarQuery: (t: SetDocumentSearchBarQueryInterface) => void;
  setDocumentSearchBarFilterDate: (t: {
    filterDate: number;
    displayFilterDateString: string;
  }) => void;
  setPageNum: (pageNum: number) => void;
  setSortBy: (sortBy: SortBy) => void;
  searchFromStoreState: () => void;
}

type Props = CurieSearchProps & CurieSearchDispatcher;

export class CurieSearchClass extends React.Component<
  CurieSearchProps & CurieSearchDispatcher
> {
  scrollRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      results: [] as ResultCardData[],
      searchLibrary: {} as SearchLibrary
    };
    this.scrollRef = React.createRef();
  }

  get cardsExist() {
    let cardIds: string[] | string = this.props.kolCards.map(i => i.personId);

    if (cardIds.length === 0) return false;

    cardIds = cardIds.sort().join(",");
    const personIds = this.props.personIds.sort().join(",");

    return cardIds === personIds;
  }

  initialize() {
    this.props.clearSelectedListId();
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (this.cardsExist) return;

    const date = new Date().setFullYear(new Date().getFullYear() - 100);
    const optionText = `All time`;
    this.props.setDocumentSearchBarFilterDate({
      displayFilterDateString: optionText,
      filterDate: date
    });
    this.initialize();
    if (this.props.query.length > 0) {
      this.props.searchFromStoreState();
    }
  }

  handleSetQuery = (query: string) => {
    this.props.setPageNum(0);
    this.props.setQuery(query);
  };

  render() {
    return (
      <Background>
        <SearchCard>
          <SearchBarAndResults>
            <div
              style={{
                display: "flex",
                marginLeft: FILTER_CARD_WIDTH + FILTER_CARD_RIGHT_MARGIN
              }}
            >
              <div style={{ width: "100%" }} ref={this.scrollRef}>
                <DropdownSearchBar
                  applySearch={this.handleSetQuery}
                  projectId={this.props.projectId}
                  query={this.props.query.split(",").filter(x => x)}
                  totalHits={this.props.totalHits}
                  loading={this.props.loading}
                  resultsCount={this.props.searchResultsCount}
                />
              </div>
            </div>
            <ResultCards nullState={<NoResultsFound />} />
          </SearchBarAndResults>
        </SearchCard>
      </Background>
    );
  }
}

const mapStateToProps = (state: RootState): CurieSearchProps => ({
  query: getQuery(state),
  projectId: getSelectedProject(state) as string,
  user: currentUser(state) as UserVO,
  kolCards: getCards(state),
  personIds: getCurrentPersonIds(state),
  totalHits: getTotalHits(state),
  getPage: getPageNum(state),
  getSize: getPageSize(state),
  getSortBy: getSortBy(state),
  loading: isLoadingPeople(state),
  searchResultsCount: getSearchResultsCount(state),
  filters: state.searchFilters
});

const mapDispatchToProps: CurieSearchDispatcher = {
  searchElastic: searchElastic.request,
  getKolCards: kolCardsActionGet.request,
  clearSelectedListId: clearSelectedId,
  setQuery,
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate,
  setPageNum,
  setSortBy,
  searchFromStoreState
};

export const CurieSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(CurieSearchClass as any);
