import * as React from "react";
import { connect } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { GraphDatum } from "@h1eng/interfaces";
import {
  cartesianGrid,
  line,
  xAxis,
  yAxis,
  lineChart,
  legend,
  tooltip
} from "../styles";
import { getDocumentSearchBarQurey } from "../../../../../store/selectors";
import { getRenderSearched, getXAxisInterval } from "../lib/helpers";
import { GraphTitle } from "./GraphTitle";

interface LineGraphProps {
  data: GraphDatum[];
  yAxisLabel?: string;
  title?: string;
  primaryColor: string;
  secondaryColor?: string;
}

interface DispatchProps {
  searchQuery: any[];
}

class LineGraphClass extends React.Component<LineGraphProps & DispatchProps> {
  static defaultProps = {
    secondaryColor: "#060464"
  };

  render() {
    const { data, title, primaryColor, secondaryColor } = this.props;
    const renderSearched = getRenderSearched(this.props);

    return (
      <div>
        {!!title && <GraphTitle>{title}</GraphTitle>}
        <ResponsiveContainer aspect={2}>
          <LineChart {...lineChart} data={data}>
            <CartesianGrid {...cartesianGrid} />
            <Line
              {...line}
              dataKey="total"
              stroke={primaryColor}
              name="Total"
            />
            {renderSearched && (
              <Line
                {...line}
                dataKey="searched"
                stroke={secondaryColor}
                name="Matched keywords"
              />
            )}
            <XAxis
              {...xAxis}
              interval={getXAxisInterval(data.length)}
              dataKey="year"
            />
            <YAxis
              {...yAxis}
              label={{ ...yAxis.label, value: this.props.yAxisLabel }}
            />
            <Tooltip {...tooltip} />
            {renderSearched && <Legend {...legend} />}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  searchQuery: getDocumentSearchBarQurey(state)
});

export const LineGraph = connect<any, any, any>(mapStateToProps)(
  LineGraphClass as any
);
