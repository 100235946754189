import { UserVO } from "../models/UserVO";
import { ENDPOINTS } from "./api-endpoints";
import { GetLiteProfileInterface, LiteProfile } from "@h1eng/interfaces";

export function requestKol(name: string, user: UserVO) {
  return fetch(ENDPOINTS.requestKol, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      requestedName: name,
      requestingUser: {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email
      }
    })
  });
}
export const getLiteProfile = async (search: GetLiteProfileInterface) => {
  const asw = await fetch(`${ENDPOINTS.liteProfile}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(search)
  });
  const data = (await asw.json()) as LiteProfile[];
  return data;
};
