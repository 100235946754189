/*
Copyright 2019 H1 Insights

Permission to use, copy, modify, and/or distribute this software for any purpose with or without fee is hereby granted,
provided that the above copyright notice and this permission notice appear in all copies.

THE SOFTWARE IS PROVIDED "AS IS" AND THE AUTHOR DISCLAIMS ALL WARRANTIES WITH REGARD TO THIS SOFTWARE
INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS.
IN NO EVENT SHALL THE AUTHOR BE LIABLE FOR ANY SPECIAL, DIRECT, INDIRECT,
OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE,
DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION,
ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SOFTWARE.
*/
/* tslint:disable:cyclomatic-complexity */
import * as React from "react";

export interface TruncateProps extends React.HTMLProps<Truncate> {
  lines?: number | false;
  width?: number;
  ellipsis?: React.ReactNode;
  trimWhitespace?: boolean;
  /**
   * Whether or not a line should truncate in the middle of a word
   */
  breakWords?: boolean;
  onTruncate?(isTruncated: boolean): void;
}

export interface TruncateState {
  targetWidth: number;
}

type CustomWindow = Window & { HTMLElement: any };

export class Truncate extends React.Component<TruncateProps, TruncateState> {
  static defaultProps = {
    children: "",
    ellipsis: "…",
    lines: 1,
    trimWhitespace: false,
    width: 0,
    breakWords: false
  };

  state = {
    targetWidth: 0
  };

  elements: { [key: string]: any } = {};

  timeout: any;

  canvasContext: any;

  styles = {
    ellipsis: {
      position: "fixed",
      visibility: "hidden",
      top: 0,
      left: 0
    }
  };

  componentDidMount() {
    const {
      elements: { text },
      calcTargetWidth,
      onResize
    } = this;

    const canvas = document.createElement("canvas");
    this.canvasContext = canvas.getContext("2d");

    calcTargetWidth(() => {
      // Node not needed in document tree to read its content
      if (text) {
        text.parentNode.removeChild(text);
      }
    });

    window.addEventListener("resize", onResize);
  }

  componentDidUpdate(prevProps: TruncateProps) {
    // Render was based on outdated refs and needs to be rerun
    if (this.props.children !== prevProps.children) {
      this.forceUpdate();
    }

    // If the width prop has changed, recalculate size of contents
    if (this.props.width !== prevProps.width) {
      this.calcTargetWidth();
    }
  }

  componentWillUnmount() {
    const {
      elements: { ellipsis },
      onResize,
      timeout
    } = this;

    ellipsis.parentNode.removeChild(ellipsis);

    window.removeEventListener("resize", onResize);

    window.cancelAnimationFrame(timeout);
  }

  // Shim innerText to consistently break lines at <br/> but not at \n
  innerText(node: HTMLElement) {
    const div = document.createElement("div");
    const contentKey =
      "innerText" in (window as CustomWindow).HTMLElement.prototype
        ? "innerText"
        : "textContent";

    div.innerHTML = node.innerHTML.replace(/\r\n|\r|\n/g, " ");

    let text = div[contentKey];

    const test = document.createElement("div");
    test.innerHTML = "foo<br/>bar";

    if ((test[contentKey] || "").replace(/\r\n|\r/g, "\n") !== "foo\nbar") {
      div.innerHTML = div.innerHTML.replace(/<br.*?[\/]?>/gi, "\n");
      text = div[contentKey];
    }

    return text;
  }

  onResize = () => {
    this.calcTargetWidth();
  };

  onTruncate = (didTruncate: any) => {
    const { onTruncate } = this.props;

    if (typeof onTruncate === "function") {
      this.timeout = window.requestAnimationFrame(() => {
        onTruncate(didTruncate);
      });
    }
  };

  calcTargetWidth = (callback?: (...args: any) => any): number | void => {
    const { width } = this.props;

    // Calculation is no longer relevant, since node has been removed
    if (!this.elements.target) {
      return;
    }

    const targetWidth =
      width ||
      // Floor the result to deal with browser subpixel precision
      Math.floor(this.elements.target.parentNode.getBoundingClientRect().width);

    // Delay calculation until parent node is inserted to the document
    // Mounting order in React is ChildComponent, ParentComponent
    if (!targetWidth) {
      return window.requestAnimationFrame(() => this.calcTargetWidth(callback));
    }

    const style = window.getComputedStyle(this.elements.target);

    const font: string = [
      // @ts-ignore
      style["font-weight"],
      // @ts-ignore
      style["font-style"],
      // @ts-ignore
      style["font-size"],
      // @ts-ignore
      style["font-family"]
    ].join(" ");

    this.canvasContext.font = font;

    this.setState(
      {
        targetWidth
      },
      callback
    );
  };

  measureWidth = (text: string) => {
    return this.canvasContext.measureText(text).width;
  };

  ellipsisWidth = (node: HTMLElement) => {
    return node.offsetWidth;
  };

  trimRight = (text?: string): string => {
    if (!text) return "";

    return text.replace(/\s+$/, "");
  };

  trimLastLine = (text: string) => {
    const { breakWords } = this.props;

    if (!breakWords) {
      const parts = text.split(" ");

      if (parts.length > 1) {
        parts.splice(-1, 1);

        return parts.join(" ");
      }
    }

    return text;
  };

  getLines = () => {
    const { ellipsis, trimWhitespace } = this.props;
    const { targetWidth } = this.state;

    const numLines = this.props.lines || Truncate.defaultProps.lines;

    const lines = [];
    const text = this.innerText(this.elements.text) || "";
    const textLines = text.split("\n").map(line => line.split(" "));
    let didTruncate = true;
    const ellipsisWidth = this.ellipsisWidth(this.elements.ellipsis);

    for (let line = 1; line <= numLines; line++) {
      const textWords = textLines[0];

      // Handle newline
      if (textWords.length === 0) {
        lines.push();
        textLines.shift();
        line--;
        continue;
      }

      let resultLine = textWords.join(" ");

      if (this.measureWidth(resultLine) <= targetWidth) {
        if (textLines.length === 1) {
          // Line is end of text and fits without truncating
          didTruncate = false;

          lines.push(resultLine);
          break;
        }
      }

      if (line === numLines) {
        // Binary search determining the longest possible line incdluding truncate string
        const textRest = textWords.join(" ");

        let lower = 0;
        let upper = textRest.length - 1;

        while (lower <= upper) {
          const middle = Math.floor((lower + upper) / 2);

          const testLine = textRest.slice(0, middle + 1);

          if (this.measureWidth(testLine) + ellipsisWidth <= targetWidth) {
            lower = middle + 1;
          } else {
            upper = middle - 1;
          }
        }

        let lastLineText = textRest.slice(0, lower);

        if (trimWhitespace) {
          lastLineText = this.trimRight(lastLineText);

          // Remove blank lines from the end of text
          while (!lastLineText.length && lines.length) {
            const prevLine = lines.pop();

            lastLineText = this.trimRight(prevLine);
          }
        }

        resultLine = (
          <span>
            {this.trimLastLine(lastLineText)}
            {ellipsis}
          </span>
        ) as any;
      } else {
        // Binary search determining when the line breaks
        let lower = 0;
        let upper = textWords.length - 1;

        while (lower <= upper) {
          const middle = Math.floor((lower + upper) / 2);

          const testLine = textWords.slice(0, middle + 1).join(" ");

          if (this.measureWidth(testLine) <= targetWidth) {
            lower = middle + 1;
          } else {
            upper = middle - 1;
          }
        }

        // The first word of this line is too long to fit it
        if (lower === 0) {
          // Jump to processing of last line
          line = numLines - 1;
          continue;
        }

        resultLine = textWords.slice(0, lower).join(" ");
        textLines[0].splice(0, lower);
      }

      lines.push(resultLine as any);
    }

    this.onTruncate(didTruncate);

    return lines;
  };

  renderLine = (line: string, i: number, arr: any[]) => {
    if (i === arr.length - 1) {
      return <span key={i}>{line}</span>;
    } else {
      const br = <br key={i + "br"} />;

      if (line) {
        return [<span key={i}>{line}</span>, br];
      } else {
        return br;
      }
    }
  };

  render() {
    const { children, ellipsis, lines, ...spanProps } = this.props;
    const { targetWidth } = this.state;

    let text;

    const mounted = !!(this.elements.target && targetWidth);

    if (typeof window !== "undefined" && mounted) {
      if (lines && lines > 0) {
        text = this.getLines().map(this.renderLine);
      } else {
        text = children;

        this.onTruncate(false);
      }
    }

    delete spanProps.onTruncate;
    delete spanProps.trimWhitespace;
    delete spanProps.breakWords;

    return (
      // @ts-ignore
      <span
        {...spanProps}
        ref={targetEl => {
          this.elements.target = targetEl;
        }}
      >
        <span>{text}</span>
        <span
          ref={textEl => {
            this.elements.text = textEl;
          }}
        >
          {children}
        </span>
        <span
          ref={ellipsisEl => {
            this.elements.ellipsis = ellipsisEl;
          }}
          style={this.styles.ellipsis as any}
        >
          {ellipsis}
        </span>
      </span>
    );
  }
}

export default Truncate;
