/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import * as _ from "lodash";
import { DropdownSuggestions } from "./DropdownSuggestions";
import PillFactory from "../../../Pill";
import SearchBar from "./SearchBar";
import { SearchLibrary } from "@h1eng/interfaces";
import { fetchPeopleSuggestions } from "../../../../apis/searchSuggestions";
import { styled } from "@h1eng/ui-components";

export const ResultNumber = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  color: "#333333";
  padding-right: 15px;
`;

const NUM_SUGGESTIONS = 5;

function getSuggestions(term: string, options: string[]) {
  if (term === "") return [];

  function permuteWords(words: string[]): string[] {
    if (words.length === 1) {
      return words;
    }

    const left = [
      ...permuteWords(_.take(words, words.length - 1)),
      words.join(" ")
    ];

    const right = [
      ...permuteWords(_.takeRight(words, words.length - 1)),
      words.join(" ")
    ];

    return _.uniq([...left, ...right]);
  }

  const matches = options.filter(option => {
    const tokenized = permuteWords(option.split(" "));
    const tokenMatches = tokenized
      .map(token => _.startsWith(token.toUpperCase(), term.toUpperCase()))
      .filter(x => x);
    return tokenMatches.length ? true : false;
  });
  return matches.length > 0 ? _.take(matches, NUM_SUGGESTIONS) : [];
}

function flattenSuggestions(suggestions: Suggestions) {
  const flat: { section: string; value: string; index: number }[] = _.flatMap(
    _.map(suggestions, (value, key) => {
      return _.map(value, (v, i: number) => {
        return { section: key, value: v, index: i };
      });
    })
  );
  return flat;
}

interface Suggestions {
  drugs: string[];
  indications: string[];
  clinicalTargets: string[];
  people: string[];
}

interface DropdownSearchBarProps {
  // library: SearchLibrary;
  projectId: string;
  totalHits: number;
  query: string[];
  applySearch: (query: string) => void;
  loading: boolean;
  resultsCount: number;
}

interface State {
  displayText: string;
  index: number;
  library: SearchLibrary;
  highlightedSuggestion: string;
  suggestions: Suggestions;
}

export default class DropdownSearchBar extends React.Component<
  DropdownSearchBarProps,
  State
> {
  debouncedFetchPeople = _.debounce(
    (projectId: string, searchBarValue: string) => {},
    300
  );
  constructor(props: DropdownSearchBarProps) {
    super(props);

    const initialLibrary: SearchLibrary = {
      drugs: [],
      indications: [],
      clinicalTargets: [],
      people: []
    };

    const initialSuggestions: Suggestions = {
      drugs: [],
      indications: [],
      clinicalTargets: [],
      people: []
    };

    this.state = {
      displayText: "",
      library: initialLibrary,
      index: -1,
      highlightedSuggestion: "",
      suggestions: initialSuggestions
    };
  }

  componentDidMount() {
    // only fetch indications, drugs, clinical targets.
    // getSearchSuggestions(this.props.projectId).then(library =>
    //   this.setState({ library })
    // );
  }

  handleChange = (e: any) => {
    const displayText = e.currentTarget.value;
    const { library } = this.state;

    this.setState(
      {
        displayText,
        index: -1,
        highlightedSuggestion: "",
        suggestions: {
          clinicalTargets: getSuggestions(displayText, library.clinicalTargets),
          drugs: getSuggestions(displayText, library.drugs),
          indications: getSuggestions(displayText, library.indications),
          people: getSuggestions(displayText, library.people)
        }
      },
      () => {
        // set to true to renable drop down
        if (false) {
          this.debouncedFetchPeople(
            this.props.projectId,
            this.state.displayText
          );
        }
      }
    );
  };

  applySearch = () => {
    const terms = this.state.highlightedSuggestion
      ? [this.state.highlightedSuggestion]
      : this.state.displayText
          .split(",")
          .map((s: any) => s.trim())
          .filter((s: any) => s.length > 0)
          .filter((e: any) => !this.props.query.includes(e));
    const update = [...this.props.query, ...terms];
    this.setState(
      {
        displayText: "",
        highlightedSuggestion: ""
      },
      () => this.props.applySearch(update.join(","))
    );
  };

  removeTerm = (term: string) => {
    const update = this.props.query.filter((t: any) => t !== term);
    this.props.applySearch(update.join(","));
  };

  addSuggestionToQuery = (term: string) => {
    const update = this.props.query.includes(term)
      ? this.props.query
      : [...this.props.query, term];
    this.setState({ displayText: "", highlightedSuggestion: "" }, () =>
      this.props.applySearch(update.join(","))
    );
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const flattenedSuggestions = flattenSuggestions(this.state.suggestions);

    // increment index
    if (
      e.key === "ArrowDown" &&
      this.state.index < flattenedSuggestions.length - 1
    ) {
      const index = this.state.index + 1;
      const highlightedSuggestion = flattenedSuggestions[index].value;
      this.setState({
        index,
        highlightedSuggestion
      });
    }

    // decrement index
    if (e.key === "ArrowUp" && this.state.index > 0) {
      const index = this.state.index - 1;
      const highlightedSuggestion = flattenedSuggestions[index].value;
      this.setState({ index, highlightedSuggestion });
    }
  };

  get resultsIndicatorValue() {
    const { loading, totalHits } = this.props;
    if (loading) return "Loading";

    let res = `${this.props.resultsCount}`;

    if (totalHits >= 2500) {
      res = `${res}+`;
    }

    return res;
  }

  render() {
    const flattenedSuggestions = flattenSuggestions(this.state.suggestions);
    const activeSection =
      flattenedSuggestions.length && this.state.index >= 0
        ? {
            section: flattenedSuggestions[this.state.index].section,
            index: flattenedSuggestions[this.state.index].index
          }
        : undefined;

    return (
      <>
        <form onSubmit={e => e.preventDefault()} style={{ margin: "0px" }}>
          <div
            style={{
              position: "relative"
            }}
          >
            <SearchBar
              handleChange={this.handleChange}
              handleKeyDown={this.handleKeyDown}
              handleSearch={this.applySearch}
              value={this.state.displayText}
            />

            {this.state.library && false && (
              <DropdownSuggestions
                activeSection={
                  activeSection ? activeSection.section : undefined
                }
                activeIndex={activeSection ? activeSection.index : undefined}
                term={this.state.displayText}
                suggestions={this.state.suggestions}
                onSuggestionSelected={this.addSuggestionToQuery}
              />
            )}
          </div>
        </form>
        <div
          style={{
            display: "flex",
            paddingTop: "19px",
            alignItems: "center",
            paddingLeft: 15
          }}
        >
          {this.props.query.length > 0 && (
            <ResultNumber>{`${this.resultsIndicatorValue} Results${
              this.resultsIndicatorValue === "Loading" ? "" : ":"
            }`}</ResultNumber>
          )}
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {this.props.query.map((term: any, i: any) => {
              return (
                <PillFactory
                  backgroundColor={"#d7d6e7"}
                  onClick={() => this.removeTerm(term)}
                  title={term}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
