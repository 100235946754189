import * as React from "react";
import { styled, css } from "@h1eng/ui-components";

interface IconBadgeProps {
  badgeColor?: string;
  badgeUrl?: string;
}

const Root = styled.div`
  border: 1px solid #ecf0f1;
  border-radius: 2px;
  background-color: #ffffff;
  min-width: 1024px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const IconBadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: transparent;
  position: relative;
  height: 0;
`;

const IconBadge = styled.div`
  width: 81px;
  height: 81px;
  border-radius: 50%;
  position: absolute;
  top: -40px;

  ${(props: IconBadgeProps) =>
    !!(props.badgeColor && props.badgeUrl) &&
    css`
      background: ${props.badgeColor} url(${props.badgeUrl}) no-repeat center;
    `}
`;

/* tslint:disable:no-empty-interface */
interface DocumentCardProps {}

export class DocumentCard extends React.Component<
  DocumentCardProps & IconBadgeProps
> {
  render() {
    const { children, badgeColor, badgeUrl } = this.props;
    return (
      <Root>
        <IconBadgeWrapper>
          <IconBadge badgeColor={badgeColor} badgeUrl={badgeUrl} />
        </IconBadgeWrapper>
        <ContentWrapper>{children}</ContentWrapper>
      </Root>
    );
  }
}
