import {
  searchElastic,
  getListForUser,
  getListOptions,
  createList,
  addToList,
  removePeopleFromList,
  deleteList,
  createGrowler,
  selectListId,
  kolSavedListsCardsActionGet,
  setKolCardsLoading,
  setSavedListPageNum
} from "../actions";
import { isActionOf } from "typesafe-actions";
import { Epic, StateObservable } from "redux-observable";
import { filter, switchMap, map, catchError, mergeMap } from "rxjs/operators";
import { from, of, pipe, concat } from "rxjs";
import {
  fetchListsForPersons,
  fetchAvailableLists,
  createNewList,
  removeFromList,
  deleteListApi,
  addToList as addToListCall
} from "../../apis/list";
import { RootState } from "../reducers";
import {
  currentUser,
  getSelectedProject,
  getSelectedListPersonIdsForCurrentPage,
  getPersonIdsInListByListId,
  getSelectedListPersonIds
} from "../selectors";
import { ListVO } from "../../models/ListVO";
import { GrowlerTypes } from "../../components/growler/GrowlerTypes";
import { paginate } from "@h1eng/pagination";

export const fetchListFlow: Epic<any, any, any> = action$ =>
  action$.pipe(
    filter(isActionOf(getListForUser.request)),
    switchMap(action =>
      from(
        fetchListsForPersons(
          action.payload.personIds,
          action.payload.projectId,
          action.payload.userId
        )
      ).pipe(
        map(getListForUser.success),
        catchError(
          pipe(
            searchElastic.failure,
            of
          )
        )
      )
    )
  );

export const removeFromListFlow: Epic<any, any, any> = action$ =>
  action$.pipe(
    filter(isActionOf(removePeopleFromList.request)),
    switchMap(({ payload }) =>
      from(removeFromList(payload.personIds, payload.listId)).pipe(
        map(() => removePeopleFromList.success(payload)),
        catchError(
          pipe(
            removePeopleFromList.failure,
            of
          )
        )
      )
    )
  );

export const deleteListFlow: Epic<any, any, any> = (
  action$,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(deleteList.request)),
    switchMap(({ payload }) =>
      concat(
        of(selectListId(null)),
        from(deleteListApi(payload.listId)).pipe(
          map(() =>
            deleteList.success({
              personIds: getPersonIdsInListByListId(
                state$.value,
                payload.listId
              ),
              listId: payload.listId
            })
          ),
          catchError(
            pipe(
              deleteList.failure,
              of
            )
          )
        )
      )
    )
  );

export const addToListFlow: Epic<any, any, any> = action$ =>
  action$.pipe(
    filter(isActionOf(addToList.request)),
    switchMap(({ payload }) =>
      from(addToListCall(payload.personIds, payload.listId)).pipe(
        map(() => addToList.success(payload)),
        catchError(
          pipe(
            addToList.failure,
            of
          )
        )
      )
    )
  );

export const createListFlow: Epic<any, any, any> = action$ =>
  action$.pipe(
    filter(isActionOf(createList.request)),
    switchMap(({ payload }) =>
      from(createNewList(payload)).pipe(
        map(createList.success),
        catchError(
          pipe(
            createList.failure,
            of
          )
        )
      )
    )
  );

export const createListWithGrowlerFlow: Epic<any, RootState, any> = (
  action$,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(createList.request)),
    mergeMap((payload: ListVO) => {
      const personName = !!(payload.firstName && payload.lastName)
        ? `${payload.firstName} ${payload.lastName}`
        : "this person";
      const listName = payload.listName || "";

      return concat(
        of(
          createGrowler({
            title: `Saved to New List`,
            description: `You have saved ${personName} to the new list ${listName}`,
            growler: GrowlerTypes.success,
            titleIcon: "check"
          })
        )
      );
    })
  );

export const getListOptionFlow: Epic<any, any, any> = action$ =>
  action$.pipe(
    filter(isActionOf(getListOptions.request)),
    switchMap(action =>
      from(
        fetchAvailableLists(action.payload.userId, action.payload.projectId)
      ).pipe(
        map(getListOptions.success),
        catchError(
          pipe(
            getListOptions.failure,
            of
          )
        )
      )
    )
  );

export const refetchListFlow: Epic<any, RootState, any> = (
  action$,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(
      isActionOf([
        addToList.success,
        createList.success,
        removePeopleFromList.success,
        deleteList.success
      ])
    ),
    mergeMap(({ payload: { personIds } }) =>
      concat(
        of(
          getListOptions.request({
            projectId: getSelectedProject(state$.value)!,
            userId: currentUser(state$.value)!.id
          })
        ),
        of(
          getListForUser.request({
            userId: currentUser(state$.value)!.id,
            projectId: getSelectedProject(state$.value)!,
            personIds
          })
        )
      )
    )
  );
export const setSavedListPageNumFlow: Epic<any, RootState, any> = (
  action$,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf([setSavedListPageNum])),
    switchMap(({ payload }) =>
      concat(
        of(setKolCardsLoading(true)),
        of(
          kolSavedListsCardsActionGet.request({
            projectId: state$.value.projects.selectedId!,
            userId: state$.value.user.user!.id,
            personIds: getSelectedListPersonIdsForCurrentPage(state$.value)
          })
        )
      )
    )
  );

export const removePeopleFromListFlow: Epic<any, RootState, any> = (
  action$,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf([removePeopleFromList.success])),
    switchMap(({ payload }) =>
      concat(
        of(
          kolSavedListsCardsActionGet.request({
            projectId: state$.value.projects.selectedId!,
            userId: state$.value.user.user!.id,
            personIds: paginate({
              pageNum: state$.value.lists.savedListPageNum,
              pageSize: state$.value.lists.savedListPageSize,
              items: getSelectedListPersonIds(state$.value).filter(
                i => payload.personIds.indexOf(i) === -1
              )
            })
          })
        )
      )
    )
  );

export const setSelectedListFlow: Epic<any, RootState, any> = (
  action$,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf([selectListId])),
    switchMap(({ payload }) =>
      concat(
        of(setKolCardsLoading(payload !== null)),
        of(setSavedListPageNum(0)),
        of(
          kolSavedListsCardsActionGet.request({
            projectId: state$.value.projects.selectedId!,
            userId: state$.value.user.user!.id,
            personIds: getSelectedListPersonIdsForCurrentPage(state$.value)
          })
        )
      )
    )
  );
