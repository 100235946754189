export interface PeopleSearchDocument {
  id: string;
  _id: string;
  name: string;
  titles: string[];
  designations: string[];
  specialty: string[];
  locations: Array<{ city: string; state: string } | null>;
  scores: Array<{ projectId: string; score: number }>;
  departments: string[];
  schools: string[];
  institutions: string[];
  projects: string[];
}

export interface GenericSearchDocument {
  terms: string[];
  type: GenericSearchEnum;
  id: string;
  _id: string;
  person_ids: { ids: string[] };
  collaborators: number;
  date: string | number | undefined;
  personNames: Array<{
    id: string;
    first: string;
    middle: string[];
    last: string;
  }>;
  paymentSum?: number;
  citationCount?: number;
}

export enum GenericSearchEnum {
  AFFILIATION,
  CLINICALTRIAL,
  CONGRESS,
  PUBLICATION,
  GRANTS,
  PAYMENTS,
  PERSONS,
  GENERIC
}
export interface ElasticSearchDocuments {
  type: GenericSearchEnum;
  doc: any;
}
