import * as React from "react";
import { Link } from "react-router-dom";
import { styled } from "@h1eng/ui-components";

const A = styled(Link)`
  font-size: 14px;
  font-family: Montserrat;
  color: #333;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    color: #0aaacd;
  }
`;

interface DocLinkProps {
  personId: string;
  docId: string;
  docType: string;
}

interface CombinedDocLinkProps {
  personId: string;
  docId: string;
}

export const DocLink: React.SFC<DocLinkProps> = props => {
  const { personId, docId, docType, children, ...otherProps } = props;

  return (
    <A to={`/curie/person/${personId}/${docType}/${docId}`} {...otherProps}>
      {children}
    </A>
  );
};

export const TrialLink: React.SFC<CombinedDocLinkProps> = props => (
  <DocLink docType="clinical-trials" {...props} />
);

export const PublicationLink: React.SFC<CombinedDocLinkProps> = props => (
  <DocLink docType="publications" {...props} />
);

export default DocLink;
