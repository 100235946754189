import {
  TitlesAndAssociationInterface,
  AffiliationClasses
} from "@h1eng/interfaces";

export interface AffiliationsSortedInterface {
  sections: {
    awards: TitlesAndAssociationInterface[];
    experience: TitlesAndAssociationInterface[];
  };
}

export interface AffiliationsSortedEducationInterface {
  sections: {
    education: TitlesAndAssociationInterface[];
    other: TitlesAndAssociationInterface[];
  };
}

export const createAndSortAffiliation = (
  aff: TitlesAndAssociationInterface[]
) =>
  aff.reduce(
    (pv: AffiliationsSortedInterface, cv: TitlesAndAssociationInterface) => {
      // if no type do not show in drop down
      if (cv.affiliationType === null) return pv;
      // if not of type awards all others are experience
      if (cv.affiliationType === AffiliationClasses.AWARDS) {
        pv.sections.awards.push(cv);
        return pv;
      }
      pv.sections.experience.push(cv);
      return pv;
    },
    {
      sections: {
        awards: [] as TitlesAndAssociationInterface[],
        experience: [] as TitlesAndAssociationInterface[]
      }
    } as AffiliationsSortedInterface
  ) as AffiliationsSortedInterface;

export const createAndSortAffiliationEducation = (
  aff: TitlesAndAssociationInterface[]
) =>
  aff.reduce(
    (
      pv: AffiliationsSortedEducationInterface,
      cv: TitlesAndAssociationInterface
    ) => {
      // if no type do not show in drop down
      if (cv.affiliationType === null) return pv;
      // if not of type awards all others are experience
      if (cv.affiliationType === AffiliationClasses.EDUCATION) {
        pv.sections.education.push(cv);
        return pv;
      }
      pv.sections.other.push(cv);
      return pv;
    },
    {
      sections: {
        education: [] as TitlesAndAssociationInterface[],
        other: [] as TitlesAndAssociationInterface[]
      }
    } as AffiliationsSortedEducationInterface
  ) as AffiliationsSortedEducationInterface;
