import { RootState } from "../reducers";
import { createSelector } from "reselect";
import * as _ from "lodash";
import { paginate } from "@h1eng/pagination";
import { ScoredDocumentResults, ScoredDocumentData } from "@h1eng/interfaces";

const getState = (state: RootState) => state;

export const getSearchResultsState = (state: RootState) => state.searchResults;
export const getSearchResultsStateWithKol = (state: RootState) => {
  return {
    kolCards: state.kolCards,
    searchResults: state.searchResults,
    selectedId: state.lists.selectedId
  };
};

// export const getPersonIds = createSelector(
//   getSearchResultsState,
//   results => results.currentResultSetPersonIds
// );

export const getTotalHits = createSelector(
  getSearchResultsState,
  results => results.totalHits
);

export const isLoadingPeople = createSelector(
  getSearchResultsStateWithKol,
  results => {
    return results.searchResults.loading;
  }
);
export const getSearchView = createSelector(
  getSearchResultsState,
  e => e.searchView
);

export const getPageNum = createSelector(
  getSearchResultsState,
  results => {
    return results.pageNum;
  }
);

export const getPageSize = createSelector(
  getSearchResultsState,
  results => {
    return results.pageSize;
  }
);

export const getDocumentSearch = createSelector(
  getSearchResultsState,
  results => results.documentSearchResults
);

/**
 * The actual number of returned results
 */
export const getSearchResultsCount = createSelector(
  getState,
  state => state.searchResults.scoredDocuments.persons.length || 0
);

export const getDocumentSearchLoading = createSelector(
  getSearchResultsState,
  res => res.documentSearchLoading
);

export const getCurrentScoredDocuments = createSelector(
  getState,
  (state: RootState): ScoredDocumentResults => {
    const pageNum = state.searchResults.pageNum;
    const pageSize = state.searchResults.pageSize;

    const {
      persons,
      ranges,
      normalizedRange
    } = state.searchResults.scoredDocuments;

    return {
      persons: paginate({ pageNum, pageSize, items: persons }),
      ranges,
      normalizedRange
    };
  }
);

export const getScoredPersons = createSelector(
  getState,
  (state: RootState): ScoredDocumentData[] => {
    return state.searchResults.scoredDocuments.persons;
  }
);

export const getCurrentPersonIds = createSelector(
  getState,
  (state: RootState): string[] => {
    const pageNum = state.searchResults.pageNum;
    const pageSize = state.searchResults.pageSize;

    return paginate({
      items: state.searchResults.scoredDocuments.persons.map(i => i.personId),
      pageNum,
      pageSize
    });
  }
);

export const getDisplayLoadingMessage = createSelector(
  getState,
  (state: RootState) => state.searchResults.displayLoadingMessage
);
