import * as React from "react";
import { withStyles } from "@material-ui/core/styles";

function componentForVariant(variant: string) {
  const variants: { [s: string]: string } = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    b1: "p",
    b2: "p",
    b3: "p",
    b4: "p",
    l1: "p",
    l2: "p"
  };
  return React.createFactory(variants[variant]);
}

const base = {
  margin: "0px",
  lineHeight: "1.5"
};

const fonts = {
  default: { fontFamily: "Montserrat, sans-serif" },
  secondary: { fontFamily: "Source Sans Pro, sans-serif;" }
};

const colors = {
  default: { color: "#333" },
  muted: { color: "#737373" },
  hint: { color: "#939393" },
  action: { color: "#0aaacd" },
  inverted: { color: "#fff" }
};

const behaviors = {
  action: {
    "&:hover": {
      cursor: "pointer",
      color: "#17798f"
    }
  },
  inverted_action: {
    "&:hover": {
      cursor: "pointer",
      color: "rgba(255, 255, 255, .8)"
    }
  }
};

const linkBase = {
  display: "inline-block"
};

const styles: any = {
  h1: {
    ...base,
    ...fonts.default,
    ...colors.default,
    fontSize: "68px",
    fontWeight: "300"
  },
  h2: {
    ...base,
    ...fonts.default,
    ...colors.default,
    fontSize: "42px",
    fontWeight: "normal"
  },
  h3: {
    ...base,
    ...fonts.default,
    ...colors.default,
    fontSize: "26px",
    fontWeight: "500"
  },
  h4: {
    ...base,
    ...fonts.default,
    ...colors.default,
    fontWeight: "600"
  },
  h5: {
    ...base,
    ...fonts.default,
    ...colors.default,
    fontSize: "12px",
    fontWeight: "600"
  },
  b1: {
    ...base,
    ...fonts.default,
    ...colors.default,
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "1.3"
  },
  b2: {
    ...base,
    ...fonts.default,
    ...colors.default,
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "1.3"
  },
  b3: {
    ...base,
    ...fonts.secondary,
    ...colors.default,
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "1.3"
  },
  b4: {
    ...base,
    ...fonts.secondary,
    ...colors.default,
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "1.3"
  },
  l1: {
    ...base,
    ...linkBase,
    ...fonts.default,
    ...colors.action,
    ...behaviors.action,
    fontSize: "14px",
    fontWeight: "bold"
  },
  l2: {
    ...base,
    ...linkBase,
    ...fonts.default,
    ...colors.action,
    ...behaviors.action,
    fontSize: "12px",
    fontWeight: "600"
  },
  muted: {
    ...colors.muted
  },
  inverted: {
    ...colors.inverted
  },
  inverted_action: {
    ...behaviors.inverted_action
  }
};

interface ClassSpec {
  classes: any;
  appliedVariant: any;
  className: any;
  muted: any;
  inverted: any;
}

const getWithClassName = (className: any) => (className ? ` ${className}` : "");
const getWithMuted = (muted: any, classes: any) =>
  muted ? `${classes.muted}` : "";
const getWithInverted = (inverted: any, classes: any) =>
  inverted ? ` ${classes.inverted}` : "";
const getWithInvertedAction = (
  inverted: any,
  appliedVariant: any,
  classes: any
) => {
  const isActionElement = appliedVariant === "l1" || appliedVariant === "l2";
  return inverted && isActionElement ? ` ${classes.inverted_action}` : "";
};

const getClasses = ({
  classes,
  appliedVariant,
  className,
  muted,
  inverted
}: ClassSpec) => {
  const applied =
    classes[appliedVariant] +
    getWithClassName(className) +
    getWithMuted(muted, classes) +
    getWithInverted(inverted, classes) +
    getWithInvertedAction(inverted, appliedVariant, classes);

  return applied;
};

const genTypography = (props: any) => {
  const { variant, muted, inverted, className, classes, ...other } = props;
  const appliedVariant = variant ? variant : "b2";

  const appliedClassNames = getClasses({
    classes,
    appliedVariant,
    className,
    muted,
    inverted
  });

  const Component = componentForVariant(appliedVariant);
  return Component({
    children: props.children,
    className: appliedClassNames,
    ...other
  });
};

export const Typography = withStyles(styles)(genTypography);
