import { setSearchBarQuery, SearchBarAction } from "../actions/searchBar";
import { getType } from "typesafe-actions";

export interface SearchBarQuery {
  query: string[];
}

const initialState = {
  query: []
} as SearchBarQuery;

export const searchBarReducer = (
  state: SearchBarQuery = initialState,
  action: SearchBarAction
): SearchBarQuery => {
  switch (action.type) {
    case getType(setSearchBarQuery): {
      return {
        ...state,
        query: action.payload
      };
    }

    default:
      return state;
  }
};
