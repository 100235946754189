import * as React from "react";

import { styled, B1 } from "@h1eng/ui-components";

const PUBMED = "https://www.ncbi.nlm.nih.gov/pubmed/";
const DOI = "http://doi.org/";
const CLINICAL_TRIALS = "https://clinicaltrials.gov/ct2/show/study/";

const ExternalLink = styled.a`
  font-size: 14px;
  font-family: Montserrat;
  color: #333;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    color: #0aaacd;
  }
`;

const GetLink = (hasId: boolean, url: string, children: React.ReactNode) => {
  return !hasId ? (
    <B1>{children}</B1>
  ) : (
    <ExternalLink href={url} target="_blank">
      {children}
    </ExternalLink>
  );
};

interface PubLinkProps {
  pmid?: string;
  doi?: string;
}

export const getLinkUrl = (props: PubLinkProps) => {
  const { pmid, doi } = props;
  if (!!pmid) {
    return `${PUBMED}${pmid}`;
  } else if (!!doi) {
    return `${DOI}${doi}`;
  }

  return null;
};

export const PubLink: React.SFC<PubLinkProps> = props => {
  const { pmid, doi } = props;
  const url = pmid ? `${PUBMED}${pmid}` : `${DOI}${doi}`;
  const hasId = !pmid && !doi ? false : true;
  return GetLink(hasId, url, props.children);
};

interface TrialLinkProps {
  NCT?: string;
}

export const TrialLink: React.SFC<TrialLinkProps> = props => {
  const { NCT } = props;
  const url = `${CLINICAL_TRIALS}${NCT}`;
  const hasId = NCT ? true : false;
  return GetLink(hasId, url, props.children);
};

export const CongressLink: React.SFC<TrialLinkProps> = props => {
  const { NCT } = props;
  const url = `${CLINICAL_TRIALS}${NCT}`;
  const hasId = NCT ? true : false;
  return GetLink(hasId, url, props.children);
};
