import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Root = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: Montserrat;
  :first-of-type {
    margin-top: 20px;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid #dedede;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
  width: 100%;
  color: #737373;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  text-transform: uppercase;
`;

interface CardSectionProps {
  title?: string;
}

export const CardSection: React.SFC<CardSectionProps> = props => (
  <Root>
    {!!props.title && <Title>{props.title}</Title>}
    {props.children}
  </Root>
);
