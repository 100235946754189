import * as React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import { clearSearchFilters } from "../../../../store/actions";
import { RootState } from "../../../../store/reducers";
import {
  getSelectedProject,
  getClearAllButtonDisabled
} from "../../../../store/selectors";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      paddingLeft: 0,
      paddingRight: 0,
      color: "#435D6B",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline"
      }
    }
  });

interface MappedStateProps {
  projectId: string;
  disabled: boolean;
}

interface DispatchProps {
  clearFilters: () => void;
}

type Props = MappedStateProps & DispatchProps & WithStyles<typeof styles>;

const ClearAllButtonComponent: React.FunctionComponent<Props> = props => {
  function handleClick() {
    props.clearFilters();
  }

  return (
    <Button
      classes={props.classes}
      size="small"
      onClick={handleClick}
      style={{ textTransform: "unset" }}
      disabled={props.disabled}
    >
      Clear All
    </Button>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  projectId: getSelectedProject(state) as string,
  disabled: getClearAllButtonDisabled(state)
});

const mapDispatchToProps: DispatchProps = {
  clearFilters: clearSearchFilters
};

export const ClearAllButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ClearAllButtonComponent));
