import * as React from "react";
import { styled } from "./themes/styled-components";
import { themeColors } from "./Colors";

export interface BodyFactoryInterface {
  size: string;
  font: string;
  weight: string;
  color: string;
  shouldHover: boolean;
  style?: React.CSSProperties;
}
interface BodyStyleInterface {
  size: string;
  font: string;
  weight: string;
  shouldHover: boolean;
}

const buildTag = (
  bodyFactory: (props: any) => BodyFactoryInterface,
  props: any
) => (
  <BodyFactory {...bodyFactory(props)} style={props.style}>
    {props.children}
  </BodyFactory>
);

const Body = styled.div<BodyStyleInterface>`
  font-size: ${(props: any) => props.size};
  font-family: ${(props: any) => props.font};
  font-weight: ${(props: any) => props.weight};
  color: ${(props: any) => props.color};
  margin: 0;
  &:hover {
    color: ${(props: any) =>
      props.shouldHover ? themeColors.themeLinkDefualt : "none"};
    cursor: ${(props: any) => (props.shouldHover ? "pointer" : "initial")};
  }
`;

export const BodyFactory: React.SFC<BodyFactoryInterface> = props => {
  return <Body {...props}>{props.children}</Body>;
};

export interface BodyInterface {
  deEmphasized?: boolean;
  darkBackground?: boolean;
  hover?: boolean;
  small?: boolean;
  style?: React.CSSProperties;
}

const color = (props: any) => {
  return props.deEmphasized
    ? themeColors.themeHeadingsDeEmphasized
    : props.darkBackground
    ? themeColors.themeBodyDarkBackground
    : themeColors.themeBodyDefualt;
};

const getBodyProps = (font: string, weight: string) => (props: any) => ({
  size: props.small ? "12px" : "14px",
  font,
  weight,
  color: color(props),
  shouldHover: props.hover || false
});

const b1Props = getBodyProps("Montserrat", "600");
const b2Props = getBodyProps("Montserrat", "normal");
const b3Props = getBodyProps("Source Sans Pro", "600");
const b4Props = getBodyProps("Source Sans Pro", "normal");

export const B1: React.SFC<BodyInterface> = props => buildTag(b1Props, props);
export const B2: React.SFC<BodyInterface> = props => buildTag(b2Props, props);
export const B3: React.SFC<BodyInterface> = props => buildTag(b3Props, props);
export const B4: React.SFC<BodyInterface> = props => buildTag(b4Props, props);
