import * as React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { RootState } from "../../../../store/reducers";

export interface MinCountMappedStateProps {
  minCount: number | null;
}

export interface MinCountDispatchProps {
  setMinCount: (minCount: number | null) => void;
}

export type MinCountProps = MinCountMappedStateProps & MinCountDispatchProps;

const handleChange = (fn: (minCount: number | null) => void) => (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => {
  const num = parseInt(event.target.value, 10);

  const minCount = isNaN(num) ? null : num;
  fn(minCount);
};

export function MinCount({
  minCount,
  setMinCount,
  ...props
}: MinCountProps & any) {
  return (
    <TextField
      value={minCount === null ? "" : minCount}
      onChange={handleChange(setMinCount)}
      type="number"
      fullWidth
      {...props}
      label={
        <span style={{ fontSize: 14, color: "#435d6b" }}>
          {props.label || "Minimum count"}
        </span>
      }
    />
  );
}

export const connectProps = (
  mapStateToProps: (state: RootState) => MinCountMappedStateProps,
  mapDispatchToProps: MinCountDispatchProps
): React.ComponentType<TextFieldProps> =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MinCount);
