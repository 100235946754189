import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { HoverTooltipProps, HoverTooltip } from "../../../Tooltip";
import { Link, LinkProps } from "react-router-dom";

interface BasicPercentageIndicatorProps {
  value: number;
  total: number;
  color: string;
  label: string;
  height?: number;
  formatter?: (value: number) => string | number;
  tooltipContent?: React.ReactElement<any>;
  tooltipProps?: Partial<HoverTooltipProps>;
  skipZeroValues?: boolean;
  percentage?: number;
}

interface LinkPercentageIndicatorProps extends BasicPercentageIndicatorProps {
  link: boolean;
  to: LinkProps["to"];
  onClick?: LinkProps["onClick"];
}

interface StandardPercentageIndicatorProps
  extends BasicPercentageIndicatorProps {
  link: boolean;
  to?: never;
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => any;
}

// A discriminated union, allowing to create a link with required `to`
export type PercentageIndicatorProps =
  | LinkPercentageIndicatorProps
  | StandardPercentageIndicatorProps;

const StandardRoot = styled.div`
  display: flex;
  flex-direction: column;
  cursor: ${props => (props.onClick ? "pointer" : "default")};
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const LinkRoot = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const LabelRoot = styled.div`
  margin-bottom: 4px;
`;

const LabelText = styled.span`
  color: #435d6b;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-right: 6px;
  cursor: inherit;
`;

const ValueText = styled(LabelText)`
  margin-right: 0;
  color: #333;
  font-weight: bold;
`;

const VisualizationRoot = styled.div`
  height: ${({
    height = PercentageIndicator.defaultProps.height
  }: Partial<PercentageIndicatorProps>) => height}px;
  width: 100%;
  max-width: 100%;
  border-radius: 7px;
  background-color: #f0f0f0;
  position: relative;
`;

export class PercentageIndicator extends React.Component<
  PercentageIndicatorProps
> {
  static defaultProps: Partial<PercentageIndicatorProps> = {
    height: 8,
    skipZeroValues: false
  };

  get formattedValue(): string | number {
    const { formatter, value } = this.props;

    if (formatter) return formatter(value);

    return value;
  }

  get percentage() {
    const { value, total } = this.props;
    let res: number;

    if (this.props.percentage) {
      res = this.props.percentage;
    } else if (value === 0 || total === 0) {
      res = 0;
    } else {
      res = (value / total) * 100;
    }

    return Math.round(res);
  }

  get indicator() {
    const { color, height } = this.props;

    const { percentage } = this;

    const Indicator = styled(VisualizationRoot)`
      background-color: ${color};
      width: ${percentage}%;
      position: absolute;
    `;

    return (
      <VisualizationRoot height={height}>
        <Indicator height={height} />
      </VisualizationRoot>
    );
  }

  render() {
    const {
      label,
      value,
      total,
      skipZeroValues,
      onClick,
      tooltipProps,
      tooltipContent,
      link,
      to
    } = this.props;
    if (skipZeroValues && (total === 0 || value === 0)) return null;

    const { indicator } = this;

    const content = (
      <HoverTooltip {...tooltipProps} content={tooltipContent}>
        <LabelRoot>
          <LabelText>{label}</LabelText>
          <ValueText>{this.formattedValue}</ValueText>
        </LabelRoot>
        {indicator}
      </HoverTooltip>
    );

    if (link) {
      return (
        <LinkRoot
          to={to as string}
          onClick={onClick as LinkPercentageIndicatorProps["onClick"]}
        >
          {content}
        </LinkRoot>
      );
    }

    return (
      <StandardRoot
        onClick={onClick as StandardPercentageIndicatorProps["onClick"]}
      >
        {content}
      </StandardRoot>
    );
  }
}
