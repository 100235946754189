export enum AffiliationClasses {
  EDUCATION = "EDUCATION",
  AWARDS = "AWARDS",
  EXPERIENCE = "EXPERIENCE",
  ASSOCIATIONS_BOARDS = "ASSOCIATIONS/BOARDS",
  HOSPITALS_PRACTICES = "HOSPITALS/PRACTICES",
  OTHER = "OTHER",
  COMPANIES = "COMPANIES",
  WORK = "WORK"
}
