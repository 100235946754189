import { isActionOf } from "typesafe-actions";
import { Epic, StateObservable } from "redux-observable";
import { filter, mergeMap, map, tap } from "rxjs/operators";
import { of, concat, from } from "rxjs";
import { UserVO } from "../../models/UserVO";

import {
  getProjectsForUser,
  currentUserGet,
  setToken //,
  // logout
} from "../actions";
//import { logout as logoutCall } from "../../apis/auth";
import * as jwt from "jsonwebtoken";
//import { RootState } from "../reducers";
//import { getAuthToken } from "../selectors";

/*
export const logoutFlow: Epic<any, any, any> = (
  action$,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(logout)),
    from(logoutCall(getAuthToken(state$.value)!)).pipe(mergeMap(() => concat()))
   from(logoutCall(getAuthToken(state$.value)!)).pipe(
      map()
    ).pipe(
      map(({ payload }) => current)
    )
*/
export const fetchCurrentUserFlow: Epic<any, any, any> = action$ => {
  return action$.pipe(
    filter(isActionOf(setToken)),
    map(({ payload }) => jwt.decode(payload)),
    mergeMap(({ user }: { user: UserVO }) =>
      concat(
        of(currentUserGet.success(user)),
        of(getProjectsForUser.request(user.id))
      )
    )
  );
};
