import { connect } from "react-redux";
import Heap from "react-heap";
import { currentUser } from "../store/selectors";

declare global {
  interface Window {
    heap: any;
  }
}

export default connect((state: any) => {
  const user = currentUser(state);
  return {
    appId: process.env.HEAP_ID,
    userId: user ? user.id : "guest",
    userData: user
      ? {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email
        }
      : {}
  };
})(Heap);
