import * as React from "react";
import { StyledNavLinkProps } from "./StyledNavLinkProps";
import { BaseNavLink } from "./BaseNavLink";
import { LinkTheme } from "./LinkTheme";
import { BaseNavLinkFactoryProps } from "./BaseNavLinkFactoryProps";
import { Dictionary } from "lodash";
import { NavBarVariant } from "./NavBarVariant";
import { getColor, getBackgroundColor } from "./colorGen";

export const PrimaryNavLink: React.SFC<StyledNavLinkProps> = props => {
  const colors: Dictionary<LinkTheme> = {
    [NavBarVariant.GRAY]: {
      idle: "#737373",
      hover: "#555",
      active: "#333",
      backgroundIdle: "none",
      backgroundHover: "none",
      backgroundActive: "none"
    }
  };

  const linkProps: BaseNavLinkFactoryProps = {
    to: props.to,
    parentOwnsHover: false,
    underline: props.active ? "inset 0px -2px 0px #0aaacd" : "none",
    color: getColor(props, colors),
    backgroundColor: getBackgroundColor(props, colors),
    hoverColor: colors[props.variant].hover,
    hoverBackgroundColor: colors[props.variant].backgroundHover
  };

  return <BaseNavLink {...linkProps}>{props.children}</BaseNavLink>;
};
