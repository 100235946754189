import * as React from "react";
import * as _ from "lodash";
import { B1, styled, themeColors } from "@h1eng/ui-components";

interface DropdownSuggestionsProps {
  term: string;
  activeSection: string | undefined;
  activeIndex: number | undefined;
  suggestions: {
    clinicalTargets: string[];
    drugs: string[];
    indications: string[];
    people: string[];
  };
  onSuggestionSelected: (suggestion: string) => void;
}

interface DropdownSectionProps {
  title: string;
  icon: string;
  suggestions: string[];
  term: string;
  activeIndex: number | undefined;
  onSuggestionSelected: (suggestion: string) => void;
}

const Suggestion = styled.div<{ active: boolean }>`
  font-family: Montserrat;
  font-weight: normal;
  font-size: 14px;
  color: #333;
  padding: 2px 20px;
  background: ${props => (props.active ? "#eee" : "white")}
  &:hover {
    cursor: pointer;
    background: #eee;
  }
`;

const SuggestionsDropdown = styled.div`
  width: 80%;
  background-color: white;
  position: absolute;
  z-index: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  z-index: 10000000;
`;

function calcMatchStart(suggestion: string, term: string) {
  // the match must start at the beginning of a word

  const upperSuggestion = suggestion.toUpperCase();
  const upperTerm = term.toUpperCase();
  const rawMatch = upperSuggestion.indexOf(upperTerm);

  if (rawMatch === 0) {
    return rawMatch; // allowed to start at the very beginning
  }

  const wordStarts = suggestion
    .split("")
    .map((s, i) => (s === " " ? i + 1 : undefined))
    .filter(x => x !== undefined) as number[];

  // otherwise, round to the nearest wordStart
  const nearest = _.first(
    _.orderBy(
      wordStarts.map(i => {
        return { i, distance: Math.abs(i - rawMatch) };
      }),
      "distance",
      "asc"
    )
  );

  return upperSuggestion.indexOf(upperTerm, nearest!.i || 0);
}

const DropdownSection = (props: DropdownSectionProps) => {
  return props.suggestions.length ? (
    <div style={{ padding: "10px 0px", borderBottom: "1px solid #d8d8d8" }}>
      <div style={{ paddingLeft: "20px", display: "flex" }}>
        <img
          src={
            props.icon
              ? props.icon
              : "http://d10ba5vkyum61s.cloudfront.net/img/favicon.ico"
          }
          style={{ width: "15px", height: "15px", paddingRight: "5px" }}
        />
        <B1>
          <b>{props.title}</b>
        </B1>
      </div>
      {props.suggestions.map((suggestion, i) => {
        // find the charachters in suggestion that match term and highlight them.

        const matchStart = calcMatchStart(suggestion, props.term);
        const matchEnd = matchStart + props.term.length;
        const left = _.take(suggestion, matchStart).join("");
        const right = _.takeRight(
          suggestion,
          suggestion.length - matchEnd
        ).join("");

        const highlighted = (
          <b style={{ color: themeColors.themeLinkDefualt }}>
            {suggestion.slice(matchStart, matchEnd)}
          </b>
        );

        return (
          <Suggestion
            key={i}
            onClick={() => props.onSuggestionSelected(suggestion)}
            active={i === props.activeIndex}
          >
            {left}
            {highlighted}
            {right}
          </Suggestion>
        );
      })}
    </div>
  ) : null;
};

export class DropdownSuggestions extends React.Component<
  DropdownSuggestionsProps
> {
  render() {
    if (this.props.term === "") {
      return null;
    }

    return (
      <SuggestionsDropdown>
        <DropdownSection
          title="Clinical Target"
          icon="http://d10ba5vkyum61s.cloudfront.net/img/ClinicalTargets.svg"
          activeIndex={
            this.props.activeSection === "clinicalTargets"
              ? this.props.activeIndex
              : undefined
          }
          suggestions={this.props.suggestions.clinicalTargets}
          term={this.props.term}
          onSuggestionSelected={this.props.onSuggestionSelected}
        />
        <DropdownSection
          title="Drugs"
          icon="http://d10ba5vkyum61s.cloudfront.net/img/Drugs.svg"
          activeIndex={
            this.props.activeSection === "drugs"
              ? this.props.activeIndex
              : undefined
          }
          suggestions={this.props.suggestions.drugs}
          term={this.props.term}
          onSuggestionSelected={this.props.onSuggestionSelected}
        />
        <DropdownSection
          title="Indications"
          icon="http://d10ba5vkyum61s.cloudfront.net/img/Indications.svg"
          activeIndex={
            this.props.activeSection === "indications"
              ? this.props.activeIndex
              : undefined
          }
          suggestions={this.props.suggestions.indications}
          term={this.props.term}
          onSuggestionSelected={this.props.onSuggestionSelected}
        />
        <DropdownSection
          title="People"
          icon="http://d10ba5vkyum61s.cloudfront.net/img/search_suggestion_person.svg"
          activeIndex={
            this.props.activeSection === "people"
              ? this.props.activeIndex
              : undefined
          }
          suggestions={this.props.suggestions.people}
          term={this.props.term}
          onSuggestionSelected={this.props.onSuggestionSelected}
        />
      </SuggestionsDropdown>
    );
  }
}
