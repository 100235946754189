import * as React from "react";

interface ClickOutsideListenerProps {
  onClickOutside: () => void;
}

// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
export default class ClickOutsideListener extends React.Component<
  ClickOutsideListenerProps,
  {}
> {
  wrapperRef: any;

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  // /**
  //  * Alert if clicked on outside of element
  //  */
  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClickOutside();
    }
  };

  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}
