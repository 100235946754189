import { getType } from "typesafe-actions";
import { currentUserGet, UserAction, setToken } from "../actions/user";
import { UserVO } from "../../models/UserVO";

export interface UserState {
  user: UserVO | null;
  jwt: string | null;
}

const initialState = {
  user: null,
  jwt: null
} as UserState;

export const userReducer = (
  state: UserState = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case getType(currentUserGet.success): {
      const user = action.payload;
      return { ...state, user };
    }
    case getType(setToken): {
      const jwt = action.payload;
      return { ...state, jwt };
    }
    default:
      return state;
  }
};
