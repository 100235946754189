/* tslint:disable:no-shadowed-variable no-unused-expression */
/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { OpenPaymentInterface } from "@h1eng/interfaces";
import * as _ from "lodash";
import { PaymentTableRow } from "./PaymentsTableRow";
export const TermsCompany = styled.div`

  width: 40%;
  min-width: 40%
  display: flex;
`;
export const TermsDrug = styled.div`

  width: 40%;
  min-width: 40%
  display: flex;
`;
export const TermsAmount = styled.div`

  width: 10%;
  min-width: 10%
  display: flex;
`;
export const TermsDate = styled.div`

  width: 10%;
  min-width: 10%
  display: flex;
`;

export interface CompanyPaymentBreakDown {
  company: string;
  drugOrDeviceName: string;
  amount: number;
  startDate?: date;
  endDate?: date;
  naturesOfPayments: {
    [nature: string]: {
      natureOfPayment: string;
      amount: number;
    };
  };
}
const createCompanyPaymentBreakDown: CompanyPaymentBreakDown = (
  companyName: string,
  drugOrDeviceName: string,
  payments: OpenPaymentInterface[]
) => {
  const groupedByNature: {
    [nature: string]: OpenPaymentInterface[];
  } = _.groupBy(payments, (e: OpenPaymentInterface) => e.natureOfPayment);
  const groupedByNatureKeys = Object.keys(groupedByNature);
  let amount = 0;
  let startDate;
  let endDate;
  const set = {
    company: companyName,
    drugOrDeviceName,
    treatments: groupedByNatureKeys.reduce(
      (pv: Partial<CompanyPaymentBreakDown>, cv: string) => {
        const sum = groupedByNature[cv].reduce(
          (pv, cv: OpenPaymentInterface) => (pv += cv.amount),
          0
        );
        const sortedDates: date[] = groupedByNature[cv]
          .map((e: OpenPaymentInterface) => new Date(e.date))
          .sort((a: OpenPaymentInterface, b: OpenPaymentInterface) => {
            return b - a;
          });
        const PaymentsOfNature = groupedByNature[cv];
        amount += sum;
        !pv.natureOfPayments.hasOwnProperty(cv)
          ? (pv.natureOfPayments[cv] = {
              amount: sum
            })
          : {
              ...pv.natureOfPayments[cv],
              amount: sum + pv.natureOfPayments[cv].amount
            };

        if (startDate === undefined) {
          startDate = sortedDates[0].getTime();
        }
        if (endDate === undefined) {
          endDate = sortedDates[0].getTime();
        }

        if (sortedDates[0].getTime() < new Date(startDate).getTime()) {
          startDate = sortedDates[0].getTime();
        }
        if (
          sortedDates[sortedDates.length - 1].getTime() >
          new Date(endDate).getTime()
        ) {
          endDate = sortedDates[sortedDates.length - 1].getTime();
        }
        return pv;
      },
      {
        natureOfPayments: {}
      } as Partial<CompanyPaymentBreakDown>
    )
  };

  return { ...set, startDate, endDate, amount };
};

export class PaymentTableGroup extends React.Component<{
  payments: OpenPaymentInterface[];
}> {
  render() {
    const font = {
      color: "#333333",
      fontFamily: "Montserrat",
      fontSize: "14px"
    };
    const groupByDrug = _.groupBy(
      this.props.payments,
      (e: OpenPaymentInterface) => e.drugOrDeviceName
    );
    const keysOfGroupByDrug = Object.keys(groupByDrug);

    const breakDownsPerCompany = keysOfGroupByDrug
      .map(e =>
        createCompanyPaymentBreakDown(
          this.props.payments[0].payerName,
          e,
          groupByDrug[e]
        )
      )
      .sort((a: CompanyPaymentBreakDown, b: CompanyPaymentBreakDown) => {
        return b.amount - a.amount;
      });

    return (
      <div
        style={{
          ...font,
          display: "flex",
          flexDirection: "column",
          borderBottom: "2px solid rgb(246, 246, 246)",
          paddingBottom: "10px",
          paddingTop: "10px"
        }}
      >
        {breakDownsPerCompany.map((breakDown, i) => {
          return (
            <PaymentTableRow
              breakDown={breakDown}
              groupIndex={i}
              sizeOfGroup={breakDownsPerCompany.length}
              key={`breakdown-${i}`}
            />
          );
        })}
      </div>
    );
  }
}
