/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { B1, B2, styled, H5 } from "@h1eng/ui-components";
import { margins } from "../../../Typeography";
import {
  ClinicalTrialInterface,
  ClinicalTrialsInterface,
  GenericSearchResultInterface,
  CombinedDocumentsType,
  GenericSearchEnum
} from "@h1eng/interfaces";
import { Card } from "../ProfileElements";
import * as _ from "lodash";
import { ProfileCard, HorizontalProfileRow } from "../ProfileShared";
import { TrialLink } from "../DocLink";
import { connect } from "react-redux";
import {
  getDocumentSearch,
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText
} from "../../../../store/selectors";
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
} from "../../../../store/actions";
import {
  SetDocumentSearchBarQueryInterface,
  ProfileDocumentSearch
} from "../ProfileDocumentSearch";
import { MultiRender } from "../MultiDocRender";
import { findMostCommon } from "../ProfileOverStats";
import {
  SearchBarQureyHelper,
  SearchBarQureyDisplay,
  filterDocsByDate,
  filterFoundDocs
} from "../SearchHelpFunctions";
import { ClinicalTrialsVisualization } from "../DataVisualizations/ClinicalTrials";
import { SummaryItem, Numerator, Denominator } from "../components/SummaryItem";
import { DateRangeDropdown } from "../../common/DateRangeDropdown";
import { TextTruncate } from "../../common/TextTruncate";
import { formatDate } from "../../DocumentViews/ClinicalTrial/ClinicalTrial";

const BottomDetails = styled.div`
  padding-top: ${margins.publicationsCard};
  > * {
    padding-right: 10px;
  }
  display: flex;
`;

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const ClinicalTrialShaderGrid = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
`;

const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ecf0f1;
`;

const Spacer = styled.div`
  margin-bottom: 5px;
`;

const getHeaderStates = (stat: any, props: any) => {
  return (
    <ClinicalTrialShaderGrid>
      <SummaryItem
        label="Total"
        item={
          <>
            <Numerator>{stat.clincalTrialsCount}</Numerator>
            <Denominator>/{props.clincalTrialsCount}</Denominator>
          </>
        }
      />
      {stat.topCondition && (
        <SummaryItem
          label="Top&nbsp;conditions"
          item={stat.topCondition}
          splitItem
        />
      )}
      {stat.topIntervention && (
        <SummaryItem
          label="Top&nbsp;interventions"
          item={stat.topIntervention}
          splitItem
        />
      )}
    </ClinicalTrialShaderGrid>
  );
};

const getHeader = (props: any) => {
  return (
    <ClinicalTrialShaderGrid>
      <SummaryItem label="Total" item={props.clincalTrialsCount} />
      {props.topCondition && (
        <SummaryItem
          label="Top&nbsp;conditions"
          item={props.topCondition}
          splitItem
        />
      )}
      {props.topIntervention && (
        <SummaryItem
          label="Top&nbsp;interventions"
          item={props.topIntervention}
          splitItem
        />
      )}
    </ClinicalTrialShaderGrid>
  );
};
interface DispatchProps {
  setDocumentSearchBarQuery: (t: SetDocumentSearchBarQueryInterface) => void;
  setDocumentSearchBarFilterDate: (t: {
    filterDate: number;
    displayFilterDateString: string;
  }) => void;
  documentSearch: GenericSearchResultInterface[];
  searchBarState: { query: string[] };
  filterDate: number;
  filterDateText: string;
}

export class ClinicalTrialsSectionClass extends React.Component<
  ClinicalTrialsInterface & DispatchProps & { personId: string },
  any
> {
  getTrialOverview(trials: ClinicalTrialInterface[]) {
    const clincalTrialsCompletedCount = trials.filter(e => e.completed).length;
    const clincalTrialsInProgressCount = trials.filter(e => e.inprogress)
      .length;
    const clincalTrialsTerminatedCount = trials.filter(e => e.completed).length;

    return {
      clincalTrialsCount: trials.length,
      clincalTrialsCompletedCount,
      clincalTrialsInProgressCount,
      clincalTrialsTerminatedCount,
      topCondition: findMostCommon(
        _.flatten(trials.map(t => t.conditions!.map(c => c)))
      ),
      topIntervention: findMostCommon(
        _.flatten(trials.map(t => t.intervention!))
      )
    };
  }

  render() {
    const trialsFilterdByDate = filterDocsByDate(
      this.props.filterDate,
      this.props.filterDateText,
      this.props.trials!
    );

    const trialsSearched = filterFoundDocs(
      this.props.documentSearch,
      trialsFilterdByDate
    );

    const summary = this.props.searchBarState.query.length
      ? getHeaderStates(
          this.getTrialOverview(trialsSearched),
          this.getTrialOverview(trialsFilterdByDate)
        )
      : getHeader(this.getTrialOverview(trialsFilterdByDate));

    return (
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "20px",
            paddingLeft: "20px"
          }}
        >
          <ProfileDocumentSearch personId={this.props.personId} />
          <DateRangeDropdown />
        </div>
        <SearchBarQureyHelper />
        <SearchBarQureyDisplay />
        <Header>
          <div style={{ marginBottom: "10px" }}>
            <H5 deEmphasized>SUMMARY</H5>
          </div>
          <SummaryGrid>
            {summary}
            <ClinicalTrialsVisualization trials={this.props.trials} />
          </SummaryGrid>
        </Header>
        <MultiRender
          docs={this.props.trials!.map(e => e as CombinedDocumentsType)}
          dateFilter={this.props.filterDate}
          types={[GenericSearchEnum.CLINICALTRIAL]}
          limitNoSearch={false}
          personId={this.props.personId}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  searchBarState: getDocumentSearchBarState(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state)
});

const mapDispatchToProps = {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
};

export const ClinicalTrialsSection = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ClinicalTrialsSectionClass as any);

// @ts-ignore
export const CLINICALTRIALCARD: React.SFC<
  ClinicalTrialInterface & { personId: string }
> = props => {
  return (
    <ProfileCard boxShadowColor={"#aeffde"}>
      <H5 deEmphasized>CLINICAL TRIAL</H5>
      <br />

      <TrialLink personId={props.personId} docId={props.id}>
        {props.title}
      </TrialLink>

      <Spacer />
      <HorizontalProfileRow>
        <B1 small>Phases:&nbsp;</B1>
        <B2 small deEmphasized>
          {props.phaseStatus}
        </B2>
      </HorizontalProfileRow>
      <HorizontalProfileRow>
        <B1 small>Conditions:&nbsp;</B1>
        <B2 small deEmphasized style={{ display: "block", width: "100%" }}>
          <TextTruncate lines={1}>{props.conditions!.join(", ")}</TextTruncate>
        </B2>
      </HorizontalProfileRow>
      <HorizontalProfileRow>
        <B1 small>Interventions:&nbsp;</B1>
        <B2 small deEmphasized style={{ display: "block", width: "100%" }}>
          <TextTruncate lines={1}>{props.intervention}</TextTruncate>
        </B2>
      </HorizontalProfileRow>

      <BottomDetails>
        {!!props.startDate && <B1 small>Start Date:</B1>}
        {!!props.startDate && (
          <B2 small deEmphasized>
            {formatDate(new Date(props.startDate))}
            &nbsp;
          </B2>
        )}

        {!!props.completionDate && <B1 small> Completion Date:</B1>}
        {!!props.completionDate && (
          <B2 small deEmphasized>
            {formatDate(new Date(props.completionDate))}
            &nbsp;
          </B2>
        )}

        {!!props.primaryCompletionDate && (
          <B1 small> Primary Completion Date:</B1>
        )}
        {!!props.primaryCompletionDate && (
          <B2 small deEmphasized>
            {formatDate(new Date(props.primaryCompletionDate))}
            &nbsp;
          </B2>
        )}
      </BottomDetails>
    </ProfileCard>
  );
};
