import * as React from "react";
import { flatMap } from "lodash";
import { styled } from "@h1eng/ui-components";
import { connect } from "react-redux";
import { ListMeta } from "../ListManagementInterfaces";
import { RootState } from "../../../../store/reducers";
import { HoverTooltip } from "../../../Tooltip";
import {
  getSelectedListId,
  getAvailableLists
} from "../../../../store/selectors";

interface ComponentProps {
  personId: string;
}

interface DispatchProps {
  listsAvailable: ListMeta[];
  selectedListId: string | null;
}

type Props = ComponentProps & DispatchProps;

interface ListsCollection {
  // The lists that a given KOL is a member of
  in: ListMeta[];
  // The other lists--those that a KOL is not a member of
  out: ListMeta[];
}

interface ListPersonJoin {
  id: string;
  personId: string;
}

type ListMetaWithJoins = ListMeta & { listPersonJoins: ListPersonJoin[] };

const ListCountText = styled.span`
  color: #737373;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  cursor: default;
`;

const Root = styled.div`
  position: relative;
  &:hover {
    ${ListCountText} {
      color: rgb(10, 170, 205);
    }
  }
`;

const TooltipRoot = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

const TooltipListText = styled.div`
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
`;

const TooltipLabel = styled(TooltipListText)`
  font-weight: 600;
`;

class ListCountClass extends React.Component<Props> {
  get lists(): ListsCollection {
    const { personId, listsAvailable } = this.props;

    return (listsAvailable as ListMetaWithJoins[]).reduce(
      (prev: ListsCollection, current) => {
        const personIds = flatMap(current.listPersonJoins, e => e.personId);

        if (personIds.indexOf(personId) === -1) {
          prev.out = [...prev.out, current];
        } else {
          prev.in = [...prev.in, current];
        }

        return prev;
      },
      { in: [], out: [] } as ListsCollection
    );
  }

  get labelText(): React.ReactChild {
    if (this.props.selectedListId) return <>Also&nbsp;saved&nbsp;to:</>;

    return <>Saved&nbsp;to:</>;
  }

  render() {
    const { lists } = this;

    if (lists.in.length === 0) return null;

    return (
      <Root>
        <HoverTooltip
          style={{
            padding: 8,
            bottom: 30,
            minWidth: 150,
            boxShadow:
              "0 0 10px 0 rgba(0,0,0,0.12), 0 4px 6px 0 rgba(0,0,0,0.24)"
          }}
          content={
            <TooltipRoot>
              <TooltipLabel>{this.labelText}</TooltipLabel>
              {lists.in.map((list, i) => (
                <TooltipListText key={`list-${list.id}-${i}`}>
                  {list.title}
                </TooltipListText>
              ))}
            </TooltipRoot>
          }
        >
          <ListCountText>
            {lists.in.length} {lists.in.length === 1 ? "List" : "Lists"}
          </ListCountText>
        </HoverTooltip>
      </Root>
    );
  }
}

export const mapStateToProps = (state: RootState): DispatchProps => ({
  listsAvailable: getAvailableLists(state),
  selectedListId: getSelectedListId(state)
});

export const ListCount = connect(mapStateToProps)(ListCountClass);
