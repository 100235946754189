export const themeColors = {
  themeBackgroundPurple: "#060464",
  themeHeadingsDefualt: "#333333",
  themeHeadingsDeEmphasized: "#737373",
  themeHeadingsDarkBackground: "#ffffff",
  themeBodyDefualt: "#333333",
  themeBodyDeEmphasized: "#737373",
  themeBodyDarkBackground: "#ffffff",
  themeFormHints: "#939393",
  themeFormHintDarkBackground: "rgba(255, 255, 255, 0.73)",
  themeLinkDefualt: "#0aaacd",
  themeLinkDarkBackground: "#ffffff",
  themeBackDropColor: "#f6f6f6",
  themeLinkDefaultHover: "#17798f",
  themeLinkDarkBackgroundHover: "rgba(255,255,255,.8)"
};
