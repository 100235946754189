import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Denominator = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  color: #333333;
`;

const Numerator = styled(Denominator)`
  font-weight: bold;
`;

const Fraction = styled(Denominator)`
  font-weight: bold;
`;

const FractionRoot = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &:hover {
    ${Numerator} {
      color: #0aaacd;
    }
  }
`;

const TotalRoot = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &:hover {
    ${Denominator} {
      color: #0aaacd;
    }
  }
`;

interface Props {
  numerator?: number | string;
  denominator: number | string;
  onClick?: () => void;
  money?: boolean;
}

export class ProfileStatDetail extends React.Component<Props> {
  static defaultProps = { money: false };

  render() {
    const { numerator, denominator, onClick, money } = this.props;

    if (numerator === undefined) {
      return (
        <TotalRoot onClick={onClick}>
          <Denominator>
            {money && "$"}
            {denominator}
          </Denominator>
        </TotalRoot>
      );
    }

    return (
      <FractionRoot>
        <Numerator>
          {money && "$"}
          {numerator}
        </Numerator>
        &nbsp;
        <Fraction>/</Fraction>
        <Denominator>{denominator}</Denominator>
      </FractionRoot>
    );
  }
}
