import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { styled } from "@h1eng/ui-components";
import { omit } from "lodash";

interface StyleProps {
  backgroundColor?: string;
  underline?: string;
  color?: string;
  parentOwnsHover?: any;
  hoverColor?: string;
  hoverBackgroundColor?: string;
}

const omitProps = [
  "backgroundColor",
  "underline",
  "color",
  "parentOwnsHover",
  "hoverColor",
  "hoverBackgroundColor"
];

export const BaseNavLink = styled((props: StyleProps & NavLinkProps) => (
  <NavLink {...omit(props, omitProps) as NavLinkProps} />
))`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: ${props => props.color};
  background: ${props => props.backgroundColor};
  box-shadow: ${props => props.underline};
  padding-left: 20px;
  padding-right: 20px;
  line-height: 65px;

  ${props =>
    props.parentOwnsHover
      ? ""
      : `&:hover {color: ${props.hoverColor}; background-color: ${
          props.hoverBackgroundColor
        } }`};

  @media all and (max-width: 600px) {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
