import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const getUserState = (state: RootState) => state.user;

export const currentUser = createSelector(
  getUserState,
  userState => userState.user
);

export const getAuthToken = createSelector(
  getUserState,
  userState => userState.jwt
);

export const getCurrentUserId = createSelector(
  getUserState,
  userState => userState.user!.id
);
