import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { OpenPaymentInterface } from "@h1eng/interfaces";
import { PaymentTableGroup } from "./PaymentsTableGroup";
const TermsCompany = styled.div`

  width: 40%;
  min-width: 40%
  font-weight: bold;
  color: #737373;
`;
const TermsDrug = styled.div`

  width: 40%;
  min-width: 40%
  font-weight: bold;
  color: #737373;
`;
const TermsAmount = styled.div`

  width: 10%;
  min-width: 10%
  font-weight: bold;
  color: #737373;
`;
const TermsDate = styled.div`

  width: 10%;
  min-width: 10%
  font-weight: bold;
  color: #737373;
`;

export class PaymentTable extends React.Component<
  { payments: { [payer: string]: OpenPaymentInterface[] } },
  any
> {
  render() {
    const keys = Object.keys(this.props.payments).sort((a, b) => {
      return (
        this.props.payments[b].reduce((pv, cv) => {
          return (pv += cv.amount);
        }, 0) -
        this.props.payments[a].reduce((pv, cv) => {
          return (pv += cv.amount);
        }, 0)
      );
    });

    return (
      <div
        style={{
          backgroundColor: "#FFFFFF",
          // paddingLeft: "20px",
          padding: "20px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            color: "#737373",
            fontFamily: "Montserrat",
            fontSize: "12px",
            fontWeight: "bold",
            borderBottom: "2px solid rgb(246, 246, 246)",
            height: "30px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <TermsCompany>COMPANY</TermsCompany>
          <TermsDrug>DRUG/DEVICE</TermsDrug>
          <TermsAmount>AMOUNT</TermsAmount>
          <TermsDate>DATE</TermsDate>
        </div>
        {keys.map((e, i) => {
          return (
            <PaymentTableGroup
              payments={this.props.payments[e]}
              key={`key-${e}-${i}`}
            />
          );
        })}
      </div>
    );
  }
}
