import * as React from "react";
import { KOLType } from "@h1eng/interfaces";
import { HoverTooltip } from "../../Tooltip";
import { B1, styled } from "@h1eng/ui-components";

const badgeImages: any = {
  "Institutional Pedigree":
    "https://d10ba5vkyum61s.cloudfront.net/img/kol-types/institutional.svg", // DEPRECATED
  "Clinical Investigator":
    "https://d10ba5vkyum61s.cloudfront.net/img/kol-types/institutional.svg",
  "Industry Partner":
    "https://d10ba5vkyum61s.cloudfront.net/img/kol-types/private-sector.svg", // DEPRECATED
  "Private Sector Engager":
    "https://d10ba5vkyum61s.cloudfront.net/img/kol-types/private-sector.svg",
  "Rising Star":
    "https://d10ba5vkyum61s.cloudfront.net/img/kol-types/rising-star.svg",
  "Research Scholar":
    "https://d10ba5vkyum61s.cloudfront.net/img/kol-types/subject-relevancy.svg"
};

const BADGECONTAINER = styled.div`
  position: relative;
  display: inline-block;
`;

interface KOLBadgesProps {
  types: KOLType[];
}

export const kolBadges = (props: KOLBadgesProps) => {
  if (!props || !props.types.length) return <></>;
  const types = props.types.map((type, i) => {
    return (
      <BADGECONTAINER key={i}>
        <HoverTooltip content={<B1 darkBackground>{type}</B1>}>
          <img style={{ margin: "2px" }} key={i} src={badgeImages[type]} />
        </HoverTooltip>
      </BADGECONTAINER>
    );
  });
  return <div>{types}</div>;
};
