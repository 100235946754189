import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducersObj } from "./reducers";

const STORAGE_KEY = process.env.STORAGE_KEY || "saved";

const rootPersistConfig = {
  key: STORAGE_KEY,
  storage,
  blacklist: [
    "config",
    "kolCards",
    "searchResults",
    "searchFilters",
    "growlers"
  ]
};

const searchResultsPersistConfig = {
  key: "searchResults",
  storage,
  whitelist: ["documentSearchResults"]
};

const searchFiltersPersistConfig = {
  key: "searchFilters",
  storage,
  blacklist: [
    "initialOptions",
    "initialOptionsLoaded",
    "customSortingOptions",
    "customSortingOptionsLoaded",
    "customSortModalOpen",
    "sortBy"
  ]
};

export default persistReducer(
  rootPersistConfig,
  combineReducers({
    ...reducersObj,
    searchResults: persistReducer(
      searchResultsPersistConfig,
      reducersObj.searchResults
    ),
    searchFilters: persistReducer(
      searchFiltersPersistConfig,
      reducersObj.searchFilters
    )
  })
);
