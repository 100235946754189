import * as React from "react";

import { NavBarVariant } from "./NavBarVariant";
import { SecondaryNavLink } from "./SecondaryNavLink";
import { DropDownContent } from "./DropDownContent";

interface NavbarDropdownProps {
  variant: NavBarVariant;
  title: string;
}

interface NavbarDropdownState {
  open: boolean;
}

export class NavbarDropdown extends React.Component<
  NavbarDropdownProps,
  NavbarDropdownState
> {
  state = {
    open: false
  };

  toggleDropdown = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div
        className="profile-dropdown-link"
        onMouseEnter={this.toggleDropdown}
        onMouseLeave={this.toggleDropdown}
        onClick={this.toggleDropdown}
        style={{ position: "relative", display: "flex" }}
      >
        <SecondaryNavLink
          variant={this.props.variant}
          active={this.state.open}
          parentOwnsHover={true}
          isHover={this.state.open}
          to="#"
        >
          {this.props.title}
        </SecondaryNavLink>

        {this.state.open ? (
          <DropDownContent variant={this.props.variant}>
            {this.props.children}
          </DropDownContent>
        ) : null}
      </div>
    );
  }
}
