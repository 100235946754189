import * as React from "react";
import { styled, css } from "@h1eng/ui-components";

interface TextProps {
  fullWidth?: boolean;
  fontSize?: number;
  bold?: boolean;
  [key: string]: any;
}

const TextRoot = styled.div`
  font-family: Montserrat;

  ${(props: TextProps) =>
    css`
      width: ${props.fullWidth ? "100%" : "inherit"};
      font-size: ${props.fontSize || 12}px;
      font-weight: ${(props.bold && 600) || "normal"};
    `}
`;

export const Text: React.SFC<TextProps> = props => {
  const { children, ...textProps } = props;

  return !!children || children === 0 ? (
    <TextRoot {...textProps}>{children}</TextRoot>
  ) : null;
};
