import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 2px solid #e8e6e1;
  border-radius: 8px;
  padding: 24px;
`;

export const CardWrapper: React.SFC<any> = props => <Root {...props} />;
