// @ts-ignore
import * as React from "react";
import { styled } from "./themes/styled-components";
import { themeColors } from "./Colors";

interface HeaderStyleInterface {
  color?: string;
}

const factoryString = (fontSize: number, fontWeight: number) => `
  font-size: ${fontSize}px;
  font-family: Montserrat;
  color: ${(props: any) => props.color};
  font-weight: ${fontWeight};
  margin: 0;
`;

export const H1_FACTORY = styled.h1<HeaderStyleInterface>`
  ${factoryString(68, 300)};
`;

export const H2_FACTORY = styled.h2<HeaderStyleInterface>`
  ${factoryString(42, 300)};
`;

export const H3_FACTORY = styled.h3<HeaderStyleInterface>`
  ${factoryString(26, 500)};
`;

export const H4_FACTORY = styled.h4<HeaderStyleInterface>`
  ${factoryString(18, 600)};
`;

export const H5_FACTORY = styled.h5<HeaderStyleInterface>`
  ${factoryString(12, 600)};
`;

interface HeaderInterface {
  deEmphasized?: boolean;
  darkBackground?: boolean;
  color?: string;
}

const genHeader = (Factory: any) => (props: any) => {
  if (props.deEmphasized) {
    return (
      <Factory color={themeColors.themeHeadingsDeEmphasized}>
        {props.children}
      </Factory>
    );
  }

  if (props.darkBackground) {
    return (
      <Factory color={themeColors.themeHeadingsDarkBackground}>
        {props.children}
      </Factory>
    );
  }

  if (props.color) {
    return <Factory color={props.color}>{props.children}</Factory>;
  }

  return (
    <Factory color={themeColors.themeHeadingsDefualt}>{props.children}</Factory>
  );
};
const SYYLEDH5DEM = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  color: #737373;
`;

const SYYLEDH5DARK = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  color: ${themeColors.themeHeadingsDarkBackground};
`;

const SYYLEDH5 = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  color: ${themeColors.themeHeadingsDefualt};
`;

export const H1: React.SFC<HeaderInterface> = genHeader(H1_FACTORY);
export const H2: React.SFC<HeaderInterface> = genHeader(H2_FACTORY);
export const H3: React.SFC<HeaderInterface> = genHeader(H3_FACTORY);
export const H4: React.SFC<HeaderInterface> = genHeader(H4_FACTORY);
export const H5: React.SFC<HeaderInterface> = (props: any) => {
  if (props.deEmphasized) return <SYYLEDH5DEM>{props.children}</SYYLEDH5DEM>;

  if (props.darkBackground) {
    return <SYYLEDH5DARK>{props.children}</SYYLEDH5DARK>;
  }

  return <SYYLEDH5>{props.children}</SYYLEDH5>;
};
