import { getType } from "typesafe-actions";
import {
  createGrowler,
  GrowlerInterface,
  GrowlerAction,
  removeGrowler,
  findAndClose
} from "../actions/growler";

export interface GrowlerState {
  growlers: { [id: number]: GrowlerInterface };
}
const initialState = {
  growlers: [] as any
} as GrowlerState;

export const growlerReducer = (
  state: GrowlerState = initialState,
  action: GrowlerAction
): GrowlerState => {
  switch (action.type) {
    case getType(createGrowler): {
      const newGrowler: GrowlerInterface = action.payload as GrowlerInterface;
      const id = Object.keys(state.growlers).length;
      return {
        ...state,
        growlers: {
          ...state.growlers,
          [id]: newGrowler
        }
      };
    }

    case getType(removeGrowler): {
      // issue with
      const idToRemove = Number.parseInt(action.payload.toString(), 10);
      const hardCopyStateGrowler = JSON.parse(JSON.stringify(state.growlers));
      delete hardCopyStateGrowler[idToRemove];

      return {
        ...state,
        growlers: hardCopyStateGrowler
      };
    }
    case getType(findAndClose): {
      const growlerToRemove: GrowlerInterface = action.payload as GrowlerInterface;
      const stateCopy = Object.assign({}, state, {});
      const growlersInStore = Object.values(stateCopy.growlers);
      const newGroweler = growlersInStore.filter(e => growlerToRemove !== e);
      return { ...state, growlers: newGroweler };
    }

    default:
      return state;
  }
};
