import * as React from "react";
import { connect } from "react-redux";
import { styled, PRIMARY_BUTTON } from "../../../../ui-components/dist";
import { RootState } from "../../store/reducers";
const CARD = styled.div`
  display: flex;
  flex-direction: column;
  width: 472px;
  border-radius: 1px;
  box-shadow: 0 9.5px 19px 0 rgba(0, 0, 0, 0.3),
    0 7.5px 6px 0 rgba(0, 0, 0, 0.22);

  background-image: linear-gradient(#fafafa, #fafafa),
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.4) 5%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0)
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const CARD_HEARD = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  align-items: center;
  background: #060464;
`;
const SAVE_TO_LIST = styled.div`
  font-size: 20px;
  font-family: Montserrat;
  margin-left: 15px;
  color: white;
`;

const X_CONTAINER = styled.div`
  margin-right: 15px;
  cursor: pointer;
  cursor: hand;
`;
const MESSAGE = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  color: #333333;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px

  padding-right: 40px;
  padding-left: 40px;
`;
const TEXT_SEARCH_INPUT = styled.input<{ color: string }>`
  border: 0;
  outline: 0;
  background: transparent;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid #060464;
  width: 70%
  margin-top: 20px;
  text-align:left;
  padding-right: 40px;
  padding-left: 40px;
`;

const BUTTON_CONTAINER = styled.div`
  margin-top: 20px;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
`;

const CANCEL_CONTAINER = styled.div`
  margin-top: 10px;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
`;
const BOLD_MESSAGE = styled.div`
  margin-top: 40px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 10px;
  cursor: pointer;
  cursor: hand;
  text-align: center;
`;

const CANCEL = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  color: #0aaacd;
  padding-bottom: 30px;
  cursor: pointer;
  cursor: hand;
`;
export interface ModalMessageProps {
  title: string;
  message?: string;
  buttonText: string;
  boldText?: string;
  dontRenderCancel?: boolean;
  onClose: () => void;
  action: (...args: any[]) => void;
}

/* tslint:disable:no-empty-interface */
interface ModalMessageState {}
/* tslint:enable:no-empty-interface */
type Props = ModalMessageProps & RootState & DispatchFromProps;
export class ModalMessageClass extends React.Component<
  Props,
  ModalMessageState
> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <CARD>
        <CARD_HEARD>
          <SAVE_TO_LIST>{this.props.title}</SAVE_TO_LIST>
          <X_CONTAINER>
            <img
              src={"https://d10ba5vkyum61s.cloudfront.net/img/xclose-white.svg"}
              onClick={this.props.onClose}
            />
          </X_CONTAINER>
        </CARD_HEARD>
        <BOLD_MESSAGE>{this.props.boldText}</BOLD_MESSAGE>
        <MESSAGE>{this.props.message}</MESSAGE>
        <BUTTON_CONTAINER>
          <PRIMARY_BUTTON
            onClick={() => {
              this.props.action();
            }}
          >
            {this.props.buttonText}
          </PRIMARY_BUTTON>
        </BUTTON_CONTAINER>
        <CANCEL_CONTAINER>
          {!this.props.dontRenderCancel && (
            <CANCEL onClick={this.props.onClose}>CANCEL</CANCEL>
          )}
        </CANCEL_CONTAINER>
      </CARD>
    );
  }
}
export const mapStateToProps = (state: any) => state;

interface StateFromProps {
  label: string;
}

/* tslint:disable:no-empty-interface */
interface DispatchFromProps {}
/* tslint:enable:no-empty-interface */

export const mapDispatchToProps = {};

export const ModalMessage = connect<DispatchFromProps, ModalMessageProps>(
  mapStateToProps,
  mapDispatchToProps
)(ModalMessageClass);
