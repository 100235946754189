/* tslint:disable:react-unused-props-and-state */
import * as React from "react";
import { styled } from "@h1eng/ui-components";
import ClickOutsideListener from "./ClickOutsideListener";
import isNode from "../utils/isNode";

const MODAL_BACKGROUND = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10000000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.33);
`;

const MODAL_CONTENT = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
`;

const MODAL_CONTENT_AUTO = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  max-height: 500px;
  overflow-y: auto;
`;

const HOVER = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const images = isNode
  ? {}
  : {
      xclose: "https://d10ba5vkyum61s.cloudfront.net/img/xclose-white.svg"
    };

interface ModalProps {
  open: boolean;
  onClose: () => any;
}

export interface ModalState {
  open: boolean;
}

interface ModalXProps {
  onClick: () => any;
}

export const MODAL_X: React.SFC<ModalXProps> = props => {
  return (
    <HOVER>
      <img onClick={props.onClick} src={images.xclose} />
    </HOVER>
  );
};

export const MODAL: React.SFC<ModalProps> = props => {
  if (!props.open) return null;

  return (
    <MODAL_BACKGROUND>
      <ClickOutsideListener onClickOutside={props.onClose}>
        <MODAL_CONTENT>{props.children}</MODAL_CONTENT>
      </ClickOutsideListener>
    </MODAL_BACKGROUND>
  );
};
export const MODAL_SMALL: React.SFC<ModalProps> = props => {
  if (!props.open) return null;

  return (
    <MODAL_BACKGROUND>
      <ClickOutsideListener onClickOutside={props.onClose}>
        <MODAL_CONTENT_AUTO>{props.children}</MODAL_CONTENT_AUTO>
      </ClickOutsideListener>
    </MODAL_BACKGROUND>
  );
};
