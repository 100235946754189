import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { ListMeta } from "../../components/curie/ListManagement/ListManagementInterfaces";
import { paginate } from "@h1eng/pagination";

const getState = (state: RootState) => state;
export const getListState = (state: RootState) => state.lists;

export const getAvailableLists = createSelector(
  getListState,
  listsState => listsState.listAvailable
);

export const getAvailableListsLoaded = createSelector(
  getListState,
  listsState => listsState.listAvailableLoaded
);

export const getLists = createSelector(
  getListState,
  listsState => listsState.lists
);

export const getSelectedListId = createSelector(
  getListState,
  listsState => listsState.selectedId
);

/**
 * Gets available lists other than the currently selected list
 */
export const getOtherAvailableLists = createSelector(
  getListState,
  listsState =>
    listsState.listAvailable.filter(i => i.id !== listsState.selectedId)
);

export function getPersonIdsInListByListId(
  state: RootState,
  listId?: string | null
): string[] {
  const selectedId = listId !== undefined ? listId : state.lists.selectedId;

  if (selectedId === null) return [];

  const list = state.lists.listAvailable.find(i => i.id === selectedId);

  if (!list) return [];

  return (list as ListMeta & {
    listPersonJoins: { id: string; personId: string }[];
  }).listPersonJoins.map(i => i.personId);
}

export const getSelectedListPersonIdsForCurrentPage = createSelector(
  getState,
  state => {
    return paginate({
      pageNum: state.lists.savedListPageNum,
      pageSize: state.lists.savedListPageSize,
      items: getPersonIdsInListByListId(state)
    });
  }
);

export const getSelectedListPersonIds = createSelector(
  getState,
  (state): string[] => {
    return getPersonIdsInListByListId(state);
  }
);

export const getSavedListPageNum = createSelector(
  getState,
  (state): number => state.lists.savedListPageNum
);

export const getSavedListPageSize = createSelector(
  getState,
  (state): number => state.lists.savedListPageSize
);
