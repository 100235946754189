import { NavBarVariant } from "./NavBarVariant";
import * as React from "react";
import { StyledNavLinkProps } from "./StyledNavLinkProps";
import { Dictionary } from "lodash";
import { LinkTheme } from "./LinkTheme";
import { BaseNavLinkFactoryProps } from "./BaseNavLinkFactoryProps";
import { BaseNavLink } from "./BaseNavLink";
import { getBackgroundColor, getColor } from "./colorGen";

const colors: Dictionary<LinkTheme> = {
  [NavBarVariant.DEFAULT]: {
    idle: "#060464",
    hover: "white",
    active: "#060464",
    backgroundIdle: "none",
    backgroundHover: "#060464",
    backgroundActive: "none"
  },
  [NavBarVariant.GRAY]: {
    idle: "#737373",
    hover: "#555",
    active: "#333",
    backgroundIdle: "none",
    backgroundHover: "#ededed",
    backgroundActive: "none"
  },
  [NavBarVariant.DARK]: {
    idle: "white",
    hover: "#aeffde",
    active: "white",
    backgroundIdle: "none",
    backgroundHover: "rgba(60,60,120,1)",
    backgroundActive: "none"
  }
};

export const SecondaryNavLink: React.SFC<StyledNavLinkProps> = props => {
  const color = getColor(props, colors);
  const backgroundColor = getBackgroundColor(props, colors);

  const linkProps: BaseNavLinkFactoryProps = {
    to: props.to,
    parentOwnsHover: props.parentOwnsHover || false,
    underline: "none",
    color,
    backgroundColor,
    hoverColor: colors[props.variant].hover,
    hoverBackgroundColor: colors[props.variant].backgroundHover
  };

  return <BaseNavLink {...linkProps}>{props.children}</BaseNavLink>;
};
