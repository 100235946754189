import * as React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import { connect } from "react-redux";
import { setCustomSortModalOpen } from "../../../../../store/actions";
import { RootState } from "../../../../../store/reducers";

interface MappedStateProps {
  loaded: boolean;
}

interface DispatchProps {
  setModalOpen: (open: boolean) => void;
}

const CustomSortButtonComponent: React.FunctionComponent<
  MappedStateProps & DispatchProps
> = props => {
  return (
    <Tooltip title="Manage Custom Sorting">
      <IconButton onClick={() => props.setModalOpen(true)}>
        <SortIcon color="inherit" fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  loaded: state.searchFilters.customSortingOptionsLoaded
});

const mapDispatchToProps: DispatchProps = {
  setModalOpen: setCustomSortModalOpen
};

export const CustomSortButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSortButtonComponent);
