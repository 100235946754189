/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { styled } from "@h1eng/ui-components";
import {
  CompanyPaymentBreakDown,
  TermsCompany,
  TermsDrug,
  TermsAmount,
  TermsDate
} from "./PaymentsTableGroup";
import { formatStats } from "@h1eng/format-util";

export const OpenClose = styled.div`
  width: 40%;
  min-width: 40%;
  display: flex;
  &:hover {
    cursor: pointer;
    color: #0aaacd;
  }
`;

export const Nature = styled.div`
  width: 40%;
  min-width: 40%;
  display: flex;
`;

const font = {
  color: "#333333",
  fontFamily: "Montserrat",
  fontSize: "14px"
};
export class PaymentTableRow extends React.Component<
  {
    breakDown: CompanyPaymentBreakDown;
    groupIndex: number;
    sizeOfGroup: number;
  },
  { expanded: boolean }
> {
  state = { expanded: false };
  renderClosed() {
    const { breakDown, groupIndex, sizeOfGroup } = this.props;
    const date =
      breakDown.startDate && breakDown.endDate
        ? new Date(breakDown.startDate).getFullYear() ===
          new Date(breakDown.endDate).getFullYear()
          ? new Date(breakDown.startDate).getFullYear()
          : `${new Date(breakDown.startDate).getFullYear()} - ${new Date(
              breakDown.endDate
            ).getFullYear()}`
        : breakDown.startDate
        ? new Date(breakDown.startDate).getFullYear()
        : "";
    return (
      <div
        style={{
          ...font,
          display: "flex",
          width: "100%"
        }}
      >
        <TermsCompany>{groupIndex === 0 ? breakDown.company : ""}</TermsCompany>
        <OpenClose
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >
          {breakDown.drugOrDeviceName !== "null"
            ? breakDown.drugOrDeviceName
            : "Unknown"}
        </OpenClose>
        <TermsAmount>${formatStats(breakDown.amount)}</TermsAmount>
        <TermsDate>{date}</TermsDate>
      </div>
    );
  }
  renderOpen(breakDown: CompanyPaymentBreakDown) {
    const { groupIndex, sizeOfGroup } = this.props;
    const keys = Object.keys(breakDown.treatments.natureOfPayments).sort(
      (a, b) => {
        return (
          breakDown.treatments.natureOfPayments[b].amount -
          breakDown.treatments.natureOfPayments[a].amount
        );
      }
    );
    const date =
      breakDown.startDate && breakDown.endDate
        ? new Date(breakDown.startDate).getFullYear() ===
          new Date(breakDown.endDate).getFullYear()
          ? new Date(breakDown.startDate).getFullYear()
          : `${new Date(breakDown.startDate).getFullYear()} - ${new Date(
              breakDown.endDate
            ).getFullYear()}`
        : breakDown.startDate
        ? new Date(breakDown.startDate).getFullYear()
        : "";

    return (
      <div
        style={{
          ...font,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%"
        }}
      >
        <div
          style={{
            ...font,
            display: "flex",

            // borderBottom: "2px solid rgb(246, 246, 246)",
            width: "100%"
          }}
        >
          <TermsCompany>
            {groupIndex === 0 ? breakDown.company : ""}
          </TermsCompany>
          <OpenClose
            onClick={() => this.setState({ expanded: !this.state.expanded })}
          >
            {breakDown.drugOrDeviceName !== "null"
              ? breakDown.drugOrDeviceName
              : "Unknown"}
          </OpenClose>
          <TermsAmount>${formatStats(breakDown.amount)}</TermsAmount>
          <TermsDate>{date}</TermsDate>
        </div>

        {keys.map((e, i) => {
          const borderStyle =
            i === keys.length - 1 && groupIndex !== sizeOfGroup - 1
              ? {
                  borderBottom: "2px solid rgb(246, 246, 246)",
                  paddingBottom: "10px"
                }
              : {};
          return (
            <div
              key={`item-${e}-${i}`}
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                ...font,
                display: "flex",
                width: "100%"
              }}
            >
              <TermsCompany />
              <Nature style={borderStyle}>
                <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                  {e}
                </div>
              </Nature>
              <TermsAmount style={borderStyle}>
                ${formatStats(breakDown.treatments.natureOfPayments[e].amount)}
              </TermsAmount>
              <TermsDate style={borderStyle} />
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { breakDown, groupIndex, sizeOfGroup } = this.props;

    return this.state.expanded
      ? this.renderOpen(breakDown)
      : this.renderClosed();
  }
}
