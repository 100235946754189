import isNode from "../../../../../utils/isNode";

export const images = isNode
  ? {}
  : {
      availableList:
        "https://d10ba5vkyum61s.cloudfront.net/img/list-management/available-list.svg",
      newList:
        "https://d10ba5vkyum61s.cloudfront.net/img/list-management/new-list.svg",
      saveSuccess:
        "https://d10ba5vkyum61s.cloudfront.net/img/list-management/save-success.svg",
      saveToList:
        "https://d10ba5vkyum61s.cloudfront.net/img/list-management/save-to-list.svg"
    };
