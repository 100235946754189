import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const getDocumentSearchBarState = (state: RootState) =>
  state.documentSearchBar;

export const getDocumentSearchBarQurey = createSelector(
  getDocumentSearchBarState,
  searchBar => searchBar.query
);
export const getDocumentSearchBarPersonId = createSelector(
  getDocumentSearchBarState,
  searchBar => searchBar.personId
);

export const getDocumentSearchBarFilterDate = createSelector(
  getDocumentSearchBarState,
  searchBar => searchBar.filterDate
);
export const getDocumentSearchBarFilterDateDisplayText = createSelector(
  getDocumentSearchBarState,
  searchBar => searchBar.filterDateText
);
