import * as React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { getInitialOptionsLoaded } from "../../../store/selectors";
import { RootState } from "../../../store/reducers";
import { Autosuggest, AutosuggestComponentProps } from "../common/Autosuggest";

interface MappedStateProps {
  disabled: boolean;
}

const styles = createStyles({
  inputLabel: {
    fontSize: 14,
    color: "#435d6b"
  }
});

type Props = MappedStateProps &
  AutosuggestComponentProps &
  WithStyles<typeof styles>;

type FilterSet = [string, string];

export const filteredCharacters: FilterSet[] = [["â€“", "—"]];

const optionTextFormatter = (text: string) => {
  let res: string = text;
  let regex: RegExp;

  for (const [match, replace] of filteredCharacters) {
    regex = new RegExp(match);

    res = res.replace(regex, replace);
  }

  return res;
};

const FilterAutosuggestComponent: React.FunctionComponent<Props> = ({
  classes,
  inputLabel,
  ...props
}: Props) => {
  return (
    <Autosuggest
      {...props}
      inputLabel={
        typeof inputLabel === "string" ? (
          <span className={classes.inputLabel}>{inputLabel}</span>
        ) : (
          inputLabel
        )
      }
      optionTextFormatter={optionTextFormatter}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  disabled: !getInitialOptionsLoaded(state)
});

export const FilterAutosuggest = connect(mapStateToProps)(
  withStyles(styles)(FilterAutosuggestComponent)
);
