import {
  createAsyncAction,
  createStandardAction,
  ActionType
} from "typesafe-actions";
import {
  TextFilter,
  CheckboxFilter,
  SearchFilterOptions,
  SortBy,
  CustomSortByConstructor,
  CustomSortBy
} from "@h1eng/interfaces";

export type AdvancedFilterSections =
  | "pubs"
  | "trials"
  | "congresses"
  | "payments"
  | "grants";

// General
export const clearSearchFilters = createStandardAction("searchFilters.reset")<
  never
>();
export const setShowAdvancedFilters = createStandardAction(
  "searchFilters.advanced.show"
)<boolean>();
export const getInitialFilterOptions = createAsyncAction(
  "searchFilters.initialOptions.request",
  "searchFilters.initialOptions.success",
  "searchFilters.initialOptions.failure"
)<never, SearchFilterOptions, Error>();
export const resetInitialOptions = createStandardAction(
  "searchFilters.initialOptions.reset"
)<never>();
export const setInitialOptionsLoaded = createStandardAction(
  "initialOptionsLoaded.set"
)<boolean>();
export const setAdvancedFilterSectionsOpen = createStandardAction(
  "advancedFilterSections.open.setAll"
)<AdvancedFilterSections[]>();
export const toggleAdvancedFilterSection = createStandardAction(
  "advancedFilterSections.open.toggleOne"
)<AdvancedFilterSections>();

export const setQuery = createStandardAction("searchFilters.query.set")<
  string
>();

export const searchFromStoreState = createStandardAction(
  "searchFilters.search.withStoreData"
)<never>();

// Sort By
export const setSortBy = createStandardAction("searchFilters.sortBy.value.set")<
  SortBy
>();
export const setCustomSortModalOpen = createStandardAction(
  "searchFilters.sortBy.modal.setOpen"
)<boolean>();
export const setCustomSortingOptionsLoaded = createStandardAction(
  "searchFilters.sortBy.options.loaded"
)<boolean>();
export const loadCustomSortingOptions = createAsyncAction(
  "searchFilters.sortBy.customEntity.fetch.request",
  "searchFilters.sortBy.customEntity.fetch.success",
  "searchFilters.sortBy.customEntity.fetch.failure"
)<any, CustomSortBy[], Error>();
export const createCustomSortingOption = createAsyncAction(
  "searchFilters.sortBy.customEntity.create.request",
  "searchFilters.sortBy.customEntity.create.success",
  "searchFilters.sortBy.customEntity.create.failure"
)<CustomSortByConstructor, CustomSortBy[], Error>();
export const editCustomSortingOption = createAsyncAction(
  "searchFilters.sortBy.customEntity.edit.request",
  "searchFilters.sortBy.customEntity.edit.success",
  "searchFilters.sortBy.customEntity.edit.failure"
)<CustomSortBy, CustomSortBy[], Error>();
/**
 * Pass the id to delete
 */
export const deleteCustomSortingOption = createAsyncAction(
  "searchFilters.sortBy.customEntity.delete.request",
  "searchFilters.sortBy.customEntity.delete.success",
  "searchFilters.sortBy.customEntity.delete.failure"
)<string, CustomSortBy[], Error>();

// Specialty
export const setSpecialtyQuery = createStandardAction(
  `searchFilters.specialty.query.set`
)<string>();
export const setSpecialtyValues = createStandardAction(
  `searchFilters.specialty.values.set`
)<string[]>();
export const requestSpecialtyOptions = createAsyncAction(
  `searchFilters.specialty.options.request`,
  `searchFilters.specialty.options.success`,
  `searchFilters.specialty.options.failure`
)<TextFilter, string[], Error>();

// Country
export const setCountryQuery = createStandardAction(
  `searchFilters.country.query.set`
)<string>();
export const setCountryValues = createStandardAction(
  `searchFilters.country.values.set`
)<string[]>();
export const requestCountryOptions = createAsyncAction(
  `searchFilters.country.options.request`,
  `searchFilters.country.options.success`,
  `searchFilters.country.options.failure`
)<TextFilter, string[], Error>();

// State
export const setStateQuery = createStandardAction(
  `searchFilters.state.query.set`
)<string>();
export const setStateValues = createStandardAction(
  `searchFilters.state.values.set`
)<string[]>();
export const requestStateOptions = createAsyncAction(
  `searchFilters.state.options.request`,
  `searchFilters.state.options.success`,
  `searchFilters.state.options.failure`
)<TextFilter, string[], Error>();

// Institution
export const setInstitutionQuery = createStandardAction(
  `searchFilters.institution.query.set`
)<string>();
export const setInstitutionValues = createStandardAction(
  `searchFilters.institution.values.set`
)<string[]>();
export const requestInstitutionOptions = createAsyncAction(
  `searchFilters.institution.options.request`,
  `searchFilters.institution.options.success`,
  `searchFilters.institution.options.failure`
)<TextFilter, string[], Error>();

// Publications
export const setPublicationMinCount = createStandardAction(
  "searchFilters.publications.minCount.set"
)<number | null>();

export const setPublicationJournalQuery = createStandardAction(
  `searchFilters.publications.journal.query.set`
)<string>();
export const setPublicationJournalValues = createStandardAction(
  `searchFilters.publications.journal.values.set`
)<string[]>();
export const requestPublicationJournalOptions = createAsyncAction(
  `searchFilters.publications.journal.options.request`,
  `searchFilters.publications.journal.options.success`,
  `searchFilters.publications.journal.options.failure`
)<TextFilter, string[], Error>();

export const setPublicationTypeQuery = createStandardAction(
  `searchFilters.publications.type.query.set`
)<string>();
export const setPublicationTypeValues = createStandardAction(
  `searchFilters.publications.type.values.set`
)<string[]>();
export const requestPublicationTypeOptions = createAsyncAction(
  `searchFilters.publications.type.options.request`,
  `searchFilters.publications.type.options.success`,
  `searchFilters.publications.type.options.failure`
)<TextFilter, string[], Error>();

// Clinical Trials
export const setTrialMinCount = createStandardAction(
  "searchFilters.trials.minCount.set"
)<number | null>();

export const setTrialStatusQuery = createStandardAction(
  `searchFilters.trials.status.query.set`
)<string>();
export const setTrialStatusValues = createStandardAction(
  `searchFilters.trials.status.values.set`
)<string[]>();
export const requestTrialStatusOptions = createAsyncAction(
  `searchFilters.trials.status.options.request`,
  `searchFilters.trials.status.options.success`,
  `searchFilters.trials.status.options.failure`
)<TextFilter, string[], Error>();

export const setTrialPhaseQuery = createStandardAction(
  `searchFilters.trials.phase.query.set`
)<string>();
export const setTrialPhaseValues = createStandardAction(
  `searchFilters.trials.phase.values.set`
)<string[]>();
export const requestTrialPhaseOptions = createAsyncAction(
  `searchFilters.trials.phase.options.request`,
  `searchFilters.trials.phase.options.success`,
  `searchFilters.trials.phase.options.failure`
)<TextFilter, string[], Error>();

export const setTrialStudyTypeQuery = createStandardAction(
  `searchFilters.trials.studyType.query.set`
)<string>();
export const setTrialStudyTypeValues = createStandardAction(
  `searchFilters.trials.studyType.values.set`
)<string[]>();
export const requestTrialStudyTypeOptions = createAsyncAction(
  `searchFilters.trials.studyType.options.request`,
  `searchFilters.trials.studyType.options.success`,
  `searchFilters.trials.studyType.options.failure`
)<TextFilter, string[], Error>();

export const setTrialFunderTypeQuery = createStandardAction(
  `searchFilters.trials.funderType.query.set`
)<string>();
export const setTrialFunderTypeValues = createStandardAction(
  `searchFilters.trials.funderType.values.set`
)<string[]>();
export const requestTrialFunderTypeOptions = createAsyncAction(
  `searchFilters.trials.funderType.options.request`,
  `searchFilters.trials.funderType.options.success`,
  `searchFilters.trials.funderType.options.failure`
)<TextFilter, string[], Error>();

export const setTrialSponsorQuery = createStandardAction(
  `searchFilters.trials.sponsor.query.set`
)<string>();
export const setTrialSponsorValues = createStandardAction(
  `searchFilters.trials.sponsor.values.set`
)<string[]>();
export const requestTrialSponsorOptions = createAsyncAction(
  `searchFilters.trials.sponsor.options.request`,
  `searchFilters.trials.sponsor.options.success`,
  `searchFilters.trials.sponsor.options.failure`
)<TextFilter, string[], Error>();

// Congresses
export const setCongressMinCount = createStandardAction(
  "searchFilters.congress.minCount.set"
)<number | null>();

export const setCongressNameQuery = createStandardAction(
  `searchFilters.congress.name.query.set`
)<string>();
export const setCongressNameValues = createStandardAction(
  `searchFilters.congress.name.values.set`
)<string[]>();
export const requestCongressNameOptions = createAsyncAction(
  `searchFilters.congress.name.options.request`,
  `searchFilters.congress.name.options.success`,
  `searchFilters.congress.name.options.failure`
)<TextFilter, string[], Error>();

export const setCongressTypeValues = createStandardAction(
  `searchFilters.congress.type.values.set`
)<string[]>();
export const requestCongressTypeOptions = createAsyncAction(
  `searchFilters.congress.type.options.request`,
  `searchFilters.congress.type.options.success`,
  `searchFilters.congress.type.options.failure`
)<CheckboxFilter, string[], Error>();

// Grants
export const setGrantMinAmount = createStandardAction(
  "searchFilters.grant.minAmount.set"
)<number | null>();

export const setGrantFunderQuery = createStandardAction(
  `searchFilters.grant.funder.query.set`
)<string>();
export const setGrantFunderValues = createStandardAction(
  `searchFilters.grant.funder.values.set`
)<string[]>();
export const requestGrantFunderOptions = createAsyncAction(
  `searchFilters.grant.funder.options.request`,
  `searchFilters.grant.funder.options.success`,
  `searchFilters.grant.funder.options.failure`
)<TextFilter, string[], Error>();

// Payments
export const setPaymentMinAmount = createStandardAction(
  "searchFilters.payment.minAmount.set"
)<number | null>();

export const setPaymentCompanyQuery = createStandardAction(
  `searchFilters.payment.company.query.set`
)<string>();
export const setPaymentCompanyValues = createStandardAction(
  `searchFilters.payment.company.values.set`
)<string[]>();
export const requestPaymentCompanyOptions = createAsyncAction(
  `searchFilters.payment.company.options.request`,
  `searchFilters.payment.company.options.success`,
  `searchFilters.payment.company.options.failure`
)<TextFilter, string[], Error>();

export const setPaymentDrugOrDeviceQuery = createStandardAction(
  `searchFilters.payment.drugOrDevice.query.set`
)<string>();
export const setPaymentDrugOrDeviceValues = createStandardAction(
  `searchFilters.payment.drugOrDevice.values.set`
)<string[]>();
export const requestPaymentDrugOrDeviceOptions = createAsyncAction(
  `searchFilters.payment.drugOrDevice.options.request`,
  `searchFilters.payment.drugOrDevice.options.success`,
  `searchFilters.payment.drugOrDevice.options.failure`
)<TextFilter, string[], Error>();

export const setPaymentFundingTypeQuery = createStandardAction(
  `searchFilters.payment.fundingType.query.set`
)<string>();
export const setPaymentFundingTypeValues = createStandardAction(
  `searchFilters.payment.fundingType.values.set`
)<string[]>();
export const requestPaymentFundingTypeOptions = createAsyncAction(
  `searchFilters.payment.fundingType.options.request`,
  `searchFilters.payment.fundingType.options.success`,
  `searchFilters.payment.fundingType.options.failure`
)<TextFilter, string[], Error>();

export type SearchFilterAction =
  | ActionType<typeof searchFromStoreState>
  | ActionType<typeof setSpecialtyValues>
  | ActionType<typeof requestSpecialtyOptions>
  | ActionType<typeof getInitialFilterOptions>
  | ActionType<typeof setInitialOptionsLoaded>
  | ActionType<typeof setAdvancedFilterSectionsOpen>
  | ActionType<typeof toggleAdvancedFilterSection>
  | ActionType<typeof setSortBy>
  | ActionType<typeof setCustomSortModalOpen>
  | ActionType<typeof setCustomSortingOptionsLoaded>
  | ActionType<typeof loadCustomSortingOptions>
  | ActionType<typeof createCustomSortingOption>
  | ActionType<typeof editCustomSortingOption>
  | ActionType<typeof deleteCustomSortingOption>
  | ActionType<typeof setQuery>
  | ActionType<typeof resetInitialOptions>
  | ActionType<typeof setSpecialtyQuery>
  | ActionType<typeof setCountryQuery>
  | ActionType<typeof setCountryValues>
  | ActionType<typeof requestCountryOptions>
  | ActionType<typeof setStateQuery>
  | ActionType<typeof setStateValues>
  | ActionType<typeof requestStateOptions>
  | ActionType<typeof setInstitutionQuery>
  | ActionType<typeof setInstitutionValues>
  | ActionType<typeof requestInstitutionOptions>
  | ActionType<typeof clearSearchFilters>
  | ActionType<typeof setShowAdvancedFilters>
  | ActionType<typeof setPublicationMinCount>
  | ActionType<typeof setPublicationJournalQuery>
  | ActionType<typeof setPublicationJournalValues>
  | ActionType<typeof requestPublicationJournalOptions>
  | ActionType<typeof setPublicationTypeQuery>
  | ActionType<typeof setPublicationTypeValues>
  | ActionType<typeof requestPublicationTypeOptions>
  | ActionType<typeof setTrialMinCount>
  | ActionType<typeof setTrialStatusQuery>
  | ActionType<typeof setTrialStatusValues>
  | ActionType<typeof requestTrialStatusOptions>
  | ActionType<typeof setTrialPhaseQuery>
  | ActionType<typeof setTrialPhaseValues>
  | ActionType<typeof requestTrialPhaseOptions>
  | ActionType<typeof setTrialStudyTypeQuery>
  | ActionType<typeof setTrialStudyTypeValues>
  | ActionType<typeof requestTrialStudyTypeOptions>
  | ActionType<typeof setTrialFunderTypeQuery>
  | ActionType<typeof setTrialFunderTypeValues>
  | ActionType<typeof requestTrialFunderTypeOptions>
  | ActionType<typeof setTrialSponsorQuery>
  | ActionType<typeof setTrialSponsorValues>
  | ActionType<typeof requestTrialSponsorOptions>
  | ActionType<typeof setCongressMinCount>
  | ActionType<typeof setCongressNameQuery>
  | ActionType<typeof setCongressNameValues>
  | ActionType<typeof requestCongressNameOptions>
  | ActionType<typeof requestCongressTypeOptions>
  | ActionType<typeof setCongressTypeValues>
  | ActionType<typeof setGrantMinAmount>
  | ActionType<typeof setGrantFunderQuery>
  | ActionType<typeof setGrantFunderValues>
  | ActionType<typeof requestGrantFunderOptions>
  | ActionType<typeof setPaymentMinAmount>
  | ActionType<typeof setPaymentCompanyQuery>
  | ActionType<typeof setPaymentCompanyValues>
  | ActionType<typeof requestPaymentCompanyOptions>
  | ActionType<typeof setPaymentDrugOrDeviceQuery>
  | ActionType<typeof setPaymentDrugOrDeviceValues>
  | ActionType<typeof requestPaymentDrugOrDeviceOptions>
  | ActionType<typeof setPaymentFundingTypeQuery>
  | ActionType<typeof setPaymentFundingTypeValues>
  | ActionType<typeof requestPaymentFundingTypeOptions>;
