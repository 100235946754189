import { connectProps } from "../MinCount";
import { RootState } from "../../../../../store/reducers";
import { setPaymentMinAmount } from "../../../../../store/actions";

const mapStateToProps = (state: RootState) => ({
  minCount: state.searchFilters.payments.minAmount.value
});

const mapDispatchToProps = {
  setMinCount: setPaymentMinAmount
};

export const PaymentsMinAmount = connectProps(
  mapStateToProps,
  mapDispatchToProps
);
