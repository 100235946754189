// for now, all APIs \go to the same host as the app.
const host = process.env.BACKEND_API;
// only direct to non standard ports if in development.  else, direct to 80
const getPort = (identity?: string) =>
  process.env.PROXIED_APP === "true" ? "" : identity;

const PIPELINE_API_PORT = getPort(process.env.PIPELINE_API_PORT);
const ELASTIC_API_PORT = getPort(process.env.ELASTIC_API_PORT);
const USER_DATA_API_PORT = getPort(process.env.USER_DATA_API_PORT);

const PIPELINE_API_PATH = process.env.PIPELINE_API_PATH;
const ELASTIC_API_PATH = process.env.ELASTIC_API_PATH;
const USER_API_PATH = process.env.USER_API_PATH;
const requestType = process.env.NODE_ENV === "development" ? "http" : "https";

export const ENDPOINTS = {
  clearScrolld: `${requestType}://${host}:${ELASTIC_API_PORT}${ELASTIC_API_PATH}/clearScrollId`,
  findCollaborators: `${requestType}://${host}:${ELASTIC_API_PORT}${ELASTIC_API_PATH}/findCollaborators`,
  collaboratorsDetails: `${requestType}://${host}:${ELASTIC_API_PORT}${ELASTIC_API_PATH}/collaboratorsDetails`,
  hasCollaborators: `${requestType}://${host}:${ELASTIC_API_PORT}${ELASTIC_API_PATH}/hasCollaborators`,
  filters: `${requestType}://${host}:${PIPELINE_API_PORT}${PIPELINE_API_PATH}`,
  project: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/users`,
  kolCards: `${requestType}://${host}:${PIPELINE_API_PORT}${PIPELINE_API_PATH}/kol-cards`,
  peopleSearch: `${requestType}://${host}:${ELASTIC_API_PORT}${ELASTIC_API_PATH}/peopleSearch`,
  genericSearch: `${requestType}://${host}:${ELASTIC_API_PORT}${ELASTIC_API_PATH}/genericSearch`,
  listsForPerson: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/lists-for-person`,
  setUser: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/setUser`,
  listsForUser: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/lists-for-user-endpoint`,
  addToList: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/add-persons-to-list`,
  createList: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/create-new-list`,
  personsInList: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/persons-for-list`,
  removeFromList: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/delete-person-from-list`,
  deleteList: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/delete-list`,
  login: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/login`,
  logout: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/logout`,
  profile: `${requestType}://${host}:${PIPELINE_API_PORT}${PIPELINE_API_PATH}/profile`,
  requestKol: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/request-kol`,
  searchSuggestions: `${requestType}://${host}:${PIPELINE_API_PORT}${PIPELINE_API_PATH}/projects`,
  peopleSuggestions: `${requestType}://${host}:${PIPELINE_API_PORT}${PIPELINE_API_PATH}/people-suggestions`,
  liteProfile: `${requestType}://${host}:${PIPELINE_API_PORT}${PIPELINE_API_PATH}/lite-profile`,
  searchFilters: {
    initialOptions: `${requestType}://${host}:${ELASTIC_API_PORT}${ELASTIC_API_PATH}/searchFilters/initialOptions`,
    getSuggestion: `${requestType}://${host}:${ELASTIC_API_PORT}${ELASTIC_API_PATH}/searchFilters/getSuggestion`,
    customSorting: `${requestType}://${host}:${USER_DATA_API_PORT}${USER_API_PATH}/custom-sorting`
  }
};
