import * as React from "react";
import { connect } from "react-redux";
import { getPaymentsFilters } from "../../../../../store/selectors";
import {
  setPaymentDrugOrDeviceValues,
  setPaymentDrugOrDeviceQuery
} from "../../../../../store/actions";
import { RootState } from "../../../../../store/reducers";
import { FilterAutosuggest as Autosuggest } from "../../FilterAutosuggest";

interface MappedStateProps {
  options: string[];
  values: string[];
  inputValue: string;
}

interface DispatchProps {
  setValues: (values: string[]) => void;
  handleInputChange: (inputVal: string) => void;
}

type Props = MappedStateProps & DispatchProps;

class DrugOrDeviceFiltersComponent extends React.Component<Props> {
  render() {
    const { options, values, setValues } = this.props;

    return (
      <Autosuggest
        values={values}
        setValues={setValues}
        suggestions={options}
        inputLabel="Drug / Device"
        inputValue={this.props.inputValue}
        setInputValue={this.props.handleInputChange}
      />
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => {
  const { drugOrDevice } = getPaymentsFilters(state);

  return {
    options: drugOrDevice.options,
    values: drugOrDevice.values,
    inputValue: drugOrDevice.query
  };
};

const mapDispatchToProps: DispatchProps = {
  setValues: setPaymentDrugOrDeviceValues,
  handleInputChange: setPaymentDrugOrDeviceQuery
};

export const DrugOrDeviceFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(DrugOrDeviceFiltersComponent);
