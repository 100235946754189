/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import {
  CustomSortBy,
  CustomSortByConstructor,
  DefaultScoreWeights
} from "@h1eng/interfaces";
import classNames from "classnames";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { CustomSortOptionManager } from "../CustomSortOptionManager";
import { RootState } from "../../../../../../store/reducers";
import {
  createCustomSortingOption,
  editCustomSortingOption,
  deleteCustomSortingOption
} from "../../../../../../store/actions";
import { validate } from "./validate";

const styles = (theme: Theme) =>
  createStyles({
    dialogContentRoot: {
      overflowX: "hidden",
      paddingTop: theme.spacing.unit * 3
    },
    nullMessageWrapper: {
      textAlign: "center"
    },
    nullMessageText: {
      marginBottom: theme.spacing.unit
    },
    contentRoot: {
      // display: "flex",
      // flexDirection: "row",
      // flexGrow: 0,
      // flexShrink: 1,
      // flexBasis: "auto",
      display: "grid",
      gridTemplateColumns: "150px auto",
      columnGap: theme.spacing.unit * 3
    },
    subheader: {
      lineHeight: "unset",
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      backgroundColor: "#fff",
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    menuWrapper: {
      width: 150,
      borderRadius: "8px",
      border: "2px solid #e8e6e1",
      marginRight: theme.spacing.unit * 3,
      height: "fit-content",
      maxHeight: 350,
      overflowY: "auto"
    },
    managerWrapper: {
      display: "flex",
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: "auto"
    },
    selectOptionMessage: {
      width: "100%",
      textAlign: "center"
    },
    menuItem: {
      height: "auto",
      whiteSpace: "unset",
      wordBreak: "break-word"
    },
    form: {
      width: "100%"
    }
  });

interface MappedStateProps {
  sortOptions: CustomSortBy[];
}

interface DispatchProps {
  create: (entity: CustomSortByConstructor) => void;
  edit: (entity: CustomSortBy) => void;
  delete: (id: string) => void;
}

interface ComponentProps {
  onClose: () => void;
  createNewOption: () => void;
}

type Props = ComponentProps &
  MappedStateProps &
  DispatchProps &
  WithStyles<typeof styles>;

interface State {
  selectedSortOption: CustomSortBy | null;
}

class ManageComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedSortOption: props.sortOptions[0] || null
    };
  }

  handleItemSelect = (selectedSortOption: CustomSortBy) => () => {
    this.setState({ selectedSortOption });
  };

  handleOptionUpdate = (
    selectedSortOption: CustomSortBy | CustomSortByConstructor
  ) => {
    this.setState({ selectedSortOption: selectedSortOption as CustomSortBy });
  };

  handleSave = (
    event?:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (event) {
      event.preventDefault();
    }

    const { selectedSortOption } = this.state;

    if (selectedSortOption === null || this.isDisabled) {
      return;
    }

    this.props.edit(selectedSortOption);
    this.props.onClose();
  };

  handleDelete = (id: string) => () => {
    this.props.delete(id);
    this.setState({
      selectedSortOption: null
    });
  };

  isDirty = () => {
    const selected = this.state.selectedSortOption;
    if (selected === null) return false;
    const persisted = this.props.sortOptions.find(i => i.id === selected.id);
    if (!persisted) return false;

    return (
      Object.keys(selected).reduce((prev: number, current: string) => {
        const idx = current as keyof CustomSortBy;

        return prev + (persisted[idx] === selected[idx] ? 0 : 1);
      }, 0) > 0
    );
  };

  get isDisabled() {
    const { selectedSortOption } = this.state;

    return (
      selectedSortOption === null ||
      selectedSortOption.name.trim() === "" ||
      Boolean(this.errorMessage) ||
      !this.isDirty()
    );
  }

  get errorMessage() {
    const { selectedSortOption } = this.state;

    return selectedSortOption === null
      ? undefined
      : validate(selectedSortOption, this.props.sortOptions);
  }

  render() {
    const { sortOptions, createNewOption, classes } = this.props;

    const { selectedSortOption } = this.state;

    const { errorMessage } = this;

    return (
      <>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          {sortOptions.length === 0 ? (
            <div className={classes.nullMessageWrapper}>
              <DialogContentText className={classes.nullMessageText}>
                You have no custom sorting configurations
              </DialogContentText>
              <Button onClick={createNewOption}>Add a new configuration</Button>
            </div>
          ) : (
            <div className={classes.contentRoot}>
              <div className={classes.menuWrapper}>
                <MenuList
                  subheader={
                    <ListSubheader className={classes.subheader}>
                      My Custom Sorting Options
                    </ListSubheader>
                  }
                >
                  {sortOptions.map(option => (
                    <MenuItem
                      className={classes.menuItem}
                      button
                      onClick={this.handleItemSelect(option)}
                      selected={
                        selectedSortOption !== null
                          ? selectedSortOption.id === option.id
                          : false
                      }
                      key={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </div>
              <div className={classes.managerWrapper}>
                {selectedSortOption === null ? (
                  <DialogContentText
                    className={classNames(
                      classes.nullMessageText,
                      classes.selectOptionMessage
                    )}
                  >
                    Select an option to manage it
                  </DialogContentText>
                ) : (
                  <form onSubmit={this.handleSave} className={classes.form}>
                    <CustomSortOptionManager
                      errorMessage={errorMessage}
                      customSortBy={selectedSortOption}
                      onUpdate={this.handleOptionUpdate}
                      onDelete={this.handleDelete(selectedSortOption.id)}
                    />
                  </form>
                )}
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            role="button"
            onClick={() => {
              this.props.onClose();
            }}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={this.handleSave}
            disabled={this.isDisabled}
          >
            Save Changes
          </Button>
        </DialogActions>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  sortOptions: state.searchFilters.customSortingOptions
});

const mapDispatchToProps: DispatchProps = {
  create: createCustomSortingOption.request,
  edit: editCustomSortingOption.request,
  delete: deleteCustomSortingOption.request
};

export const Manage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ManageComponent));
