/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { ResultCardData } from "@h1eng/interfaces";
import { styled } from "@h1eng/ui-components";
import { Card } from "../../common/Card";
import {
  Root as ContentRoot,
  TitleLink,
  PersonDataText,
  InfoWrapper,
  InfoColumn,
  InfoColumnLabel,
  Text,
  BoldText,
  getTotalWorks
} from "../../KOLResult/ResultCards/ResultCard/ResultCardContent";
import { ListButton } from "../../ListManagement/ListButton";
import { Checkbox } from "../../common/Checkbox";
import { WorksLink } from "./WorksLink";
import { formatStats } from "@h1eng/format-util";

interface Props {
  person: ResultCardData;
  selected: boolean;
  onSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

type SavedListCardProps = Props;

const CardRoot = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  margin-left: -24px;
`;

const WorksRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
`;

const SavedListCardComponent: React.FunctionComponent<SavedListCardProps> = ({
  person,
  selected,
  onSelect
}) => {
  return (
    <Card>
      <CardRoot>
        <CheckboxWrapper>
          <Checkbox
            value={person.personId}
            checked={selected}
            onChange={onSelect}
            color="primary"
          />
        </CheckboxWrapper>
        <ContentRoot>
          <TitleLink to={`/curie/person/${person.personId}`}>{`${
            person.firstName
          } ${(!!person.middleName && `${person.middleName} `) || ""}${
            person.lastName
          }`}</TitleLink>
          <PersonDataText>{person.title}</PersonDataText>
          <PersonDataText>{person.organization}</PersonDataText>
          <PersonDataText>{person.location}</PersonDataText>
          <InfoWrapper>
            {person.specialty.length > 0 && (
              <InfoColumn>
                <InfoColumnLabel>Specialty</InfoColumnLabel>
                <BoldText>{person.specialty.join(", ")}</BoldText>
              </InfoColumn>
            )}
            <InfoColumn>
              <InfoColumnLabel>Works</InfoColumnLabel>
              <div>
                <Text>{getTotalWorks(person)}</Text>
              </div>
            </InfoColumn>
          </InfoWrapper>
          <div>
            <ListButton
              personId={person.personId}
              firstName={person.firstName}
              lastName={person.lastName}
            />
          </div>
        </ContentRoot>
        <WorksRoot>
          {person.countPublications > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/publications`}
              label="Publications"
              value={formatStats(person.countPublications)}
              color="publications"
            />
          )}
          {person.countClinicalTrials > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/clinical-trials`}
              label="Clinical Trials"
              value={formatStats(person.countClinicalTrials)}
              color="trials"
            />
          )}
          {person.congresses > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/congresses`}
              label="Congresses"
              value={formatStats(person.congresses)}
              color="congresses"
            />
          )}
          {person.sumPayments > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/payments`}
              label="Payments"
              value={`$${formatStats(person.sumPayments)}`}
              color="payments"
            />
          )}
          {person.sumGrants > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/grants`}
              label="Grants"
              value={`$${formatStats(person.sumGrants)}`}
              color="grants"
            />
          )}
        </WorksRoot>
      </CardRoot>
    </Card>
  );
};

export const SavedListCard = SavedListCardComponent;
