import { Middleware } from "redux";
import { getType } from "typesafe-actions";
import { updateConfig } from "../actions";

export const RefetchPageOnConfigChange: Middleware = store => next => action => {
  switch (action.type) {
    case getType(updateConfig):
      const { config } = store.getState().config;
      const configSha = config.sha;
      const newSha = action.payload.sha;
      if (configSha.length > 0 && configSha !== newSha) {
        location.reload();
      }
  }

  return next(action);
};
