import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Root = styled.div`
  background-color: #fff;
  border-radius: 0;
  border: none;
  padding-bottom: 24px;
  margin-bottom: 24px;
  &:not(:last-of-type) {
    border-bottom: 2px solid #e8e6e1;
  }
`;

export const Card: React.SFC = props => <Root {...props} />;
