import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";

const styles = (theme: Theme) =>
  createStyles({
    popperRoot: {
      margin: theme.spacing.unit * 2,
      display: "flex",
      flexDirection: "column",
      maxWidth: 300
    },
    popperActions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: theme.spacing.unit * 2
    }
  });

interface ComponentProps {
  onConfirm: () => void;
  popperContent: React.ReactChild;
  cancelLabel?: string;
  confirmLabel?: string;
}

type Props = ComponentProps & WithStyles<typeof styles>;

type AnchorEl = null | EventTarget & HTMLElement;

const ConfirmDeleteButtonComponent: React.FunctionComponent<Props> = ({
  classes,
  onConfirm,
  popperContent,
  confirmLabel = "Delete",
  cancelLabel = "Cancel"
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null as AnchorEl);

  function handleButtonClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="Delete">
        <IconButton
          aria-owns={open ? "delete-confirmation-popper" : undefined}
          aria-haspopup="true"
          onClick={handleButtonClick}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popover
        id="delete-confirmation-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
      >
        <div className={classes.popperRoot}>
          <div>
            {typeof popperContent === "string" ? (
              <Typography>{popperContent}</Typography>
            ) : (
              popperContent
            )}
          </div>
          <div className={classes.popperActions}>
            <Button size="small" onClick={handleClose} color="secondary">
              {cancelLabel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                handleClose();
                onConfirm();
              }}
            >
              {confirmLabel}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export const ConfirmDeleteButton = withStyles(styles)(
  ConfirmDeleteButtonComponent
);
