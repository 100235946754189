import { connect } from "react-redux";
import { RootState } from "../../../../store/reducers";
import {
  getSavedListPageNum,
  getSavedListPageSize,
  getSelectedListPersonIds
} from "../../../../store/selectors";
import { setSavedListPageNum } from "../../../../store/actions";
import { Pagination as P } from "../../common/Pagination";

const mapStateToProps = (state: RootState) => ({
  pageNum: getSavedListPageNum(state),
  pageSize: getSavedListPageSize(state),
  total: getSelectedListPersonIds(state).length
});

const mapDispatchToProps = {
  loadPage: setSavedListPageNum
};

export const Pagination = connect(
  mapStateToProps,
  mapDispatchToProps
)(P);
