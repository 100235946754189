/* tslint:disable:react-unused-props-and-state */
import * as React from "react";
import { styled } from "../../../../ui-components/dist";
import { GrowlerInterface } from "../../store/actions/growler";
import { GrowlerTypes } from "./GrowlerTypes";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/Check";

const GrowlerCard = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TitleRoot = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 10px;
`;

const CloseRoot = styled.div`
  cursor: pointer;
  cursor: hand;
`;

const TitleText = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  display: flex;
`;

const GrowlerContent = styled.div``;

const DescriptionText = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
`;

interface GrowlerMessageProps {
  growlerData: GrowlerInterface;
  onClose: () => any;
}

export interface GrowlerlState {
  open: boolean;
}

export class GrowlerMessage extends React.Component<GrowlerMessageProps, any> {
  get titleIcon() {
    if (!this.props.growlerData.titleIcon) return null;

    const iconProps = {
      style: { marginRight: 4, fontSize: 14 }
    };

    switch (this.props.growlerData.titleIcon) {
      case "warning":
        return <WarningIcon {...iconProps} />;
      case "check":
        return <CheckIcon {...iconProps} />;
      default:
        return this.props.growlerData.titleIcon;
    }
  }

  render() {
    //background-color:
    const backgroundColor =
      this.props.growlerData.growler === GrowlerTypes.success
        ? "#aeffde"
        : "#c33420";

    const titleColor =
      this.props.growlerData.growler === GrowlerTypes.success
        ? "rgba(51, 51, 51, 0.81)"
        : "#ffffff";

    const descriptionColor =
      this.props.growlerData.growler === GrowlerTypes.success
        ? "#333333"
        : "#f6f6f6";

    return (
      <GrowlerCard style={{ backgroundColor }}>
        <GrowlerContent>
          <TitleRoot>
            <TitleText style={{ color: titleColor }}>
              {this.titleIcon}
              {this.props.growlerData.title}
            </TitleText>
            <CloseRoot style={{ color: titleColor }}>
              <CloseIcon color="inherit" onClick={this.props.onClose} />
            </CloseRoot>
          </TitleRoot>

          <DescriptionText style={{ color: descriptionColor }}>
            {this.props.growlerData.description}
          </DescriptionText>
        </GrowlerContent>
      </GrowlerCard>
    );
  }
}
