import * as React from "react";
import { TooltipPayload } from "recharts";
import { styled } from "@h1eng/ui-components";

const Root = styled.div`
  padding: 10px;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.24), 0 0 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #060464;
  font-family: Montserrat;
`;

const PayloadRoot = styled.tr`
  width: 100%;
  height: auto;
  margin-bottom: 5px;
`;

const PayloadValue = styled.th`
  font-size: 14px;
  font-weight: bold;
`;

const PayloadLabel = styled.td`
  font-size: 14px;
`;

const Table = styled.table`
  color: #fff;
  font-family: Montserrat;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
`;

/* tslint:disable:cyclomatic-complexity */
export const PayloadRenderer: React.SFC<TooltipPayload> = props => {
  const name = props.name || props.dataKey;
  let value = props.value;
  if (props.payload && props.payload.formatter) {
    value = props.payload.formatter(value);
  }

  if (!name || typeof value === "undefined") return null;

  return (
    <PayloadRoot>
      <PayloadValue>{value}</PayloadValue>&nbsp;
      <PayloadLabel>{name}</PayloadLabel>
    </PayloadRoot>
  );
};
/* tslint:enable:cyclomatic-complexity */

interface TooltipContentProps {
  payload?: TooltipPayload[];
  label?: string;
}

export const TooltipContent: React.SFC<TooltipContentProps> = props => {
  if (!props.payload || !props.payload.length) return null;

  const payloads = props.payload;

  return (
    <Root>
      {!!props.label && (
        <PayloadRoot>
          <Label>{props.label}</Label>
        </PayloadRoot>
      )}
      <Table>
        {payloads.map(i => (
          <PayloadRenderer key={`tooltip-item-${i}`} {...i} />
        ))}
      </Table>
    </Root>
  );
};
