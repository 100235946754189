import { GraphDatum, YearCount } from "@h1eng/interfaces";
import { FULL_BAR_SIZE, HALF_BAR_SIZE, BAR_GRAPH_LIMIT } from "../";
import { AxisInterval } from "recharts";

/**
 * Used to determine whether a graph should render the "searched" documents based on the query
 */
export const getRenderSearched = ({
  searchQuery,
  data
}: {
  searchQuery: any[];
  data: GraphDatum[];
}) => {
  return searchQuery.length > 0 && !data.every(i => i.searched === 0);
};

export const getBarSize = ({
  searchQuery,
  data
}: {
  searchQuery: any[];
  data: GraphDatum[];
}) => {
  return getRenderSearched({ searchQuery, data })
    ? HALF_BAR_SIZE
    : FULL_BAR_SIZE;
};

export const fillInterimYears = (
  counts: YearCount,
  filterDateText?: string
) => {
  let minYear;
  let maxYear;
  const currentDate = new Date();

  switch (filterDateText) {
    case "Last 5 years":
      maxYear = currentDate.getFullYear();
      minYear = currentDate.getFullYear() - 5;
      break;
    case "Last 10 years":
      maxYear = currentDate.getFullYear();
      minYear = currentDate.getFullYear() - 10;
      break;
    default:
      const years: number[] = Object.keys(counts).map(i => parseInt(i, 10));
      minYear = Math.min(...years);
      maxYear = Math.max(...years);
  }

  for (let year = minYear; year <= maxYear; year++) {
    if (!counts[year]) {
      counts[year] = {
        year,
        total: 0,
        searched: 0
      };
    }
  }

  return counts;
};

export const getXAxisInterval = (count: number) => {
  const res = count <= BAR_GRAPH_LIMIT + 1 ? 0 : "auto";

  return res as AxisInterval;
};
