import { withStyles, createStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const styles = createStyles({
  root: {
    backgroundColor: "#151361",
    opacity: 0.26,
    color: "#fff",
    fontWeight: "bold",
    minWidth: 36
  }
});

export const FilterCountChip = withStyles(styles)(Chip);
