import { getType } from "typesafe-actions";
import { setOrigConfig, ConfigAction } from "../actions";
import { ConfigVO } from "../../models/ConfigVO";

export interface ConfigState {
  config: ConfigVO;
}

const initialState: ConfigState = {
  config: {
    branch: "",
    sha: ""
  }
};

export const configReducer = (
  state: ConfigState = initialState,
  action: ConfigAction
): ConfigState => {
  switch (action.type) {
    case getType(setOrigConfig): {
      return { ...state, config: action.payload };
    }
  }
  return state;
};
