import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  requestGrantFunderOptions,
  setGrantFunderQuery,
  resetInitialOptions,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  map,
  filter,
  catchError
} from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { getOptions } from "../../../apis/searchFilters";
import { DEBOUNCE_TIME } from ".";

const setGrantFunderQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setGrantFunderQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestGrantFunderOptions.request(
              state$.value.searchFilters.grants.funder
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getGrantFunderOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestGrantFunderOptions.request)),
    switchMap(({ payload }) =>
      from(getOptions(state$.value.searchFilters.grants.funder)).pipe(
        map(requestGrantFunderOptions.success),
        catchError(
          pipe(
            requestGrantFunderOptions.failure,
            of
          )
        )
      )
    )
  );

export default [setGrantFunderQueryFlow, getGrantFunderOptionsFlow];
