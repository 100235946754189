import * as React from "react";
import { styled } from "@h1eng/ui-components";

interface Props {
  label: string;
  item: string | number | React.ReactNode;
  splitItem?: boolean;
  splitDelimiter?: string;
}

export const Numerator = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
`;

export const Denominator = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
`;

const Root = styled.div`
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

const SummaryLabel = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  display: inline-block;
`;

const SingleItem = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
`;

const List = styled.ul`
  margin: 0;
  padding-left: 25px;
`;

const ListItem = styled.li`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
`;

export const SummaryItem: React.SFC<Props> = ({
  label,
  item,
  splitItem = false,
  splitDelimiter = ", "
}) => {
  let content: any = item;

  if (typeof item === "string" || typeof item === "number") {
    if (typeof item === "string" && splitItem) {
      content = (
        <List>
          {item.split(splitDelimiter).map((i, idx) => (
            <ListItem key={`summary-list-item-${idx}=${i}`}>{i}</ListItem>
          ))}
        </List>
      );
    } else {
      content = <SingleItem>{item}</SingleItem>;
    }
  }

  return (
    <Root>
      <SummaryLabel>{label}&nbsp;</SummaryLabel>
      {content}
    </Root>
  );
};
