/* tslint:disable:cyclomatic-complexity */
import { getType } from "typesafe-actions";
import {
  searchElastic,
  searchSubDocumentsElastic,
  setPageNum,
  setSearchLoading,
  displayLoadingMessage,
  clearLoadingMessage,
  SearchAction,
  setSearchView
} from "../actions";
import {
  GenericSearchResultInterface,
  ScoredDocumentResults,
  SearchView
} from "@h1eng/interfaces";

export interface SearchState {
  documentSearchResults: GenericSearchResultInterface[];
  loading: boolean;
  totalHits: number;
  pageNum: number;
  pageSize: number;
  documentSearchLoading: boolean;
  displayLoadingMessage: boolean;
  scoredDocuments: ScoredDocumentResults;
  searchView: SearchView;
}

export const initialState: SearchState = {
  documentSearchResults: [],
  loading: false,
  totalHits: 0,
  pageNum: 0,
  pageSize: 15,
  searchView: SearchView.SearchedWork,
  documentSearchLoading: false,
  displayLoadingMessage: false,
  scoredDocuments: {
    normalizedRange: { min: 0, max: 0 },
    persons: [],
    ranges: {
      citationCount: { min: 0, max: 0 },
      trialCount: { min: 0, max: 0 },
      congressCount: { min: 0, max: 0 },
      grantCount: { min: 0, max: 0 },
      grantSum: { min: 0, max: 0 },
      paymentCount: { min: 0, max: 0 },
      paymentSum: { min: 0, max: 0 },
      publicationCount: { min: 0, max: 0 },
      totalCollaborators: { min: 0, max: 0 }
    }
  }
};

export const searchReducer = (
  state: SearchState = initialState,
  action: SearchAction
): SearchState => {
  switch (action.type) {
    case getType(setSearchView): {
      return {
        ...state,
        searchView: action.payload
      };
    }
    case getType(setPageNum): {
      return {
        ...state,
        pageNum: action.payload
      };
    }

    case getType(searchElastic.request): {
      return { ...state, loading: true, totalHits: 0 };
    }

    case getType(searchElastic.success): {
      return {
        ...state,
        totalHits: action.payload.totalHits,
        loading: false,
        scoredDocuments: { ...action.payload.scoredDocuments }
      };
    }

    case getType(searchSubDocumentsElastic.request): {
      return {
        ...state,
        documentSearchLoading: true
      };
    }

    case getType(searchSubDocumentsElastic.success): {
      return {
        ...state,
        documentSearchLoading: false,
        documentSearchResults:
          action.payload && action.payload.length ? action.payload : []
      };
    }

    case getType(setSearchLoading): {
      return {
        ...state,
        loading: action.payload
      };
    }

    case getType(displayLoadingMessage): {
      return {
        ...state,
        displayLoadingMessage: true
      };
    }

    case getType(clearLoadingMessage): {
      return {
        ...state,
        displayLoadingMessage: false
      };
    }
  }

  return state;
};
