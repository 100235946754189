import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/lab/Slider";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import { colors, ColorKey } from "../../../KOLResult/ResultCards/colors";
import numeral from "numeral";

const styles = (theme: Theme) =>
  createStyles({
    slider: {
      padding: `${theme.spacing.unit * 2.5}px 0px`
    },
    publications: {
      backgroundColor: colors.publications
    },
    citations: { backgroundColor: colors.citations },
    trials: { backgroundColor: colors.trials },
    congresses: { backgroundColor: colors.congresses },
    payments: { backgroundColor: colors.payments },
    grants: { backgroundColor: colors.grants },
    labelWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }
  });

interface Props {
  value: number;
  label: string;
  onChange: (value: number) => void;
  colorKey: ColorKey;
}

const CustomOptionSliderComponent: React.FunctionComponent<
  Props & WithStyles<typeof styles>
> = props => {
  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    props.onChange(value);
  };

  const { classes } = props;

  // @ts-ignore
  const colorClass = classes[props.colorKey] as string;

  return (
    <div>
      <div className={classes.labelWrapper}>
        <Typography>{props.label}</Typography>
        <Typography style={{ marginLeft: 8, fontWeight: 600 }}>
          {numeral(props.value).format("0[.]0[0]")}
        </Typography>
      </div>
      <Slider
        color={colors[props.colorKey]}
        value={props.value}
        classes={{
          container: props.classes.slider,
          thumb: colorClass,
          trackAfter: colorClass,
          trackBefore: colorClass
        }}
        onChange={handleChange}
        step={0.05}
        min={0}
        max={1}
      />
    </div>
  );
};

export const CustomOptionSlider = withStyles(styles)(
  CustomOptionSliderComponent
);
