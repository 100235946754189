/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { Card } from "../../../common/Card";
import {
  ScoredResultCardData,
  SearchView,
} from "@h1eng/interfaces";
import { styled } from "@h1eng/ui-components";
import { ResultCardContent } from "./ResultCardContent";
import { Checkbox } from "../../../common/Checkbox";
import { ResultPercentageIndicators } from "./ResultPercentageIndicators";
import { WorkOverTime } from "./WorkOverTime/WorkOverTime";
import { WorksLink } from "../../../SavedListsPage/SavedListCards/WorksLink";
import { formatStats } from "@h1eng/format-util";
const Root = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  margin-left: -24px;
`;
const WorksRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
`;

interface Props {
  selected: boolean;
  lists: any[];
  selectedListId: string | null;
  listsAvailable: any[];
  onSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  person: ScoredResultCardData;
  listButton: React.ReactElement<any>;
  searchView: SearchView;
}

export class ResultCard extends React.Component<Props> {
  render() {
    const { person, selected, onSelect, listButton, searchView } = this.props;
    let workView = <>hi</>;
    if (searchView === SearchView.SearchedWork) {
      workView = (
        <ResultPercentageIndicators
          scoredDocuments={person.scoredDocuments}
          personId={person.personId}
        />
      );
    }
    if (searchView === SearchView.TotalWork) {
      workView = (
        <WorksRoot>
          {person.countPublications > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/publications`}
              label="Publications"
              value={formatStats(person.countPublications)}
              color="publications"
            />
          )}
            {person.citationCountAvg > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/publications`}
              label="Citations Per Pub"
              value={formatStats(person.citationCountAvg)}
              color="citations"
            />
          )}
          {person.countClinicalTrials > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/clinical-trials`}
              label="Clinical Trials"
              value={formatStats(person.countClinicalTrials)}
              color="trials"
            />
          )}
          {person.congresses > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/congresses`}
              label="Congresses"
              value={formatStats(person.congresses)}
              color="congresses"
            />
          )}
          {person.sumPayments > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/payments`}
              label="Payments"
              value={`$${formatStats(person.sumPayments)}`}
              color="payments"
            />
          )}
          {person.sumGrants > 0 && (
            <WorksLink
              to={`/curie/person/${person.personId}/grants`}
              label="Grants"
              value={`$${formatStats(person.sumGrants)}`}
              color="grants"
            />
          )}
        </WorksRoot>
      );
    }
    if ((searchView === SearchView.WorkOverTime) {
      workView = (
        <WorkOverTime
          dates={[
            ...person.congressesDates,
            ...person.paymentDates,
            ...person.publicationDates,
            ...person.trailDates
          ]}
        />
      );
    }

    return (
      <Card>
        <Root>
          <CheckboxWrapper>
            <Checkbox
              value={person.personId}
              onChange={onSelect}
              checked={selected}
              color="primary"
            />
          </CheckboxWrapper>
          <ResultCardContent person={person} listButton={listButton} />
          {workView}
        </Root>
      </Card>
    );
  }
}
