import * as React from "react";
import { styled } from "@h1eng/ui-components";

const Root = styled.div`
  padding: 6px 6px 6px 24px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  min-width: 150px;
  &:hover {
    background-color: #eeeeee;
  }
`;

export interface DropdownOptionInterface {
  text: string;
  value: any;
}

type Props = DropdownOptionInterface & {
  onSelect: (value: any, text: string) => any;
};

export class DropdownOption extends React.Component<Props> {
  handleSelect = () => {
    this.props.onSelect(this.props.value, this.props.text);
  };

  render() {
    return (
      <Root onClick={this.handleSelect} onTouchEnd={this.handleSelect}>
        {this.props.text}
      </Root>
    );
  }
}
