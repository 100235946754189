import * as React from "react";
import * as _ from "lodash";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  AxisInterval,
  PositionType,
  Tooltip,
  LineChart,
  Line,
  CartesianGrid
} from "recharts";
import {
  tooltip,
  bar,
  cartesianGrid,
  line,
  lineChart,
  xAxis,
  yAxis
} from "../../../../Profile/DataVisualizations/styles";

interface WorkOverTimePros {
  dates: number[];
}

export class WorkOverTime extends React.Component<WorkOverTimePros> {
  get barData() {
    const groupedDates = _.groupBy(
      this.props.dates.map(e => e * 1000).map(e => new Date(e).getFullYear())
    );
    return Object.keys(groupedDates).map(e => {
      return { year: e, value: groupedDates[e].length };
    });
  }
  render() {
    const data = this.barData;
    const barSize = 36;

    if (data.length > BAR_GRAPH_LIMIT) {
      return (
        <LineChart
          {...lineChart}
          data={data}
          width={370}
          height={170}
          margin={{ bottom: 20, left: 30, right: 10, top: 5 }}
        >
          <CartesianGrid {...cartesianGrid} />
          <Line
            {...line}
            dataKey="value"
            stroke={"#060464"}
            name="Number Of Works"
          />

          <XAxis
            {...xAxis}
            label={{
              ...xAxis.label,
              value: "Years ",
              fill: "#435D6B",
              fontWeight: "bold",
              offset: 10,
              position: "bottom" as PositionType
            }}
            interval={getXAxisInterval(data.length)}
            dataKey="year"
            tickCount={1}
            tickMargin={4}
            tick={{
              fill: "#333",
              style: {
                offset: 3,
                fontFamily: "Montserrat",
                fontSize: 12,
                fill: "#435D6B",
                fontWeight: "bold"
              }
            }}
          />
          <YAxis
            {...yAxis}
            label={{
              angle: -90,
              position: "insideBottomLeft" as PositionType,
              style: {
                fontFamily: "Montserrat",
                fontSize: 12,
                fill: "#435D6B",
                fontWeight: "bold"
              },
              value: "Work Over Time"
            }}
            tickMargin={0}
            tick={{
              fill: "#333",
              style: {
                fontFamily: "Montserrat",
                fontSize: 12,
                fill: "#435D6B",
                fontWeight: "bold"
              }
            }}
          />
          <Tooltip {...tooltip} />
        </LineChart>
      );
    }

    return (
      <div>
        <BarChart
          data={data}
          width={370}
          height={170}
          margin={{ bottom: 20, left: 30, right: 10, top: 5 }}
        >
          <Bar
            {...bar}
            barSize={barSize}
            dataKey="value"
            fill={"#060464"}
            name="Total"
          />
          <XAxis
            type={"category"}
            tickCount={1}
            tickMargin={1}
            tick={{
              fill: "#333",
              style: {
                fontFamily: "Montserrat",
                fontSize: 12,
                fill: "#435D6B",
                fontWeight: "bold"
              }
            }}
            dataKey="year"
            label={{
              ...xAxis.label,
              offset: -5,
              fill: "#435D6B",
              fontWeight: "bold",
              value: "Years"
            }}
          />
          <YAxis
            {...yAxis}
            tick={false}
            label={{
              ...yAxis.label,
              offset: 0,
              fill: "#435D6B",
              fontWeight: "bold",
              position: "center" as PositionType,
              value: "Number of Works"
            }}
          />
          <Tooltip {...tooltip} />
        </BarChart>
      </div>
    );
  }
}
export const BAR_GRAPH_LIMIT = 11;

export const getXAxisInterval = (count: number) => {
  const res = count <= BAR_GRAPH_LIMIT + 1 ? undefined : "auto";

  return res as AxisInterval;
};
