import { SearchFilterOptions } from "@h1eng/interfaces";
import { SearchFilterState } from "../reducers/searchFilterReducer";

export const mapInitialSearchFilterOptionsToState = (
  state: SearchFilterState,
  options: SearchFilterOptions
): SearchFilterState => ({
  ...state,
  initialOptions: options,
  specialty: {
    ...state.specialty,
    options: options.specialty
  },
  country: {
    ...state.country,
    options: options.country
  },
  state: {
    ...state.state,
    options: options.state
  },
  institution: {
    ...state.institution,
    options: options.institution
  },
  publications: {
    ...state.publications,
    journal: {
      ...state.publications.journal,
      options: options.publications.journal
    },
    type: {
      ...state.publications.type,
      options: options.publications.type
    }
  },
  trials: {
    ...state.trials,
    status: {
      ...state.trials.status,
      options: options.trials.status
    },
    phase: {
      ...state.trials.phase,
      options: options.trials.phase
    },
    studyType: {
      ...state.trials.studyType,
      options: options.trials.studyType
    },
    funderType: {
      ...state.trials.funderType,
      options: options.trials.funderType
    },
    sponsor: {
      ...state.trials.sponsor,
      options: options.trials.sponsor
    }
  },
  congresses: {
    ...state.congresses,
    name: {
      ...state.congresses.name,
      options: options.congresses.name
    },
    type: {
      ...state.congresses.type,
      options: options.congresses.type
    }
  },
  grants: {
    ...state.grants,
    funder: {
      ...state.grants.funder,
      options: options.grants.funder
    }
  },
  payments: {
    ...state.payments,
    company: {
      ...state.payments.company,
      options: options.payments.company
    },
    drugOrDevice: {
      ...state.payments.drugOrDevice,
      options: options.payments.drugOrDevice
    },
    fundingType: {
      ...state.payments.fundingType,
      options: options.payments.fundingType
    }
  }
});
