import * as React from "react";
import { connect } from "react-redux";
import { CardWrapper } from "../../common/Card";
import { NullState } from "../NullState";
import { RootState } from "../../../../store/reducers";
import {
  getSavedListsKolCards,
  getCardsLoadingStatus
} from "../../../../store/selectors";
import { ResultCardData } from "@h1eng/interfaces";
import { LoadingState } from "../../KOLResult/Loading";
import { SavedListCard } from "./SavedListCard";
import { Checkbox } from "../../common/Checkbox";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BulkActions } from "./BulkActions";
import { styled } from "@h1eng/ui-components";
import { Pagination } from "./Pagination";

interface MappedStateProps {
  cards: ResultCardData[];
  loading: boolean;
}

type SavedListCardsProps = MappedStateProps & RouteComponentProps;

/* tslint:disable:react-unused-props-and-state */
interface State {
  selectAll: boolean;
  selected: string[];
}
/* tslint:enable:react-unused-props-and-state */

const initialState: State = {
  selectAll: false,
  selected: []
};

const handleCardSelect = ({
  state,
  setState
}: {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
}) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  const { value } = event.target;
  let selected: string[];

  if (checked) {
    selected = [...state.selected, value];
  } else {
    selected = state.selected.filter(i => i !== value);
  }

  setState({
    ...state,
    selected
  });
};

const handleSelectAllChange = ({
  state,
  setState,
  indeterminate,
  ids
}: {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  indeterminate: boolean;
  ids: string[];
}) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  let selected: string[];
  let selectAll: boolean;

  if (checked && !indeterminate) {
    selected = ids;
    selectAll = true;
  } else {
    selected = [];
    selectAll = false;
  }

  setState({ selected, selectAll });
};

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const SavedListCardsComponent: React.FunctionComponent<SavedListCardsProps> = ({
  cards,
  loading,
  history
}) => {
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    history.listen(() => {
      setState({ selected: [], selectAll: false });
    });
  });

  const cardIds = cards.map(i => i.personId);

  const selected = state.selected.filter(i => !(cardIds.indexOf(i) === -1));

  const checked = selected.length > 0;

  const indeterminate = checked ? selected.length < cards.length : false;

  return (
    <>
      <ActionsWrapper>
        <Checkbox
          indeterminate={indeterminate}
          checked={checked}
          color="primary"
          onChange={handleSelectAllChange({
            state,
            setState,
            indeterminate,
            ids: cards.map(i => i.personId)
          })}
        />
        <BulkActions selectedIds={state.selected} style={{ height: 28 }} />
      </ActionsWrapper>
      <CardWrapper>
        {cards.length === 0
          ? getNullOrLoadingView(loading)
          : cards.map((person, i) => (
              <SavedListCard
                person={person}
                key={`card-${person.firstName}-${person.lastName}-${i}`}
                selected={state.selected.indexOf(person.personId) !== -1}
                onSelect={handleCardSelect({ state, setState })}
              />
            ))}
      </CardWrapper>
      <Pagination />
    </>
  );
};

function getNullOrLoadingView(loading: boolean) {
  if (loading) return <LoadingState />;

  return <NullState />;
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  cards: getSavedListsKolCards(state),
  loading: getCardsLoadingStatus(state)!
});

export const SavedListCards = connect(mapStateToProps)(
  withRouter(SavedListCardsComponent)
);
