import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate,
  DocumentSearchBarAction
} from "../actions/documentSearchBar";
import { getType } from "typesafe-actions";

export interface SearchBarQuery {
  query: string[];
  personId: string;
  types: any[];
  filterDate: number;
  filterDateText: string;
}

const initialState = {
  query: [],
  personId: "",
  types: [],
  filterDate: new Date().setFullYear(new Date().getFullYear() - 100),
  filterDateText: "All time",
  loading: false
} as SearchBarQuery;

export const searchBarReducer = (
  state: SearchBarQuery = initialState,
  action: DocumentSearchBarAction
): SearchBarQuery => {
  switch (action.type) {
    case getType(setDocumentSearchBarQuery): {
      return {
        ...state,
        ...action.payload
      };
    }
    case getType(setDocumentSearchBarFilterDate): {
      return {
        ...state,
        filterDate: action.payload.filterDate,
        filterDateText: action.payload.displayFilterDateString
      };
    }
    default:
      return state;
  }
};
