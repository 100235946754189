import { connectProps } from "../MinCount";
import { RootState } from "../../../../../store/reducers";
import { setTrialMinCount } from "../../../../../store/actions";

const mapStateToProps = (state: RootState) => ({
  minCount: state.searchFilters.trials.minCount.value
});

const mapDispatchToProps = {
  setMinCount: setTrialMinCount
};

export const TrialsMinCount = connectProps(mapStateToProps, mapDispatchToProps);
