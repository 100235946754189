import {
  createAsyncAction,
  ActionType,
  createStandardAction
} from "typesafe-actions";

import { ListMeta } from "../../components/curie/ListManagement/ListManagementInterfaces";
import { ListVO } from "../../models/ListVO";

export interface GetListInterface {
  projectId: string;
  userId: string;
  personIds: string[];
}

export interface ListManipulationInterface {
  personIds: string[];
  listId: string;
}

export interface ListDeleteInterface {
  listId: string;
}
export const addToList = createAsyncAction(
  "list.add/request",
  "list.add/success",
  "list.add/failure"
)<ListManipulationInterface, ListManipulationInterface, Error>();

export const removePeopleFromList = createAsyncAction(
  "list.removePeopleFromList/request",
  "list.removePeopleFromList/success",
  "list.removePeopleFromList/failure"
)<ListManipulationInterface, ListManipulationInterface, Error>();

export const deleteList = createAsyncAction(
  "list.deleteList/request",
  "list.deleteList/success",
  "list.deleteList/failure"
)<ListDeleteInterface, ListManipulationInterface, Error>();

export interface ReturnedListListInterface {
  [key: string]: { personLists: ListMeta[] };
}

export const getListForUser = createAsyncAction(
  "list/request",
  "list/success",
  "list/failure"
)<GetListInterface, ReturnedListListInterface, Error>();

export const createList = createAsyncAction(
  "listCreate/request",
  "listCreate/success",
  "listCreate/failure"
)<ListVO, ListVO, Error>();

export interface ListOptionsExample {
  projectId: string;
  userId: string;
}
export const getListOptions = createAsyncAction(
  "listOptions/request",
  "listOptions/success",
  "listOptions/failure"
)<ListOptionsExample, ListMeta[], Error>();

export const selectListId = createStandardAction("list/setSelectedId")<
  string | null
>();

export const clearSelectedId = createStandardAction("list/clearSelectedId")<
  void
>();

export const setSavedListPageNum = createStandardAction("list/setPageNum")<
  number
>();

export type ListAction =
  | ActionType<typeof getListForUser>
  | ActionType<typeof removePeopleFromList>
  | ActionType<typeof addToList>
  | ActionType<typeof createList>
  | ActionType<typeof getListOptions>
  | ActionType<typeof selectListId>
  | ActionType<typeof clearSelectedId>
  | ActionType<typeof deleteList>
  | ActionType<typeof setSavedListPageNum>;
