/* tslint:disable:cyclomatic-complexity */
import * as _ from "lodash";
import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { SearchFilterState } from "../reducers/searchFilterReducer";
import { AdvancedFilterSections } from "../actions/searchFilters";

const getState = (state: RootState) => state;
const getSearchFilterState = (state: SearchFilterState) => state;

export const getSearchFilters = createSelector(
  getState,
  state => state.searchFilters
);

export const showAdvancedFilters = createSelector(
  getState,
  state => state.searchFilters.showAdvancedFilters
);

export const getPublicationsFilters = createSelector(
  getState,
  state => state.searchFilters.publications
);

export const getPublicationsFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { publications } = state.searchFilters;

    if (publications.minCount.value !== null) {
      res += 1;
    }
    res += publications.journal.values.length;
    res += publications.type.values.length;

    return res;
  }
);

export const getTrialsFilters = createSelector(
  getState,
  state => state.searchFilters.trials
);

export const getTrialsFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { trials } = state.searchFilters;

    if (trials.minCount.value !== null) {
      res += 1;
    }

    res += trials.status.values.length;
    res += trials.phase.values.length;
    res += trials.studyType.values.length;
    res += trials.funderType.values.length;
    res += trials.sponsor.values.length;

    return res;
  }
);

export const getCongressesFilters = createSelector(
  getState,
  state => state.searchFilters.congresses
);

export const getCongressesFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { congresses } = state.searchFilters;

    if (congresses.minCount.value !== null) {
      res += 1;
    }

    res += congresses.name.values.length;
    res += congresses.type.values.length;

    return res;
  }
);

export const getGrantsFilters = createSelector(
  getState,
  state => state.searchFilters.grants
);

export const getGrantsFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { grants } = state.searchFilters;

    if (grants.minAmount.value !== null) {
      res += 1;
    }

    res += grants.funder.values.length;

    return res;
  }
);

export const getPaymentsFilters = createSelector(
  getState,
  state => state.searchFilters.payments
);

export const getPaymentsFiltersCount = createSelector(
  getState,
  state => {
    let res: number = 0;
    const { payments } = state.searchFilters;

    if (payments.minAmount.value !== null) {
      res += 1;
    }

    res += payments.company.values.length;
    res += payments.drugOrDevice.values.length;
    res += payments.fundingType.values.length;

    return res;
  }
);

export const getAdvancedSearchFiltersCount = createSelector(
  getState,
  state =>
    getPublicationsFiltersCount(state) +
    getTrialsFiltersCount(state) +
    getCongressesFiltersCount(state) +
    getGrantsFiltersCount(state) +
    getPaymentsFiltersCount(state)
);

export const getTotalAppliedFiltersCount = createSelector(
  getState,
  state =>
    state.searchFilters.specialty.values.length +
    state.searchFilters.state.values.length +
    state.searchFilters.country.values.length +
    state.searchFilters.institution.values.length +
    getAdvancedSearchFiltersCount(state)
);

export const getClearAllButtonDisabled = createSelector(
  getState,
  state => {
    return getTotalAppliedFiltersCount(state) === 0;
  }
);

export const getQuery = createSelector(
  getState,
  state => state.searchFilters.query
);

export const getSortBy = createSelector(
  getState,
  state => state.searchFilters.sortBy
);

export const getHasQuery = createSelector(
  getState,
  state => !!state.searchFilters.query
);

export const getInitialOptionsLoaded = createSelector(
  getState,
  state => state.searchFilters.initialOptionsLoaded
);

export const getAdvancedFilterSectionOpen = (section: AdvancedFilterSections) =>
  createSelector(
    getSearchFilterState,
    state => state.advancedFiltersOpen.indexOf(section) > -1
  );

export const getAppliedFilters = createSelector(
  getState,
  rootState => {
    const {
      specialty,
      country,
      state,
      institution,
      publications,
      trials,
      congresses,
      grants,
      payments
    } = rootState.searchFilters;
    const obj = {
      specialty: specialty.values.join(", "),
      country: country.values.join(", "),
      state: state.values.join(", "),
      institution: institution.values.join(", "),
      "publications.minCount": publications.minCount.value || "",
      "publications.journal": publications.journal.values.join(", "),
      "publications.type": publications.type.values.join(", "),
      "trials.minCount": publications.minCount.value || "",
      "trials.status": trials.status.values.join(", "),
      "trials.phase": trials.phase.values.join(", "),
      "trials.studyType": trials.studyType.values.join(", "),
      "trials.funderType": trials.funderType.values.join(", "),
      "trials.sponsor": trials.sponsor.values.join(", "),
      "congresses.minCount": congresses.minCount.value || "",
      "congresses.name": congresses.name.values.join(", "),
      "congresses.type": congresses.type.values.join(", "),
      "grants.minAmount": grants.minAmount.value || "",
      "grants.funder": grants.funder.values.join(", "),
      "payments.minAmount": payments.minAmount.value || "",
      "payments.company": payments.company.values.join(", "),
      "payments.drugOrDevice": payments.drugOrDevice.values.join(", "),
      "payments.fundingType": payments.fundingType.values.join(", ")
    };

    Object.keys(obj).forEach(key => {
      // @ts-ignore
      if (obj[key] === "") {
        // @ts-ignore
        delete obj[key];
      }
    });

    return obj;
  }
);

export const getAnalyticsPayload = createSelector(
  getState,
  state => {
    const filters = JSON.stringify(getAppliedFilters(state));
    return {
      query: state.searchFilters.query,
      filters: filters !== "{}" || undefined
    };
  }
);
