import * as React from "react";
import { connect } from "react-redux";
import { CustomSortButton } from "./CustomSortButton";
import { CustomSortModal } from "./CustomSortModal";
import { loadCustomSortingOptions } from "../../../../../store/actions";
import { RootState } from "../../../../../store/reducers";

interface MappedStateProps {
  optionsLoaded: boolean;
}

interface DispatchProps {
  fetchSortOptions: () => void;
}

const CustomSortingComponent: React.FunctionComponent<
  MappedStateProps & DispatchProps
> = ({ fetchSortOptions, optionsLoaded }) => {
  React.useEffect(() => {
    if (!optionsLoaded) {
      fetchSortOptions();
    }
  }, []);

  return (
    <>
      <CustomSortButton />
      <CustomSortModal />
    </>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  optionsLoaded: state.searchFilters.customSortingOptionsLoaded
});

const mapDispatchToProps: DispatchProps = {
  fetchSortOptions: loadCustomSortingOptions.request
};

export const CustomSorting = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSortingComponent);
