import { connectProps } from "../MinCount";
import { RootState } from "../../../../../store/reducers";
import { setCongressMinCount } from "../../../../../store/actions";

const mapStateToProps = (state: RootState) => ({
  minCount: state.searchFilters.congresses.minCount.value
});

const mapDispatchToProps = {
  setMinCount: setCongressMinCount
};

export const CongressesMinCount = connectProps(
  mapStateToProps,
  mapDispatchToProps
);
