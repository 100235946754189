/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import {
  CustomSortBy,
  CustomSortByConstructor,
  DefaultScoreWeights
} from "@h1eng/interfaces";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import { CustomOptionSlider } from "./CustomOptionSlider";
import { ConfirmDeleteButton } from "./ConfirmDeleteButton";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    inputWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    nameInput: {
      marginRight: theme.spacing.unit,
      flexGrow: 1,
      marginBottom: 0,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 250
      }
    },
    marginBottom: {
      marginBottom: theme.spacing.unit * 3
    },
    noMarginBottom: {
      marginBottom: 0
    }
  });

interface ComponentProps {
  customSortBy: CustomSortBy | CustomSortByConstructor;
  onUpdate: (entity: CustomSortBy | CustomSortByConstructor) => void;
  onDelete?: (entity: CustomSortBy | CustomSortByConstructor) => void;
  errorMessage?: string;
}

type CustomSortByValueKey = keyof typeof DefaultScoreWeights;

type Props = ComponentProps & WithStyles<typeof styles>;

const CustomSortOptionManagerComponent: React.FunctionComponent<Props> = ({
  customSortBy,
  onUpdate,
  onDelete,
  classes,
  errorMessage
}) => {
  function handleNameChange(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) {
    const name = event.target.value;
    onUpdate({
      ...customSortBy,
      name
    });
  }

  const handleValueChange = (key: CustomSortByValueKey) => (value: number) => {
    const num = parseFloat(value.toFixed(2));
    onUpdate({ ...customSortBy, [key]: num });
  };

  const outlined: boolean = false;

  return (
    <div className={classes.root}>
      <div
        className={classNames(
          classes.inputWrapper,
          outlined ? classes.marginBottom : classes.noMarginBottom
        )}
      >
        <TextField
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          className={classNames(
            classes.nameInput,
            outlined ? classes.noMarginBottom : classes.marginBottom
          )}
          onChange={handleNameChange}
          value={customSortBy.name}
          label="Name"
          autoFocus={!customSortBy.name}
          name="sort-option-name"
          variant={(outlined && "outlined") || "standard"}
        />
        {!!onDelete && (
          <ConfirmDeleteButton
            popperContent="Are you sure you want to delete this item?"
            onConfirm={() => {
              onDelete(customSortBy);
            }}
          />
        )}
      </div>
      <CustomOptionSlider
        value={customSortBy.publication}
        label="Publications"
        onChange={handleValueChange("publication")}
        colorKey="publications"
      />
      <CustomOptionSlider
        value={customSortBy.citation}
        label="Citations"
        onChange={handleValueChange("citation")}
        colorKey="citations"
      />
      <CustomOptionSlider
        value={customSortBy.trial}
        label="Clinical Trials"
        onChange={handleValueChange("trial")}
        colorKey="trials"
      />
      <CustomOptionSlider
        value={customSortBy.payment}
        label="Payments"
        onChange={handleValueChange("payment")}
        colorKey="payments"
      />
      <CustomOptionSlider
        value={customSortBy.congress}
        label="Congresses"
        onChange={handleValueChange("congress")}
        colorKey="congresses"
      />
      {/* <CustomOptionSlider
        value={customSortBy.grant}
        label="Grants"
        onChange={this.handleValueChange("grant")}
      /> */}
    </div>
  );
};

export const CustomSortOptionManager = withStyles(styles)(
  CustomSortOptionManagerComponent
);
