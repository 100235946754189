import * as React from "react";
import { H5, styled, B1, B2 } from "@h1eng/ui-components";
import { withRouter } from "react-router";
import PillFactory from "../../Pill";
import {
  GenericSearchResultInterface,
  PersonOverviewInterface
} from "@h1eng/interfaces";
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
} from "../../../store/actions";
import { connect } from "react-redux";

//@ts-ignore
import ContainerDimensions from "react-container-dimensions";
import { Card } from "./ProfileElements";

import {
  getDocumentSearch,
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDate,
  getDocumentSearchBarFilterDateDisplayText
} from "../../../store/selectors";

import {
  ProfileDocumentSearch,
  SetDocumentSearchBarQueryInterface
} from "./ProfileDocumentSearch";
import { ProfileStats } from "./ProfileOverStats";
import { MultiRender } from "./MultiDocRender";
import {
  SearchBarQureyHelper,
  dateFilters,
  SearchBarQureyDisplay
} from "./SearchHelpFunctions";
import { DateRangeDropdown } from "../common/DateRangeDropdown";

const ClearAll = styled.div`
  height: 18px;
  color: #000000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;
const ShowingResults = styled.div`
  padding-top: 10px;
  display: flex;
  font-family: Montserrat;
  font-size: 14px;
  color: #333333;
`;
export const ShowingResultsKeyWords = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
`;

interface OVERVIEWSECTIONCDispatcher {
  setDocumentSearchBarQuery: (t: SetDocumentSearchBarQueryInterface) => void;
  setDocumentSearchBarFilterDate: (
    t: { filterDate: number; displayFilterDateString: string }
  ) => void;
  documentSearch: GenericSearchResultInterface[];
  searchBarState: { query: string[] };
  filterDateText: string;
  filterDate: number;
  match: any;
  location: any;
  history: any;
}
type Props = PersonOverviewInterface & OVERVIEWSECTIONCDispatcher;

class OVERVIEWSECTIONClass extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  removeTerm = (term: string) => {
    const query = this.props.searchBarState.query.filter(
      (t: any) => t !== term
    );
    this.props.setDocumentSearchBarQuery({
      query,
      personId: this.props.id,
      types: []
    });
    // this.setState({ searchTerms: update }, this.applySearch);
  };
  render() {
    return (
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "20px",
            paddingLeft: "20px"
          }}
        >
          <ProfileDocumentSearch personId={this.props.id} />
          <DateRangeDropdown />
        </div>

        <SearchBarQureyHelper />
        <SearchBarQureyDisplay />
        <ProfileStats {...this.props} docs={this.props.documents || []} />
        <MultiRender
          docs={this.props.documents || []}
          dateFilter={this.props.filterDate}
          limitNoSearch={true}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  searchBarState: getDocumentSearchBarState(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state)
});

const mapDispatchToProps = {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate
};

export const OVERVIEWSECTION = connect(
  mapStateToProps,
  mapDispatchToProps
)(OVERVIEWSECTIONClass as any);
