import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { omit } from "lodash";

const defaultHighlightColor = "#e4f3f7";

interface StyleProps {
  active: boolean;
  img: string;
  highlightColor?: string;
  defaultHighlightColor?: string;
}

const omitProps = ["active", "img", "highlightColor", "defaultHighlightColor"];

export const IconButton = styled(
  (props: StyleProps & { children: React.ReactChildren }) => (
    <div {...omit(props, omitProps)} />
  )
)`
  opacity: ${props => (props.active ? "1" : ".33")};
  background: url(${props => props.img}) no-repeat center;
  width: 1px;
  height: 1px;
  padding: 15px;
  border-radius: 50%;
  &:hover {
    cursor: ${props => (props.active ? "pointer" : "auto")};
    background-color: ${props =>
      props.active
        ? props.highlightColor
          ? props.highlightColor
          : defaultHighlightColor
        : "auto"};
  }
`;
